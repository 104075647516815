import type { LibroModel } from '@difizen/libro-core';
import {
  LibroToolbarArea,
  LibroView,
  NotebookCommands,
  ExecutableCellView,
} from '@difizen/libro-core';
import { CommandRegistry, ToolbarContribution, ToolbarRegistry } from '@difizen/mana-app';
import {
  CommandContribution,
  inject,
  ModalService,
  singleton,
} from '@difizen/mana-app';
import { CloudLabLibroService } from './cloud-lab-libro-service';
import { LibroCellView, LibroCommandRegister, LibroJupyterModel } from '@difizen/libro-jupyter';

export const CloudlabCommand = {
  LibroLogoCommand: {
    id: 'cloudlab:libro-logo',
    label: '点击跳转 libro notebook 官网',
  }
};

@singleton({ contrib: [CommandContribution,ToolbarContribution] })
export class CloudlabLibroCommandContribution implements CommandContribution,ToolbarContribution {
  @inject(LibroCommandRegister) protected readonly libroCommand: LibroCommandRegister;
  @inject(ModalService) protected readonly modalService: ModalService;
  @inject(CloudLabLibroService) protected cloudLabLibroService: CloudLabLibroService;

  registerCommands(command: CommandRegistry): void {
    command.registerHandler(NotebookCommands['SideToolbarRunSelect'].id, {
        execute: () => {
        },
        isVisible: (commandRegister,cell, libro, path) => {
            if (
                !cell ||
                !libro ||
                !ExecutableCellView.is(cell) ||
                !(libro instanceof LibroView)
            ) {
                return false;
            }
            return (
                (libro?.model as LibroModel).executable &&
                path === LibroToolbarArea.CellRight
            );
        },
        isEnabled: (commandRegister,cell, libro) => {
            if (!libro || !(libro instanceof LibroView)) {
                return false;
            }
            if(this.cloudLabLibroService.globalEnvStatus!==1) {
                return true
            };
            return (
                (libro.model as LibroJupyterModel).kernelConnection !== undefined &&
                (libro.model as LibroJupyterModel).kernelConnecting === false
            );
        },
      });
    command.registerHandler(NotebookCommands['TopToolbarRunSelect'].id, {
        execute: () => {
        },
        isVisible: (commandRegister,cell, libro, path) => {
            if (
                !libro ||
                !(libro instanceof LibroView) ||
                path !== LibroToolbarArea.HeaderCenter
            ) {
                return false;
            }
            return (libro?.model as LibroModel).executable;
        },
        isEnabled: (commandRegister,cell, libro) => {
            if (!libro || !(libro instanceof LibroView)) {
                return false;
            }
            if(this.cloudLabLibroService.globalEnvStatus!==1) {
                return true
            };
            return (
                (libro.model as LibroJupyterModel).kernelConnection !== undefined &&
                (libro.model as LibroJupyterModel).kernelConnecting === false
            );
        },
    });
    command.registerHandler(NotebookCommands['RunCell'].id, {
        execute: async (commandRegister,cell, libro) => {
          if (
            !cell ||
            !libro ||
            !(cell instanceof LibroCellView) ||
            !(libro instanceof LibroView)
          ) {
            return;
          }
          if ((libro.model as LibroModel).executable) {
            libro.runCell(cell);
          }
        },
        isEnabled: (cell, libro) => {
          if (!libro || !(libro instanceof LibroView)||this.cloudLabLibroService.globalEnvStatus!==1) {
            return false;
          }
          return true;
        },
    });
    command.registerHandler(NotebookCommands['RunCell'].id, {
        execute: async (commandRegister,cell, libro) => {
          if (
            !cell ||
            !libro ||
            !(cell instanceof LibroCellView) ||
            !(libro instanceof LibroView)
          ) {
            return;
          }
          if ((libro.model as LibroModel).executable) {
            libro.runCell(cell);
          }
        },
        isEnabled: (cell, libro) => {
          if (!libro || !(libro instanceof LibroView)||this.cloudLabLibroService.globalEnvStatus!==1) {
            return false;
          }
          return true;
        },
    });
    command.registerHandler(NotebookCommands['RunCellAndInsertBelow'].id, {
        execute: async (commandRegister,cell, libro) => {
          if (
            !cell ||
            !libro ||
            !(cell instanceof LibroCellView) ||
            !(libro instanceof LibroView)
          ) {
            return;
          }
          if ((libro.model as LibroModel).executable) {
            libro.runCellandInsertBelow(cell);
          }
        },
        isEnabled: (cell, libro) => {
          if (!libro || !(libro instanceof LibroView)||this.cloudLabLibroService.globalEnvStatus!==1) {
            return false;
          }
          return true;
        },
    });
    command.registerHandler(NotebookCommands['RunCellAndSelectNext'].id, {
        execute: async (commandRegister,cell, libro) => {
          if (
            !cell ||
            !libro ||
            !(cell instanceof LibroCellView) ||
            !(libro instanceof LibroView)
          ) {
            return;
          }
          if ((libro.model as LibroModel).executable) {
            libro.runCellandSelectNext(cell);
          }
        },
        isEnabled: (cell, libro) => {
          if (!libro || !(libro instanceof LibroView)||this.cloudLabLibroService.globalEnvStatus!==1) {
            return false;
          }
          return true;
        },
    });
    this.libroCommand.registerLibroCommand(command,CloudlabCommand['LibroLogoCommand'],{
      execute: async (cell, libro, path) => {
        window.open("https://libro.difizen.net/","__blank")
      },
      isVisible: (cell, libro, path) => {
          return (
            path === LibroToolbarArea.HeaderRight 
          );
      },
    })
  }

  registerToolbarItems(registry: ToolbarRegistry): void {
    registry.registerItem({
      id: CloudlabCommand['LibroLogoCommand'].id,
      command: CloudlabCommand['LibroLogoCommand'].id,
      icon: <img src='https://mdn.alipayobjects.com/huamei_zabatk/afts/img/A*1hb8TrGGnrMAAAAAAAAAAAAADvyTAQ/original' width={20}/>,
      tooltip: CloudlabCommand['LibroLogoCommand'].label,   
      order:'z'
    });
  }
}
