/*
 * 新增编辑目录信息
 */
import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons';
import './index.less'
import SubmitStage from './SubmitStage';
import SvgIcon from '../SvgIcon';
import { commitCatalog } from '../../api/modules/repo';

const { confirm, warning } = Modal;

export type SubmitProps = {
  reqParams?: any
  submitShow: boolean
  submitValueList: any
  onshow: (v: boolean) => void
  coursePublish: () => void
}

const SubmitModal: React.FC<SubmitProps> = (props) => {

  // 课程目录数据
  const [submitList, setSubmitList] = useState<any>(props.submitValueList)


  const [ifAllSelect, setIfAllSelect] = useState(false)


  const [modalshow, setModalshow] = useState(props.submitShow)

  //提交按钮
  // 发布loading
  const [loading, setLoading] = useState(false);



  //提交提示弹窗
  const warnShowConfirm = () => {
    warning({
      title: '无法提交！',
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: '请确保你的课程内至少有一个小节才可以提交哦~',
      className: 'warn2',
      centered: true,
      width: 400,
      okText: '我知道了',

      onOk() {

      },
    });
  };

  //提交失败弹窗
  const errShowConfirm = () => {
    warning({
      title: '提交失败！',
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: '此次提交的课程中存在部分小节提交失败，请修改后重新提交。',
      className: 'warn2',
      centered: true,
      width: 400,
      okText: '我知道了',

      onOk() {

      },
    });
  };

  //提交成功弹窗
  const successShowConfirm = () => {
    confirm({
      title: '提交成功！',
      icon: <SvgIcon iconClass='success' fontSize='22px' />,
      content: '快去发布你的课程吧！',
      okText: '去发布',
      centered: true,
      className: 'success1',
      width: 400,
      cancelText: '继续编辑',
      onOk() {
        props.coursePublish()
      },
      onCancel() {
      },
    });
  };

  //
  const change = (value: any) => {
    let newList: any = []
    value?.forEach((item: any) => {

      if (item?.children?.length > 0) {
        item = change1(item)
        newList.push(item)
      } else {
        let towHierarchy = {
          frist: '1',
          children: [
            {
              second: '2',
              children: [item]
            }
          ]
        }
        newList.push(towHierarchy)
      }
    })
    console.log(newList);

    if (newList[0]) {
      changeSubmitvalue(newList)
    }
  }

  //判断每个节点是属于章或者小姐
  const change1 = (value: any) => {
    //章或者阶段
    let i = false
    value?.children?.forEach((item: any) => {
      if (item?.children?.length > 0) {
        i = true
      } else {
      }
    })


    if (i) {
      value?.children?.forEach((item: any, index: any) => {
        if (item?.children?.length > 0) {
        } else {
          value.children[index] = {
            second: '2',
            children: [item]
          }
        }
      })
      return value
    } else {
      let towHierarchy = {
        frist: '1',
        children: [value]
      }
      return towHierarchy
    }
  }



  const handleOK = () => {
    setLoading(true)
    let konbLIst: any[] = []
    let isChangedLIst: any[] = []
    const submitValue = JSON.parse(JSON.stringify(submitList))
    console.log(submitValue);

    submitValue.forEach((item: any) => {
      if (item?.children?.length > 0) {
        item.children.forEach((aItem: any) => {
          if (aItem?.children?.length > 0) {
            aItem.children.forEach((aaItems: any) => {
              if (aaItems.konbShow && aaItems.isChanged && !aaItems.isEmpty) {
                konbLIst.push(aaItems.path)
              } else if (!aaItems.isChanged && !aaItems.isEmpty) {
                console.log(aaItems.path);

                isChangedLIst.push(aaItems.path)
              }
            })
          } else {
            if (aItem.konbShow) {
              // konbLIst.push(aItem.path)
            }
          }
        })
      } else {
        if (item.konbShow) {
          // konbLIst.push(item.path)
        }
      }
    })
    console.log(konbLIst, isChangedLIst);

    if (konbLIst?.length > 0 || isChangedLIst?.length > 0) {
      commitCatalog({
        ...props.reqParams,
        path: [...konbLIst, ...isChangedLIst]
      }).then(() => {
        setLoading(false)
        props.onshow(false)
        successShowConfirm()

      }).catch(() => {
        setLoading(false)
        props.onshow(false)
        errShowConfirm()
      })


    } else {
      setLoading(false)
      warnShowConfirm()
    }
  }

  const onCancel = () => {
    props.onshow(false)
  }

  const changeSubmitList = (e: any, index: any) => {
    const subList = [...submitList]
    subList[index] = e
    setSubmitList(subList)
  }

  const allSelect = () => {
    const allValue = JSON.parse(JSON.stringify(submitList))
    if (!ifAllSelect) {
      allValue.forEach((item: any, index1: any) => {
        if (item?.children) {
          item?.children?.forEach((aItem: any, index2: any) => {
            if (aItem?.children) {
              aItem.children?.forEach((aaItems: any, index3: any) => {
                item.stageShow = true
                aItem.sectionShow = true
                aaItems.konbShow = true
              })
            } else {
              item.sectionShow = true
              aItem.konbShow = true
            }
          })
        } else {
          item.konbShow = true
        }
      })
    } else {
      allValue.forEach((item: any, index1: any) => {
        if (item?.children) {
          item?.children?.forEach((aItem: any, index2: any) => {
            if (aItem?.children) {
              aItem.children?.forEach((aaItems: any, index3: any) => {
                item.stageShow = false
                aItem.sectionShow = false
                aaItems.konbShow = false
              })
            } else {
              item.sectionShow = false
              aItem.konbShow = false
            }
          })
        } else {
          item.konbShow = false
        }
      })
    }
    setIfAllSelect(!ifAllSelect)
    setSubmitList(allValue)
  }

  const changeSubmitvalue = (value: any) => {


    value.forEach((item: any) => {
      if (item?.children?.length > 0) {
        item.children?.forEach((aItem: any) => {
          if (aItem?.children?.length > 0) {
            aItem.children.forEach((aaItems: any) => {
              item.stageShow = false
              aItem.sectionShow = false
              aaItems.konbShow = false
              if (aaItems.isChanged) {
                aItem.ifUpdate = true
                item.ifUpdate = true
              }
            })
          } else {
            item.sectionShow = false
            aItem.konbShow = false
          }
        })
      } else {
        item.konbShow = false
      }
    })
    setSubmitList(value)
  }



  useEffect(() => {
    if (props.submitShow) {

    } else {
      setTimeout(() => {
        setSubmitList([])
      }, 500);
    }
    setModalshow(props.submitShow)

  }, [props.submitShow])

  useEffect(() => {
    if (props.submitValueList) {
      // changeSubmitvalue(props.submitValueList)
      change(props.submitValueList)
    }
  }, [props.submitValueList])

  useEffect(() => {
    if (submitList?.length > 0) {
      let ifAllSelect = true
      submitList?.forEach((item: any) => {
        console.log(item);

        if (!item?.stageShow) {
          ifAllSelect = false
        }
      })
      setIfAllSelect(ifAllSelect)
    }
    console.log('submitList ====> ', submitList);

  }, [submitList])

  return (
    <div className='submit'>
      <Modal
        closable={false}
        centered
        open={modalshow}
        forceRender={true}
        className='submit-modal'
        width={680}
        footer={[
          <Button key="back" disabled={loading} onClick={onCancel}>
            取消
          </Button>,
          <Button key="submit" loading={loading} type="primary" onClick={handleOK}>
            确定
          </Button>
        ]}
      >
        <div className='submit-top'>
          <div className='submit-top-text'>
            请勾选提交的章节
          </div>
          {ifAllSelect ? <div className='submit-top-clickBtns' onClick={allSelect}>
            全选
          </div> :
            <div className='submit-top-btns' onClick={allSelect}>
              全选
            </div>
          }
        </div>
        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {submitList?.map((item: any, index: any) => (
            <div className='submit-content' key={index}>
              <SubmitStage stageList={item} stageIndex={index} changeList={(e: any, index: any) => { changeSubmitList(e, index) }}></SubmitStage>
            </div>
          ))}
        </div>

        <div className='submit-dialog'>提示：已提交的课程默认勾选，不可取消；无内容的课程不做展示。</div>
      </Modal>
    </div>
  )
}

export default SubmitModal