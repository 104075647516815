import React, { useContext, useEffect, useState } from 'react'
import './index.less'
import { Button, Form, Modal, message } from 'antd'
import ExperEnv from '../../../../ExperEnv'
import SharedDataContext from '../../../../../utils/share'
import { rename } from '../../../../../api/modules/fs'
import { changeCourseTemplate } from '../../../../../api/modules/community'
import { courseTypes } from '../../../../../utils/commonTypes'

type BlogEnvModalProps = {
    courseType?: any    // 课程类型
    open: boolean          // 是否显示弹框
    onClose: () => void    // 关闭显示
}

const BlogEnvModal: React.FC<BlogEnvModalProps> = (props) => {

    const { courseType, open, onClose } = props

    const [form] = Form.useForm()

    // 获取共享数据
    const { section, setSection, labCode, couseCode, userInfo, selectedChapter, params, repoEnvStatus } = useContext(SharedDataContext)

    const [currentExperEnv, setCurrentExperEnv] = useState<any>(null)      // 当前的实验环境
    const [currentExperTools, setCurrentExperTools] = useState<any>(null)  // 当前实验工具
    const [experPort, setExperPort] = useState<any>([])                    // 当前端口工具
    const [durationTime, setDurationTime] = useState('30')                 // 当前环境时长

    const [loading, setLoading] = useState(false)                          // 保存中

    // 监听当前环境的变化
    useEffect(() => {
        if (open) {
            let template: any = {}
            // 课程的环境修改不走继承
            if (courseType !== courseTypes.COURSE) {
                if (section?.template && Object.keys(section?.template)?.length) {
                    template = section?.template
                } else if (repoEnvStatus?.template && Object.keys(repoEnvStatus?.template)?.length) {
                    template = repoEnvStatus.template
                }
            } else {
                template = selectedChapter?.describe?.template
            }
            setCurrentExperEnv(template.experEnv)
            setCurrentExperTools(template.expertools)
            setExperPort(template.ports ?? [])
            setDurationTime(template.duration ?? '30')
        } else {
            setCurrentExperEnv('')
        }
    }, [open, section, selectedChapter, courseType])


    // 确定提交
    const sumbit = async () => {
        // 对数据进行处理
        const data = { ...selectedChapter }
        const ports = experPort?.map((item: any) => {
            const type = item?.type ?? 'system'
            return { ...item, type }
        })
        const portFind = ports?.find((i: any) => i.name === selectedChapter?.describe?.template?.defaultOpenName && i.port === selectedChapter?.describe?.template?.defaultOpen)

        let template;
        if (portFind >= 0) {
            template = {
                ...selectedChapter?.describe?.template,
                experEnv: currentExperEnv,
                expertools: currentExperTools,
                ports,
                duration: currentExperEnv ? Number(durationTime) : 30
            }
        } else {
            template = {
                ...selectedChapter?.describe?.template,
                experEnv: currentExperEnv,
                expertools: currentExperTools,
                ports,
                duration: currentExperEnv ? Number(durationTime) : 30,
                defaultOpenName: ports?.find((i: any) => i.isDefaultTool)?.name,
                defaultOpen: ports?.find((i: any) => i.isDefaultTool)?.port,
            }
        }
        data.describe.template = template
        setLoading(true)
        // 修改小节的环境
        const res = await rename({
            repo: params?.get('repo'),
            key: params?.get('key'),
            data: {
                title: data?.title.trim(),
                path: data?.path,
                parent: data?.parent,
                describe: data?.describe ?? {}
            }
        }).catch((err) => {
            message.destroy()
            message.warning('保存失败')
            setLoading(false)
        })
        if (!res) return
        if (courseType !== courseTypes.COURSE) {
            // 修改课程的环境
            const code = await changeCourseTemplate(couseCode, JSON.stringify(template)).catch((err) => {
                message.destroy()
                message.warning('保存失败')
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
        // if (code) {
        onClose?.()
        setSection({ ...section, template })
        // }
    }

    return <Modal
        open={open}
        wrapClassName={'blogenvmodal-modal'}
        onCancel={onClose}
        footer={false}
        closable={false}
        centered={true}
        width={520}
        maskClosable={false}
        destroyOnClose={true}
    >
        <div className='blogenvmodal-title'>设置{courseType === courseTypes.BLOG ? '博客' : courseType === courseTypes.COURSE ? '小节' : ''}实验环境</div>
        <Form
            form={form}
            name="control-hooks"
            autoComplete="off"
        >
            <ExperEnv
                currentExperEnv={currentExperEnv}
                setCurrentExperEnv={setCurrentExperEnv}
                currentExperTools={currentExperTools}
                setCurrentExperTools={setCurrentExperTools}
                experPort={experPort}
                setExperPort={setExperPort}
                userId={userInfo?.userId ?? ''}
                labCode={labCode ?? ''}
                form={form}
                currentContentType={section?.type}
                durationTime={durationTime}
                setDurationTime={setDurationTime}
                creator={userInfo?.nickname}
            />

            <Form.Item
                noStyle
            >
                <div className='blogenvmodal-footer'>
                    <Button onClick={() => { onClose?.() }} htmlType='button' style={{ minWidth: '88px' }}>取消</Button>
                    <Button loading={loading} onClick={sumbit} type='primary' htmlType='button' style={{ minWidth: '88px' }}>确定</Button>
                </div>
            </Form.Item>
        </Form>
    </Modal>
}

export default BlogEnvModal