import React, { useContext, useEffect, useRef, useState } from 'react'
import './index.less'
import SvgIcon from '../../../components/SvgIcon'
import { Checkbox, Form, Input, Modal, Select, message } from 'antd'
import RichEditor from '../../../components/RichEditor'
import TopNav from '../TopNav'
import { getQuestionAnswerType, getUrlConfig, publishQuesAndAns, queryUserToken } from '../../../api/modules/community'
import SharedDataContext from '../../../utils/share'

type PublishQuesProps = {
    back: () => void
    onClose: () => void   // 关闭显示 
}

const PublishQues: React.FC<PublishQuesProps> = (props) => {

    const [form] = Form.useForm()

    const { back, onClose} = props

    // 获取共享数据
    const { section, labCode, couseCode, labDetail } = useContext(SharedDataContext)

    // 标题
    const [title, setTitle] = useState('')
    // 编辑器内容
    const [editorValue, setEditorValue] = useState('')
    // 问答类型
    const [quesType, setQuesType] = useState([])

    // 显示发布问答的下拉菜单
    const [showMenus, setShowMenus] = useState(false)
    // 下拉菜单当前选中    true 第一个    false  第二个
    const [lightMenus, setLightMenus] = useState(true)
    // 用户绑定github的相关信息
    const [userGithub, setUserGithub] = useState<any>({})

    // 前端社区地址
    const [comUrl, setComUrl] = useState('') 

    // 当下拉菜单显示的时候，监听关闭时机
    useEffect(() => {
        const handleClick = (event: any) => {
            const menu = document.getElementById('publishques-main-btn-menus-id') as HTMLDivElement
            if (!menu?.contains(event.target)) {
                setShowMenus(false)
            }
        };
        if (showMenus) {
            setTimeout(() => {
                document.addEventListener('click', handleClick)
            }, 0)
            return () => {
                document.removeEventListener('click', handleClick);
            }
        }
    }, [showMenus])

    // 是否发布接口请求中
    const tamp = useRef<boolean>(false)

    // 发布   only 发布问答   mult 发布并同步issue
    const submit = async (type: 'only' | 'mult') => {
        if (tamp.current) return
        const data = await form?.validateFields().catch(() => { })
        if (!data) return
        if (type === 'mult') {
            // 校验实验室是否已经绑定
            if (!labDetail?.url || !labDetail?.token) {
                message.destroy()
                message.warning('当前实验室未激活issue，无法同步')
                return
            }
            // 校验当前用户是否绑定
            if (!userGithub?.unionId || !userGithub?.token) {
                Modal.confirm({
                    title: '暂无权限',
                    content: '暂时无法操作，请先绑定GitHub账号并设置访问令牌~',
                    okText: '去设置  ',
                    centered: true,
                    width: 400,
                    cancelText: '我再想想',
                    onOk() {
                        if (comUrl) {
                            window.open(comUrl + 'user/github')
                        }
                    },
                    onCancel() {}
                })
                return
            }
        }
        // 设置当前的菜单
        setLightMenus(type === 'only' ? true : false)
        // 获取课程分享的url域名
        const { value } = await getUrlConfig().catch(() => { })
        // 对地址进一步理
        if (!value) return
        const url = new URL(value)
        const cooperateUrl = url.href.replace(url.host, `${labCode}.${url.host}`) + `os/${couseCode}`
        // 发布
        tamp.current = true
        const res = await publishQuesAndAns({
            chapter: section?.path,
            code: couseCode,
            content: editorValue,
            cooperateUrl,
            labCode,
            title: data.title.trim(),
            labels: data?.labels ?? [],
            cooperate: data.permission ?? false,
            sync: type === 'only' ? false : true,
            type: data.type,
            learn: data.learn ?? false
        }).catch((err) => {
            message.destroy()
            message.error(err?.message ?? '发布问答失败')
        }).finally(() => {
            tamp.current = false
        })
        if (!res) return
        message.destroy()
        message.success('问答发布成功！')
        back?.()
    }

    // 获取问答类型
    const fetchType = async () => {
        const res = await getQuestionAnswerType().catch(() => { })
        if (!res) return
        setQuesType(res ?? [])
    }

    // 获取github相关的信息
    const fetchGithub = async () => {
        const token = await queryUserToken('github').catch(() => {})
        setUserGithub(token ?? {})
    }

    // 获取社区前端地址
    const fetchUrl = async () => {
        const { value } = await getUrlConfig().catch(() => { })
        setComUrl(value)
    }

    // 相关数据初始化
    useEffect(() => {
        fetchType()
        fetchGithub()
        fetchUrl()
    }, [])

    return (
        <div className='publishques'>
            <TopNav title='发布问答' back={back} close={onClose}/>

            <div className='publishques-main'>
                <Form
                    name="publishques-main-form"
                    colon={false}
                    form={form}
                    autoComplete="off"
                >
                    <Form.Item
                        name='title'
                        rules={[
                            { required: true, message: '请输入标题', validateTrigger: "onSubmit", whitespace: true },
                        ]}
                        validateFirst
                    >
                        <Input
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value)
                            }}
                            maxLength={50}
                            showCount
                            allowClear
                            placeholder='请输入标题'
                        />
                    </Form.Item>

                    <Form.Item
                        rules={[]}
                        validateFirst
                    >
                        <RichEditor
                            placeholder='请输入问答内容'
                            onChange={(value: string) => {
                                setEditorValue(value)
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label='问答类型'
                        name='type'
                        rules={[]}
                        validateFirst
                    >
                        <Select
                            fieldNames={{ label: 'tips', value: 'code' }}
                            placeholder="请选择"
                            options={quesType}
                        />
                    </Form.Item>

                    <Form.Item
                        label='影响学习进度？'
                        name='learn'
                        rules={[]}
                        validateFirst
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>

                    <Form.Item
                        label='问答标签'
                        className='publishques-main-labels'
                    >
                        <Form.List name="labels">
                            {
                                (fields, { add, remove }, { errors }) => {
                                    return <>
                                        {
                                            fields.map(({ key, name, ...field }, index) => {
                                                return <div className='publishques-main-labels-item' key={key}>
                                                    <Form.Item
                                                        {...field}
                                                        rules={[
                                                            { required: true, message: '请输入标签名称', validateTrigger: "onSubmit", whitespace: true },
                                                        ]}
                                                        name={name}
                                                        // name={[name, 'label']}
                                                        style={{ width: '368px' }}
                                                    >
                                                        <Input placeholder="请输入标签名称" maxLength={10} allowClear showCount />
                                                    </Form.Item>

                                                    <svg className='publishques-main-labels-remove' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                        remove(name)
                                                    }}>
                                                        <circle cx="7.99967" cy="8.35221" r="6.16667" />
                                                        <rect x="11.7598" y="7.75195" width="1.2" height="7.51953" rx="0.6" transform="rotate(90 11.7598 7.75195)" />
                                                    </svg>
                                                </div>
                                            })
                                        }
                                        <div className='publishques-main-labels-add' style={{ color: fields?.length < 10 ? '#1677ff' : '#cccccc', cursor: fields?.length < 10 ? 'pointer' : 'default' }} onClick={() => {
                                            if (fields?.length < 10) {
                                                add('')
                                                // add({ label: '' })
                                            }
                                        }}>
                                            <SvgIcon iconClass='ques-add' style={{ width: '16px', height: '16px' }} />
                                            <span>添加标签</span>
                                        </div>
                                    </>
                                }
                            }
                        </Form.List>
                    </Form.Item>

                    <Form.Item
                        label='开启协同权限'
                        name='permission'
                        style={{ marginBottom: '0px' }}
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>

                    <Form.Item
                        noStyle
                    >
                        <div className='publishques-main-permission-tip'>
                            <SvgIcon iconClass='ques-notip' style={{ width: '14px', height: '14px', marginTop: '2px' }} />
                            <p>开启权限后，该工作台的协同链接将会对他人开放，他人可以通过链接进行工作台对你进行在线协助</p>
                        </div>
                    </Form.Item>

                    <Form.Item
                        className='publishques-main-submit'
                        style={{ marginTop: '40px', marginBottom: '56px' }}
                    >
                        <div className={(title && editorValue) ? 'publishques-main-btn' : 'publishques-main-btn publishques-main-btn-dark'}>
                            <div className='publishques-main-btn-left center' onClick={() => {
                                if (tamp.current) return
                                if (lightMenus) {
                                    submit('only')
                                } else {
                                    submit('mult')
                                }
                            }}>
                                <span>{lightMenus ? '发布问答' : '发布并同步issue'}</span>
                            </div>
                            <div className='publishques-main-btn-right center' onClick={() => {
                                title && editorValue && setShowMenus(true)
                            }}>
                                <SvgIcon iconClass='ques-return' style={{ width: '14px', height: '14px', transform: 'rotate(-90deg)' }} />
                            </div>

                            {
                                showMenus ? <div className='publishques-main-btn-menus' id='publishques-main-btn-menus-id'>
                                    <div className='publishques-main-btn-menus-item' onClick={() => {
                                        if (tamp.current) return
                                        submit('only')
                                        setShowMenus(false)
                                    }}>
                                        <span>发布问答</span>
                                        {
                                            lightMenus ? <SvgIcon iconClass='ques-choice' style={{ width: '14px', height: '14px', marginRight: '8px' }} /> : <></>
                                        }
                                    </div>
                                    <div className='publishques-main-btn-menus-item' onClick={() => {
                                        if (tamp.current) return
                                        submit('mult')
                                        setShowMenus(false)
                                    }}>
                                        <span>发布并同步issue</span>
                                        {
                                            lightMenus ? <></> : <SvgIcon iconClass='ques-choice' style={{ width: '14px', height: '14px', marginRight: '8px' }} />
                                        }
                                    </div>
                                </div> : <></>
                            }
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default PublishQues