import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, message, Modal, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import './index.less'
import { startJudgeJob } from '../../../../api/modules/community'
import Content from './Content'

type ChooseModalProps = {
  examRecordCode?: any
  questionCode?: any
  data?: any
  podAppId?: string //环境启动的id
  open: boolean //是否打开
  onCancel: () => void //取消
  onOk: (value: any) => void //保存
}

const ChooseModal: React.FC<ChooseModalProps> = (props) => {

  const { examRecordCode, questionCode, data, podAppId, open, onCancel, onOk } = props

  const tableRef = useRef(null)

  // 表格高度
  const [tableHeight, setTableHeight] = useState(0)

  // 保存的loading
  const [sureLoading, setSureLoading] = useState(false)

  // 选中的判分点
  const [checked, setChecked] = useState<any>()

  // 列数据
  const columns: ColumnsType<any> = [
    {
      title: '判分点',
      dataIndex: 'index',
      align: 'center',
      width: 120,
      // render: (_, __, index) => {
      //   return (
      //     <span>{index + 1}</span>
      //   )
      // }
      className: 'order',
      render: (value) => <div className='centerstart'>{value + 1}</div>
    },
    {
      title: '内容',
      dataIndex: 'content',
      className: 'choicemodal-content',
      width: 560,
      render: (value: string) => <div className='content'>
            <Content content={value} />
        </div>
    },
    {
      title: '镜像名称',
      dataIndex: 'optionJson',
      align: 'center',
      className: 'order',
      width: 240,
      ellipsis: {
        showTitle: false
      },
      render: (_, record) => {
        return (
          <div className='content'>
            <Tooltip placement='bottomLeft' title={record?.optionJson?.image} color='#fff' arrow={false} overlayClassName='item-tooltip'>
              <div className='text-ellipsis'>{record?.optionJson?.image}</div>
            </Tooltip>
          </div>
        )
      }
    },
    {
      width: 120,
      render: (_, record) => {
        return (
          <>
            <Checkbox checked={record?.exampleId === checked?.exampleId} onChange={(e: any) => {
              if (e?.target?.checked) {
                setChecked(record)
              } else {
                setChecked('')
              }
            }}></Checkbox>
          </>
        )
      }
    }
  ]


  // 确定
  const onSure = () => {
    setSureLoading(true)
    startJudgeJob({
      examRecordCode,
      imageCodes: [checked?.exampleId],
      questionCode,
      questionType: 1,
      submitType: 3,
      targetPodAppId: podAppId
    }).then(res => {
      setSureLoading(false)
      onOk(res)
    }).catch(res => {
      setSureLoading(false)
      message.error(res?.message || '请求出错')
    })
  }

  // 重置选中
  useEffect(() => {
    if (open) {
      setChecked('')
    }
  }, [open])

  // 监听表格区域的大小
  useEffect(() => {
    if (!open) return
    if (!tableRef) return
    const tableTarget: any = tableRef.current
    if (!tableTarget) return
    const observer = new ResizeObserver((entries: any) => {
      const { target } = entries[0]
      setTableHeight(target.offsetHeight)
    })
    observer.observe(tableTarget as Element)
  }, [open, tableRef])

  return (
    <Modal
      wrapClassName="choose-modal"
      open={open}
      width="100vw"
      closable={false}
      onCancel={onCancel}
      footer={[
        <Button key='cancel' onClick={onCancel}>
          取消
        </Button>,
        <Button key='save' type="primary" disabled={!checked?.exampleId} loading={sureLoading} onClick={onSure}>
          确定
        </Button>
      ]}
    >
      <div className='choose-modal-content'>
        <div className='choose-modal-content-title'>
          请选择单个客观判分点进行判分
        </div>
        <div className='choose-modal-content-table' ref={tableRef}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ y: tableHeight - 40 }}
            rowKey="exampleId"
          />
        </div>
      </div>
    </Modal>
  )
}

export default ChooseModal