/*
 * 新增编辑目录信息
 */
import React, { useEffect, useState } from 'react'

import './index.less'
import SvgIcon from '../../SvgIcon'
import SubmitKnob from '../SubmitKnob';



export type SubmitSectionProps = {
  sectionList: any
  sectionIndex: number
  changeSection: (v: any, i: number) => void
}

const SubmitSection: React.FC<SubmitSectionProps> = (props) => {
  const [sectionList, setSectionList] = useState(props.sectionList)
  //小节是否为空
  const [sectionValue, setSectionValue] = useState(false)
  const changeSectionList = (value?: boolean) => {
    const sectionValue = JSON.parse(JSON.stringify(sectionList))
    sectionValue.sectionShow = value ?? sectionValue.sectionShow

    sectionValue?.children?.forEach((item: any) => {
      if (!sectionList.sectionShow) {
        item.konbShow = true
      } else {
        item.konbShow = false
      }
    })
    props.changeSection(sectionValue, props.sectionIndex)
  }

  //修改小姐
  const changeKnob = (e: any) => {
    const sectionValue = JSON.parse(JSON.stringify(sectionList))
    let section = true
    e.forEach((item: any) => {
      if (!item.konbShow && item.isChanged) {
        section = false
      }
    })
    sectionValue.sectionShow = section
    sectionValue.children = e
    props.changeSection(sectionValue, props.sectionIndex)
  }

  useEffect(() => {
    props?.sectionList?.children?.forEach((item: any) => {
      if (!item.isEmpty) {
        setSectionValue(true)
      }
    })
    setSectionList(props.sectionList)
  }, [props.sectionList])

  return (
    <div>
      {sectionValue && sectionList?.title !== undefined &&
        <div className='section'>
          <div className='section-left'>
            <div className='section-left-instroction'>{sectionList.title}</div>
          </div>
          <div className='section-right' onClick={() => {
            changeSectionList(!sectionList.sectionShow)
          }}>
            {
              !sectionList?.children?.length ? <div className='noValue'></div> : !sectionList?.ifUpdate ? <SvgIcon iconClass='noSelect' fontSize='24px' /> : sectionList.sectionShow ? <SvgIcon iconClass='allSelect' fontSize='24px' /> : <div className='section-right-none'></div>
            }
          </div>
        </div>
      }
      {sectionValue && <SubmitKnob borderShow={sectionList.title === undefined ? false : true} knobList={sectionList.children} changeKnob={(e: any) => changeKnob(e)}></SubmitKnob>}
    </div>
  )
}

export default SubmitSection