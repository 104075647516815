import React from 'react'
import './index.less'
import { Modal } from 'antd'
import SvgIcon from '@/components/SvgIcon'

type ModalCloseProps = {
    style?: React.CSSProperties
    className?: string
    onClose?: () => void
}

const ModalClose: React.FC<ModalCloseProps> = (props) => {

    const { style, className, onClose } = props

    return <div onClick={onClose} style={style} className={className ? 'modal-close ' + className : 'modal-close'}>
        <SvgIcon iconClass='modalclose' fontSize='24px' />
    </div>
}

export default ModalClose