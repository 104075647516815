import { LibroJupyterModel } from '@difizen/libro-jupyter';
import { Deferred, getOrigin, inject, prop, transient } from '@difizen/mana-app';
import { l10n } from '@difizen/mana-l10n';
import { Modal } from 'antd';
import { CloudLabLibroService } from './cloud-lab-libro-service';

@transient()
export class CloudLabLibroModel extends LibroJupyterModel {
  wsDefer = new Deferred<void>();
  get wsReady (){
    return this.wsDefer.promise;
  }
  @prop()
  ws?:WebSocket;

  @inject(CloudLabLibroService) protected cloudLabLibroService: CloudLabLibroService;

  sendWsMsg (msg: string){
    this.ws?.send(msg)
  }

  override canRun() {
    if(this.cloudLabLibroService.globalEnvStatus!==1) {
      Modal.confirm({
        title: "无法执行 Notebook！",
        content: '您还未启动环境，请先启动环境后再执行Notebook。',
        cancelText: '我知道了',
        okText: '启动环境',
        centered: true,
        width: 400,
        onOk(close) {
            close()
            // 直接启动环境：在启动环境组件里监听该消息
            window.postMessage({
                type: 'startupEnv'
            })
        }
      })
      return false
    }
    if (!this.kernelConnection) {
      alert(l10n.t('Kernel Connection 还没有建立'));
      return false;
    }

    if (this.kernelConnection.isDisposed) {
      alert('Kernel Connection disposed');
      return false;
    }

    return true;
  }

  async interrupt() {
    if(this.cloudLabLibroService.globalEnvStatus!==1) {
      Modal.confirm({
        title: "Notebook 无法进行执行能力相关操作",
        content: '您还未启动环境，请先启动环境后再进行',
        cancelText: '我知道了',
        okText: '启动环境',
        centered: true,
        width: 400,
        onOk(close) {
            close()
            // 直接启动环境：在启动环境组件里监听该消息
            window.postMessage({
                type: 'startupEnv'
            })
        }
      })
      return;
    }
    if (!this.kernelConnection) {
      alert(l10n.t('Kernel Connection 还没有建立'));
      return;
    }
    await this.kernelConnection.interrupt();
  }

  async shutdown() {
    if(this.cloudLabLibroService.globalEnvStatus!==1) {
      Modal.confirm({
        title: "Notebook 无法进行执行能力相关操作",
        content: '您还未启动环境，请先启动环境后再进行',
        cancelText: '我知道了',
        okText: '启动环境',
        centered: true,
        width: 400,
        onOk(close) {
            close()
            // 直接启动环境：在启动环境组件里监听该消息
            window.postMessage({
                type: 'startupEnv'
            })
        }
      })
      return;
    }
    if (!this.kernelConnection) {
      alert(l10n.t('Kernel Connection 还没有建立'));
      return;
    }

    await this.kernelConnectionManager.shutdownKC(this.currentFileContents);
    this.kernelConnection = undefined;
  }

  async restart() {
    if(this.cloudLabLibroService.globalEnvStatus!==1) {
      Modal.confirm({
        title: "Notebook 无法进行执行能力相关操作",
        content: '您还未启动环境，请先启动环境后再进行',
        cancelText: '我知道了',
        okText: '启动环境',
        centered: true,
        width: 400,
        onOk(close) {
            close()
            // 直接启动环境：在启动环境组件里监听该消息
            window.postMessage({
                type: 'startupEnv'
            })
        }
      })
      return;
    }
    if (!this.kernelConnection || this.kernelConnection.isDisposed) {
      this.startKernelConnection();
      getOrigin(this.kcReady)
        .then(() => {
          if (!this.kernelConnection) {
            alert(l10n.t('Kernel Connection 还没有建立'));
            return;
          }
          return;
        })
        .catch(console.error);
      return;
    }

    this.kernelConnection.restart();
  }

  async reconnect() {
    if(this.cloudLabLibroService.globalEnvStatus!==1) {
      Modal.confirm({
        title: "Notebook 无法进行执行能力相关操作",
        content: '您还未启动环境，请先启动环境后再进行',
        cancelText: '我知道了',
        okText: '启动环境',
        centered: true,
        width: 400,
        onOk(close) {
            close()
            // 直接启动环境：在启动环境组件里监听该消息
            window.postMessage({
                type: 'startupEnv'
            })
        }
      })
      return;
    }
    if (!this.kernelConnection) {
      alert(l10n.t('Kernel Connection 还没有建立'));
      return;
    }
    await this.kernelConnection.reconnect();
  }
}
