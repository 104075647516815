import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import { Tab } from '../index'
import Menu from '../Menu'
import SvgIcon from '../../../components/SvgIcon'

type ItabProps = {
    highlight?: boolean
    currentTab?: Tab
    changeCurrent?: Function
    closeTab?: Function
    menu?: object[]
}

const Itab: React.FC<ItabProps> = (props) => {

    const { highlight, currentTab, changeCurrent, closeTab, menu } = props

    const itab = useRef(null)

    const click = () => {
        if (highlight) {
            onmouseleave()
            Menu.open(menu as object[], itab.current)
        } else {
            changeCurrent?.(currentTab?.id)
        }
    }

    useEffect(() => {
        if (!highlight) {
            Menu.close()
        }
        return () => {
            Menu.close()
        }
    }, [highlight])

    // 关闭当前标签
    const close = () => {
        closeTab?.((currentTab as any)?.id)
    }

    const currentDiv = useRef<any>(null)

    const onMouseEnter = (e: any) => {
        const { instance, currentId } = Menu.getCurrent()
        if (instance && currentTab?.id === currentId) return
        const el = e?.target?.getBoundingClientRect()
        const left = el?.left + el?.width * 0.5
        const top = el?.bottom + 12
        const div = document.createElement('div')
        div.innerHTML = currentTab?.title ?? ''
        div.style.setProperty("position", 'absolute')
        div.style.setProperty("left", left + 'px')
        div.style.setProperty("top", top + 'px')
        div.style.setProperty("transform", 'translateX(-50%)')
        div.style.setProperty("z-index", '1000')
        div.classList.add('tabdiv-tip')
        document.body.appendChild(div)
        currentDiv.current = div
    }

    const onmouseleave = () => {
        currentDiv?.current?.remove()
    }

    return (
        <div className='itab' style={{ background: highlight ? "#1e1e1e" : "#252525" }} ref={itab} id={currentTab?.id}>
            { highlight && <div className='itab-current'></div> }
            <div className='itab-togater' onClick={click} >
                {
                    currentTab?.icon && <div className='itab-togater-icon'>
                        {
                            typeof currentTab?.icon === 'string' ? <img src={currentTab?.icon} alt="" /> :
                            currentTab?.icon
                        }
                    </div>
                }
                <div className='itab-togater-title' style={{ color: highlight ? "#FFFFFF" : "#9d9d9d" }} onMouseEnter={onMouseEnter} onMouseLeave={onmouseleave}><span>{currentTab?.title}</span></div>
                {
                    highlight && <div className='itab-togater-bot'>
                        <SvgIcon iconClass={'container-bottom'} style={{ width: '15px', height: '15px' }} />
                    </div>
                }
            </div>
            {
                closeTab && <>
                    <div className='itab-line'></div>
                    <div className='itab-close' onClick={close} >
                        <SvgIcon iconClass={'container-close'} style={{ width: '15px', height: '15px' }} />
                    </div>
                </>
            }
        </div>
    )
}

export default Itab