import { Button, Checkbox, Form, FormInstance, Input, Modal, Radio, RadioChangeEvent, Select, Space, TimePicker, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import SvgIcon from '@/components/SvgIcon'
import MiniRichEditor from '../MiniRichEditor'
import { handleTimeSecond } from '@/utils/time'
import KnowledgeFormItem from '../KnowledgeFormItem'
import ModalClose from '../ModalClose'

type ContentProps = {
    form?: FormInstance
    formData: any
    time: boolean
    initTime: string
    disableTime: boolean
    veriryTime?: (speed: number, id?: string) => any
    chapterId: string             // 小节id
    courseCode: string            // 课程code
    languageList: any[]
    template?: any
}

const Content: React.FC<ContentProps> = (props) => {

    const { form, time, initTime, veriryTime, formData, disableTime, chapterId, courseCode, languageList, template } = props

    const RichDom = useRef<any>(null)
    const [tip, setTip] = useState(false)

    const codeLanguage = Form.useWatch('codeLanguage')

    useEffect(() => {
        form?.setFieldsValue({
            codeLanguage: formData?.codeLanguage,
            title: formData?.title || '',
            buttonName: formData?.buttonName || '运行示例',
            time: formData?.time ? dayjs(formData.time, 'HH:mm:ss') : dayjs(initTime, 'HH:mm:ss'),
            showCode: !!formData?.showCode,
            knowledgeIds: formData?.knowledgeIds,
            required: !!formData?.required,
            template: formData?.template ?? (template?.experEnv ? 'follow' : 'default')
        })
    }, [formData])

    useEffect(() => {
        if (['shell', 'bash'].includes(codeLanguage ?? '')) {
            if (template?.experEnv) {
                form?.setFieldValue('template', 'follow')
            } else {
                form?.resetFields(['template'])
            }
        }
    }, [codeLanguage])


    return (
        <>
            <Form.Item label='代码语言' required>
                <Space align='center' size={30} style={{display: 'flex'}} className='codelanguage-space'>
                    <Form.Item
                        name='codeLanguage'
                        rules={[
                            { required: true, message: '请选择代码语言', validateTrigger: "onSubmit", whitespace: true },
                        ]}
                        validateFirst
                        noStyle
                    >
                        <Select
                            placeholder="请选择代码语言"
                            // fieldNames={{ label: 'dictCodeText', value: 'dictCode' }}
                            options={languageList}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                        />
                    </Form.Item>
                    <span style={{color: 'red', visibility: ['shell', 'bash'].includes(codeLanguage ?? '') ? 'visible' : 'hidden'}}>当选择shell时，仅支持运行在配置的实验环境中，若无环境，请先配置。</span>
                </Space>
            </Form.Item>

            {/* <Form.Item
                label='代码语言'
                name='codeLanguage'
                rules={[
                    { required: true, message: '请选择代码语言', validateTrigger: "onSubmit", whitespace: true },
                ]}
                validateFirst
            >
                <Select
                    placeholder="请选择代码语言"
                    // fieldNames={{ label: 'dictCodeText', value: 'dictCode' }}
                    options={languageList}
                    // options={[
                    //     {
                    //         label: 'shell',
                    //         value: 'shell'
                    //     },
                    //     {
                    //         label: 'java',
                    //         value: 'java'
                    //     },
                    //     {
                    //         label: 'python',
                    //         value: 'python'
                    //     },
                    //     {
                    //         label: 'c',
                    //         value: 'c'
                    //     },
                    //     {
                    //         label: 'c++',
                    //         value: 'cpp'
                    //     },
                    //     {
                    //         label: 'c#',
                    //         value: 'csharp'
                    //     },
                    //     {
                    //         label: 'javascript',
                    //         value: 'javascript'
                    //     },
                    //     {
                    //         label: 'typescript',
                    //         value: 'typescript'
                    //     },
                    //     {
                    //         label: 'vb',
                    //         value: 'vb'
                    //     },
                    //     {
                    //         label: 'php',
                    //         value: 'php'
                    //     },
                    //     {
                    //         label: 'golang',
                    //         value: 'golang'
                    //     },
                    //     {
                    //         label: 'rust',
                    //         value: 'rust'
                    //     },
                    //     {
                    //         label: 'html',
                    //         value: 'html'
                    //     },
                    // ]}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                />
            </Form.Item> */}

            <Form.Item label='实验环境' required>
                <Space align='center'>
                    <Form.Item
                        name='template'
                        rules={[
                            { required: true, message: '请选择实验环境', validateTrigger: "onSubmit", whitespace: true },
                        ]}
                        noStyle
                        validateFirst
                    >
                        <Radio.Group disabled={['shell', 'bash'].includes(codeLanguage ?? '')}>
                            <Radio value={'default'}>默认环境</Radio>
                            {
                                !!template?.experEnv && <Radio value={'follow'}>{template?.name}</Radio>
                            }
                        </Radio.Group>
                    </Form.Item>
                    <Typography.Link onClick={() => { setTip(true) }}>+配置小节环境</Typography.Link>
                </Space>
            </Form.Item>

            <Form.Item
                label='演示内容'
                name='title'
                required
                rules={[
                    // { required: true, message: '请输入题干内容', validateTrigger: "onSubmit", whitespace: true },
                    {
                        required: true, validator(rule, value) {
                            const res = RichDom?.current?.validateField()
                            if (!res?.success) {
                                return Promise.reject('请输入演示内容')
                            }
                            return Promise.resolve()
                        }, validateTrigger: "onSubmit"
                    }
                ]}
                validateFirst
            >
                <div>
                    <MiniRichEditor
                        ref={RichDom}
                        height={220}
                        value={formData?.title ?? ''}
                        placeholder='请输入演示内容'
                        onChange={(value: string) => {
                            form?.setFieldValue('title', value)
                        }}
                    />
                </div>
            </Form.Item>

            <Form.Item
                label='按钮名称'
                name='buttonName'
                rules={[
                    { required: true, message: '请输入按钮名称', validateTrigger: "onSubmit", whitespace: true },
                    // { min: 3, message: '请至少输入3个字', validateTrigger: 'onSubmit', whitespace: true }
                ]}
                validateFirst
            >
                <Input
                    showCount
                    maxLength={6}
                    placeholder='请输入按钮名称'
                    allowClear
                    style={{ width: '180px' }}
                />
            </Form.Item>

            {
                time && <Form.Item
                    label="触发时间"
                    name='time'
                    rules={[
                        { required: true, message: '请输入触发时间', validateTrigger: "onSubmit", type: 'object' },
                        {
                            validator(_, _value) {
                                if (veriryTime) {
                                    const res = veriryTime(handleTimeSecond(_value), formData?.id)
                                    if (res.success) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(res.message ?? '')
                                    }
                                }
                                return Promise.resolve()
                            },
                            validateTrigger: "onSubmit"
                        }
                    ]}
                    validateFirst
                >
                    <TimePicker
                        disabled={disableTime}
                        defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                        placeholder='请输入触发时间'
                        showNow={false}
                        style={{ width: '180px' }}
                    />
                </Form.Item>
            }

            <KnowledgeFormItem form={form} chapterId={chapterId} courseCode={courseCode} />

            <Form.Item label='显示示例代码' >
                <Form.Item
                    name='showCode'
                    valuePropName="checked"
                    validateFirst
                    noStyle
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <div className='item-tip'>
                    <SvgIcon iconClass={'promptIcon'} fontSize='14px' />
                    <span>勾选显示示例代码后，初始代码对学习者可见。</span>
                </div>
            </Form.Item>

            <Form.Item label='是否必答' >
                <Form.Item
                    name='required'
                    valuePropName="checked"
                    validateFirst
                    noStyle
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <div className='item-tip'>
                    <SvgIcon iconClass={'promptIcon'} fontSize='14px' />
                    <span>勾选必答后，学习者必须执行该示例演示才可以进行下一步操作。</span>
                </div>
            </Form.Item>

            <Modal
                open={tip}
                wrapClassName='demo-content-template-modal'
                onCancel={() => { setTip(false) }}
                footer={false}
                closable={false}
                centered={true}
                width={474}
                maskClosable={false}
            >
                <ModalClose className='close' onClose={() => { setTip(false) }} />
                <p className='title'>配置小节环境</p>
                <p className='tips'>
                    <span>1.</span>
                    <span>支持使用小节环境或使用平台默认环境；</span>
                </p>
                <p className='tips'>
                    <span>2.</span>
                    <span>若需使用小节环境，请先在课程工作台中编辑小节，设置小节环境，如下图所示</span>
                </p>
                <img className='img' src={require('../../assets/images/add-template-tip.png')} alt="" />
                <Space align='center' style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type='primary' onClick={() => { setTip(false) }}>我知道了</Button>
                </Space>
            </Modal>
        </>
    )
}

export default Content