import React, { useEffect, useState } from 'react';
import './index.less';
import { Button, Form, Input, Modal, message } from 'antd';
import { gitClone } from '@/api/modules/fs';
import SvgIcon from '../SvgIcon'


type EnlargeProps = {
  ifTrue: boolean,
  changeValue: () => void;
  cloneGitValue?: (v: any) => void;
}

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const ImportModal: React.FC<EnlargeProps> = (props) => {
  const { ifTrue, changeValue, cloneGitValue } = props

  const [validGitUrl, setValidGitUrl] = useState(true); // 受控的校验状态
  const [gitUrlHelp, setGitUrlHelp] = useState(''); // 校验的提示信息
  // 删除图标
  const [deleteIcon, setDeleteIcon] = useState<boolean>(false);

  //是否处于导入状态
  const [importLoding, setImportLoding] = useState(false);

  const [form] = Form.useForm();

  const [fields, setFields] = useState<FieldData[]>(
    [
      { name: ['gitHub'], value: '' },
    ]);

  //是否处于提交
  const [ifSubmit, setIfSubmit] = useState(false)

  const onFinish = (values: any) => {
    console.log(values);
    if (cloneGitValue) {
      cloneGitValue(values?.gitHub || '')
    }

  };

  const handGit = async () => {
    if (!ifSubmit) return
    const regex = /^(https):\/\//;
    if (fields[0].value) {
      if (regex.test(fields[0].value)) {
        setValidGitUrl(true);
        setGitUrlHelp('');
      } else {
        setValidGitUrl(false);
        setGitUrlHelp('地址无效，请重新输入');
        throw new Error('地址无效，请重新输入');
      }

    } else {
      setValidGitUrl(false);
      setGitUrlHelp('请输入Git地址');
      throw new Error('请输入Git地址');
    }
  }

  // 每次进入清空
  useEffect(() => {
    if (ifTrue) {
      setFields([
        { name: ['gitHub'], value: '' },
      ])
    }
  }, [ifTrue])


  return (
    <Modal wrapClassName={'importModal'} open={ifTrue} footer={''} width={'520px'} destroyOnClose centered>
      <div className='modalAll'>
        <div style={{ position: 'absolute', top: '4px', right: '4px', cursor: 'pointer' }}>
          <div onClick={() => {
            changeValue()
            setDeleteIcon(false)
          }} onMouseEnter={() => setDeleteIcon(true)} onMouseLeave={() => setDeleteIcon(false)}>
            {deleteIcon ? <SvgIcon iconClass={'closeIconhover'} style={{ width: '24px', height: '24px' }} /> : <SvgIcon iconClass={'closeIconModal'} style={{ width: '24px', height: '24px' }} />}
          </div>
        </div>
        <div className='modalAll-title'>打包克隆Git项目文件</div>
        <div style={{ width: '100%', padding: '30px 0 0' }}>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{}}
            fields={fields}
            onFieldsChange={(_, allFields) => {

              setFields(allFields);
            }}
          >

            <Form.Item label='Git项目地址'>
              <Form.Item
                name='gitHub'
                validateStatus={validGitUrl ? 'success' : 'error'}
                help={gitUrlHelp}
                rules={[
                  { validator: handGit },
                ]}
                noStyle
              >
                <Input placeholder="请输入Git地址" autoComplete="off" allowClear onFocus={() => {
                  setIfSubmit(false)
                }}
                />
              </Form.Item>
            </Form.Item>


            <Form.Item noStyle>
              <div style={{ marginTop: '40px', textAlign: 'center' }}>
                <Button className="bottonLeft" onClick={() => {
                  changeValue()
                }} >
                  取消
                </Button>
                <Button type="primary" htmlType="submit" className="bottonRigth" loading={importLoding} onClick={() => {
                  setIfSubmit(true)
                }} >
                  {importLoding ? '导入中' : '克隆'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default ImportModal;