import React from 'react'
import './index.less'
import SvgIcon from '../../../components/SvgIcon'

type TopNavProps = {
    title: string         // 标题
    back?: () => void      // 返回上一级
    close?: () => void     // 关闭
}

const TopNav: React.FC<TopNavProps> = (props) => {

    const { title, back, close } = props

    return (
        <div className='topnav'>
            <span className='topnav-title'>{title}</span>
            <SvgIcon iconClass='ques-return' style={{ width: '16px', height: '16px' }} className='topnav-left' onClick={() => {back?.()}} />
            <SvgIcon iconClass='ques-fold' style={{ width: '16px', height: '16px' }} className='topnav-right' onClick={() => {close?.()}}/>
        </div>
    )
}

export default TopNav