import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import { Input, InputRef } from 'antd'

export type MoreMenuItemProps = {
    title: string
    onClick: () => void

    [other: string]: any
}

export type MoreMenuProps = {
    show: boolean
    offset?: { top: number, left: number }
    menus: Array<MoreMenuItemProps>
}

const MoreMenu: React.FC<MoreMenuProps> = (props) => {

    const inputRef = useRef<InputRef>(null);
    // const postMessage = () => {
    //     console.log('发送事件');

    //     window.postMessage({
    //         type: 'inputBlur',
    //     })
    // }
    useEffect(() => {
        if (props?.show) {
            // inputRef?.current!.focus();
        }
    }, [props?.show])

    return (
        <>
            {
                props?.show && (
                    <div id='more-menu-id' className='more-menu' style={{ top: props?.offset?.top ?? 0, left: props?.offset?.left ?? 0 }}>
                        {
                            props?.menus && props?.menus?.map((it: MoreMenuItemProps) => {
                                return (<div key={it?.title} className='more-menu-item' onClick={() => {
                                    // inputRef?.current!.focus();
                                    it?.onClick && it?.onClick()

                                }}>{it?.title}</div>)
                            })
                        }
                    </div>
                )
            }
            {/* <div style={{ position: 'fixed', top: '-100px' }}>
                <Input
                    ref={inputRef}
                    color='111'
                    placeholder="请输入层级名称"
                    value={'111'}
                    showCount maxLength={30}
                    onBlur={postMessage}
                />
            </div> */}
        </>
    )
}

export default MoreMenu