import http from "../index";

// 配置端口
export async function setPort(data: any) {
    return http.post(`/cmd/config/ports`, data)
}

// 查询端口
export async function queryPort(data: any) {
    return http.post(`/cmd/config/ports/get`, data)
}

// 删除端口
export async function delPort(data: any) {
    return http.post(`/cmd/config/ports/delete`, data)
}

// 初始化端口
export async function initPort(data: any) {
    return http.post(`/cmd/config/ports/init`, data)
}

// 重置端口
export async function resetPort(data: any) {
    return http.post(`/cmd/config/ports/all`, data)
}





