import React, { useEffect, useRef, useState } from 'react'
import { message } from 'antd'
import './index.less'
import file_svg from '../../icons/file.svg'
import folder_svg from '../../icons/folder-open.svg'
import more_svg from '../../icons/more.svg'
import { TreeNodeProps } from '../../types'
import { useDrag, useDrop } from 'react-dnd'
import SvgIcon from '../../../SvgIcon'
import utils from '../../utils'

const TreeNode: React.FC<TreeNodeProps> = (props) => {

    const input = useRef<any>()

    const { isDrag, changeNodes, data, selected } = props

    // 单击
    const onClick = (event: any): any => {
        //判断是否为上传中
        if (props?.data?.ifUpload) {
            message.destroy()
            message.warning('文件上传中')
            return
        }
        event.preventDefault()
        if (!props?.data) {
            props.data = {}
        }
        if (props?.data?.edit) {
            return false
        }
        props.data.fold = !props?.data?.fold
        props?.onClick && props?.onClick(props?.data)
    }

    // 双击
    const onEntryEdit = (event: any): any => {
        event.preventDefault()
        event.stopPropagation()
        if (props?.data?.ifUpload) return
        if (!props?.data) {
            props.data = {}
        }
        if (!props?.data?.edit) {
            props.data.edit = true
            props?.onEntryEdit && props?.onEntryEdit(props?.data)
        }
        return false
    }

    // 回车
    const onKeyDown = (event: any): any => {
        if (event.keyCode === 13) {
            onBlurEdit()
        }
    }

    // 取消
    const onBlurEdit = (): any => {
        const value = input?.current?.value
        if (!value) {
            return false
        }
        if (!props?.data) {
            props.data = {}
        }
        props.data.title = value
        props.data.edit = false
        props?.onBlurEdit && props?.onBlurEdit(props?.data)
    }

    // 更多
    const onEntryMore = (event: any) => {
        event?.preventDefault()
        event?.stopPropagation()
        props?.onEntryMore && props?.onEntryMore(props?.data, event)
    }

    // 输入框全选
    const inputSelectAll = () => {
        props?.data?.edit && input?.current?.select()
    }

    // 编辑
    useEffect(() => {
        inputSelectAll()
    }, [props?.data?.edit])

    const [{ isDragging }, drag] = useDrag({
        type: data?.describe?.type || 'none',  // 拖拽的类型，只有同类型才允许相互拖拉放置
        item: {
            current: data  // 节点
        },
        canDrag: () => isDrag as boolean, // 控制是否允许拖拉
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item: any, monitor: any) => {
            // console.log('拖拽结束')
            // console.log(item)
        },
    })
    // 拖动源在列表上面进行显示  0 表示没有在上面    1 表示要放在该列表的前面  2 表示放到该列表的后面
    const [isHover, setIsHover] = useState(0)
    // 获取当前列表的位置
    const [domObj, setDomObj] = useState<any>()

    useEffect(() => {
        if (data?.path) {
            const node = document.getElementById(data.path + data?.size)
            setDomObj(node?.getBoundingClientRect())
        }
    }, [data])

    const [{ isOver, path }, drop] = useDrop(
        () => ({
            accept: utils.judge(data?.describe?.type) || 'none', // 允许放置的节点
            hover(item: any, monitor: any) {
                if (item.current.path === data?.path) return
                const { y } = monitor.getClientOffset()
                if (domObj?.y + domObj?.height / 2 < y) {
                    setIsHover(2)
                } else {
                    setIsHover(1)
                }
                // console.log(y)
            },
            drop: (item: any, monitor: any) => {
                changeNodes?.(item.current, data, isHover)
            },
            collect: (monitor: any) => {
                return {
                    isOver: monitor.isOver(),
                    path: monitor.getItem()
                }
            }
        }),
        [props?.nodes, domObj, isHover]
    )

    useEffect(() => {
        if (!isOver || path?.current?.path === data?.path) {
            setIsHover(0)
        }

    }, [isOver])

    // 如果为编辑态，滚动到可视区域
    useEffect(() => {
        if (props?.data?.edit && data?.path + data?.size) {
            const el = document.getElementById(data?.path + data?.size)
            el?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
        }
    }, [props?.data?.edit])

    return (
        <>
            <div className={[
                'tree-node',
                props?.data?.path === props?.selected?.path ? 'selected' : '',
                isDragging ? 'tree-drag' : '',
                isHover ? (isHover === 1 ? 'tree-hover-before' : 'tree-hover-after') : ''
            ].join(' ')}
                ref={(node) => drag(drop(node))}
                style={{ "paddingLeft": 8 * (props?.data?.path?.match(/\//g)?.length ?? 0) + 10 }}
                id={data?.path + data?.size}
                onClick={onClick} onDoubleClick={props?.doubleClickToEdit ? onEntryEdit : () => { }}>
                {
                    props?.data?.ifUpload ? <SvgIcon iconClass='resourcefile' ></SvgIcon> :
                    // fold:true 展开
                    props?.data?.fold ? (
                        props?.data?.expandIcon ? props?.data?.expandIcon : <img className='icon' />
                    ) : (
                        props?.data?.foldIcon ? props?.data?.foldIcon : <img className='icon' />
                    )
                }
                {!props?.data?.edit &&
                    <>
                        <div className='title'>
                            {props?.data?.title}
                        </div>
                        {!props?.data?.ifUpload && props?.showMoreIcon &&
                            (
                                <div 
                                    onClick={onEntryMore}
                                    className={['icon', 'right'].join(' ')}>
                                    {props?.moreIcon}
                                </div>
                            )
                        }
                    </>
                }
                {!props?.data?.ifUpload && props?.data?.edit &&
                    <input ref={input}
                        defaultValue={props?.data?.title}
                        onKeyDown={onKeyDown}
                        onBlur={onBlurEdit}
                        className='tree-node-input'
                    />
                }
                {props?.data?.ifUpload &&
                    <div className='rotate-element'>
                        <SvgIcon iconClass='resourceLoaing' ></SvgIcon>
                    </div>
                }
            </div>
            {
                props?.data?.fold && !props?.data?.edit && props?.data?.children?.map((child: any) => {
                    return (
                        <TreeNode
                            key={child?.path + child?.size}
                            data={child}

                            isDrag={isDrag}
                            changeNodes={changeNodes}
                            nodes={props?.nodes}

                            selected={props?.selected}
                            doubleClickToEdit={props?.doubleClickToEdit ?? false}
                            showMoreIcon={props?.showMoreIcon ?? true}
                            moreIcon={props?.moreIcon}
                            onClick={props?.onClick}
                            onEntryEdit={props?.onEntryEdit}
                            onBlurEdit={props?.onBlurEdit}
                            onEntryMore={props?.onEntryMore}
                        />
                    )
                })
            }
        </>
    )

}

export default TreeNode