/*
 * 新增编辑目录信息
 */
import React, { useEffect, useState } from 'react'

import './index.less'
import SvgIcon from '../../SvgIcon'
import SubmitSection from '../SubmitSection';



export type SubmitStageProps = {
  stageList: any
  stageIndex: number
  changeList: (v: any, i: number) => void
}

const SubmitStage: React.FC<SubmitStageProps> = (props) => {

  const [stageList, setStageList] = useState(props.stageList)

  const [stageValue, setStageValue] = useState(false)

  const changeStageShow = () => {
    const stageValue = JSON.parse(JSON.stringify(stageList))
    stageValue.stageShow = !stageList.stageShow
    if (stageValue?.children?.length > 0) {
      stageValue?.children?.forEach((item: any) => {
        if (!stageList.stageShow) {
          item.sectionShow = true
          item?.children?.forEach((items: any) => {
            items.konbShow = true
          })
        } else {
          item.sectionShow = false
          item?.children?.forEach((items: any) => {
            items.konbShow = false
          })
        }
      })
    }
    props.changeList(stageValue, props.stageIndex)
  }

  //修改单个章节
  const changeSection = (e: any, index: number) => {
    const stageValue = JSON.parse(JSON.stringify(stageList))
    stageValue.children[index] = e
    let stageSelect = true
    stageValue.children.forEach((item: any) => {
      if (!item.sectionShow && item?.children) {
        stageSelect = false
      }
    })
    stageValue.stageShow = stageSelect
    props.changeList(stageValue, props.stageIndex)
  }

  useEffect(() => {
    if (props?.stageList?.children?.length > 0) {
      props?.stageList?.children?.forEach((item: any) => {
        if (item?.children?.length > 0) {
          item?.children?.forEach((aaItem: any) => {
            if (!aaItem.isEmpty) {
              setStageValue(true)
            }
          })
        }
      })
      setStageList(props.stageList)
      // console.log(props.stageList);
      // console.log(props.stageIndex);
      // console.log(stageList.stageShow);
    }

  }, [props.stageList])


  return (
    <div>
      {
        stageValue && stageList.title !== undefined &&
        <div className='stage'>
          <div className='stage-left'>
            <div className='stage-left-index'>{props.stageIndex > 8 ? props.stageIndex : `0${props.stageIndex + 1}`}</div>
            <div className='stage-left-name'>{stageList.title}</div>
          </div>
          <div className='stage-right' onClick={() => {
            changeStageShow()
          }}>
            {
              !stageList?.children?.length ? <div className='noValue'></div> : !stageList?.ifUpdate ? <SvgIcon iconClass='noSelect' fontSize='24px' /> : stageList.stageShow ? <SvgIcon iconClass='allSelect' fontSize='24px' /> : <div className='stage-right-none'></div>
            }
          </div>
        </div>
      }
      {stageValue && stageList.children?.map((item: any, index: number) => {
        return <SubmitSection key={index} sectionList={item} sectionIndex={index} changeSection={(e: any, index: number) => changeSection(e, index)}></SubmitSection>
      })}
    </div>
  )
}

export default SubmitStage