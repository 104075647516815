import { Button, Modal } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import './index.less'
import SharedDataContext from '../../../utils/share'

type ProjectRunProps = {
  modal?: string | null
  section: any
  userAnser: string
  examType?: string | null //考试类型
  examDetail?: any //考试详情
  [other: string]: any
}

const ProjectRun: React.FC<ProjectRunProps> = (props) => {

  const { section, userAnser } = props

  const { globalEnvPod } = useContext(SharedDataContext)
  //运行按钮
  const [running, setRunning] = useState(false)

  //点击运行
  const clickRun = () => {
    // if (!running) {
    getShell()
    // }
  }

  // 获取shell工具
  const getShell = () => {
    if (globalEnvPod?.pod && section?.template?.experEnv) {
      // setRunning(true)
      window.postMessage({
        type: 'pushContainer',
        component: 'Xterm',
        value: {
          projectRun: userAnser
        }
      })
    } else {
      showModal()
    }
  }
  const showModal = () => {
    Modal.confirm({
      title: "工具无法打开！",
      content: '您还未启动环境，请先启动环境后再使用工具。',
      cancelText: '我知道了',
      okText: '启动环境',
      centered: true,
      width: 400,
      onOk(close) {
        close()
        // 直接启动环境：在启动环境组件里监听该消息
        window.postMessage({
          type: 'startupEnv'
        })
      }
    })
  }

  return (
    <>
      <div className='project-run'>
        <div className='project-runBtn' onClick={clickRun}>
          <Button loading={running} ghost>运行</Button>
        </div>
      </div>
    </>

  )
}

export default ProjectRun;