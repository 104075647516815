import React from 'react'
import './index.less'
import SvgIcon from '../../../components/SvgIcon'
import { Tooltip } from 'antd'
import { courseTypes } from '../../../utils/commonTypes'

type QuesItemProps = {
    data: dataType
    courseType: string     // 课程类型
    onClick?: Function
}

type dataType = {
    id: string             // 唯一标识
    user: any              // 用户信息
    createDateTime: string // 创建时间
    type: any              // 问答类型
    learn: boolean         // 是否影响学习进度
    labels: any[]          // 标签
    cooperate: boolean     // 是否开启协同
    cooperateUrl: string   // 协同链接
    chapter: string        // 所属小节
    title: string          // 标题
    answerCount: number    // 回复数
    [key: string]: any
}

const QuesItem: React.FC<QuesItemProps> = (props) => {

    const { data, onClick, courseType } = props

    return (
        <div className='quesitem' onClick={() => {
            onClick?.(data?.id)
        }}>
            <div className='quesitem-left'>
                <img src={typeof data?.user?.avatar === 'string' ? data?.user?.avatar : data?.user?.avatar?.custom} alt="" />
            </div>

            <div className='quesitem-right'>
                <div className='quesitem-right-recover'>
                    <SvgIcon iconClass='ques-recover' style={{width: '16px', height: '16px'}}/>
                    <span>{data?.answerCount}</span>
                </div>

                <p className='quesitem-right-one'>
                    <span className='quesitem-right-one-user'>{data?.user?.display_name || data?.user?.displayName}</span>
                    <span className='quesitem-right-one-time'>{data.createDateTime}</span>
                </p>

                {
                    (data?.type?.title || data?.learn ) &&
                    <div className='quesitem-right-two'>
                        {
                            data?.type?.title && <span>#{data?.type?.title}#</span>
                        }
                        {
                            data?.learn && <span>#影响学习进度#</span>
                        }
                    </div>
                }
                
                <p className='quesitem-right-title'>{data.title}</p>
                
                {
                    data?.labels?.length ? <div className='quesitem-right-four'>
                        {
                            data?.labels?.map((item) => {
                                return <span key={item}>{item}</span>
                            })
                        }
                    </div> : <></>
                }

                <div className='quesitem-right-five'>
                    {
                        courseType === courseTypes.COURSE && <p className='quesitem-right-five-title'>
                            <span>来自：</span>
                            <span>{data.chapter}</span>
                        </p>
                    }
                    {
                        data?.cooperate ? <p className='quesitem-right-five-title'>
                            <span>协同链接：</span>
                            <span onClick={(e) => {
                                e.stopPropagation()
                                data.cooperateUrl && window.open(data.cooperateUrl)
                            }}>点击进入协同</span>
                        </p> : <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default QuesItem