import React, { useEffect, useState } from 'react'
import './index.less'
import { Form, Input, Modal, message } from 'antd'
import { getPortImg } from '../../api/modules/community'

export type AddToolsProps = {
    open?: boolean,              // 是否显示
    onCancel?: () => void        // 关闭显示
    finish?: (data: any) => void // 确定
    ports?: any                  // 所有的端口
    initValue?: any              // 初始值
}

const AddTools: React.FC<AddToolsProps> = (props) => {

    const [form] = Form.useForm()

    const { open, onCancel, finish, ports, initValue } = props

    const [portImg, setPortImg] = useState<any>([])    // 选择的图片列表

    const [name, setName] = useState('')               // 工具名称
    const [port, setPort] = useState('')               // 开放端口

    // 表单校验
    const verify = () => {
        if (!name) {
            message.destroy()
            message.warning('请输入工具名称')
            return
        }
        const re = /^[\u4E00-\u9FA5a-zA-Z0-9_-]+$/
        if (!re.test(name)) {
            message.destroy()
            message.warning(`请输入3-20字符的中英文、数字、_-`)
            return
        }
        if (!port) {
            message.destroy()
            message.warning('请输入端口号')
            return
        }
        if (port) {
            // const patn = /^([12]\d{4}|30000|[1-9]\d{3})$/
            const patn = /^(?:[1-9]\d{0,3}|[1-2]\d{4}|30000)$/
            if (!patn.test(port)) {
                message.destroy()
                // message.warning('请输入1000-30000的数字')
                message.warning('请输入1-30000的数字')
                return
            }
            let is = false
            if (initValue?.port) {
                is = ports?.some((item: any) => {
                    if (parseInt(port) === initValue?.port) {
                        return false
                    }
                    return parseInt(item.port) === parseInt(port)
                })
            } else {
                is = ports?.some((item: any) => {
                    return parseInt(item.port) === parseInt(port)
                })
            }
            if (is) {
                message.destroy()
                message.warning('端口重复')
                return
            }
        }
        const img = portImg.find((item: any) => {
            return item.live
        })
        finish?.({
            name,
            port,
            image: img.image,
            oldPort: initValue?.port
        })
        onCancel?.()
    }

    // 选择图片
    const choiceImg = (pictureId: string) => {
        const imgs = portImg.map((item: any) => {
            if (item.pictureId === pictureId) {
                return { ...item, live: true }
            }
            return { ...item, live: false }
        })
        setPortImg(imgs)
    }

    // 获取图片
    useEffect(() => {
        if (open) {
            getPortImg().then(res => {
                if (initValue && Object?.keys(initValue)?.length) {
                    form?.setFieldsValue({
                        toolsName: initValue?.name ?? '',
                        openPort: initValue?.port?? ''
                    })
                    setName(initValue?.name ?? '')
                    setPort(initValue?.port ?? '')
                    let have = true
                    const result = res?.course?.map((item: any) => {
                        const live = item?.image === initValue?.image
                        if (live) {
                            have = false
                        }
                        return {...item, live}
                    })
                    if (have && result?.length) {
                        result[0].live = true
                    }
                    setPortImg(result)
                } else {
                    if (res.course?.length) {
                        res.course[0].live = true
                    }
                    setPortImg(res.course)
                }
            }).catch(() => { })
        } else {
            form?.setFieldsValue({
                toolsName: '',
                openPort: ''
            })
            form?.resetFields()
            setName('')
            setPort('')
            setPortImg([])
        }
    }, [open, initValue])

    return <Modal
        open={open}
        wrapClassName={'ylzcc-addtools'}
        onCancel={onCancel}
        footer={false}
        closable={false}
        centered={true}
        width={532}
        maskClosable={false}
        destroyOnClose={true}
    >
        <p className="ylzcc-addtools-title">{initValue ? '编辑' : '添加'}工具</p>
        <Form
            name="AddTools"
            colon={false}
            validateTrigger='onBlur'
            autoComplete="off"
            form={form}
        >
            <Form.Item
                label="工具名称"
                name='toolsName'
            >
                <Input
                    placeholder="请输入工具名称"
                    showCount
                    maxLength={10}
                    value={name}
                    allowClear
                    onChange={(e) => {
                        setName(e.target.value)
                    }} />
            </Form.Item>

            <Form.Item
                label="开放端口"
                name='openPort'
            >
                <Input
                    placeholder="请输入端口号"
                    value={port}
                    allowClear
                    onChange={(e) => {
                        setPort(e.target.value)
                    }} />
            </Form.Item>

            <Form.Item label="工具图标" >
                <div className='add-tools-list'>
                    {
                        portImg?.map((item: any) => {
                            return <div className={item?.live ? 'add-tools-list-item add-tools-list-light' : 'add-tools-list-item'} key={item?.pictureId} onClick={() => { choiceImg(item?.pictureId) }}>
                                <img src={item?.image} alt="" />
                            </div>
                        })
                    }
                </div>
            </Form.Item>

            <Form.Item>
                <div className='ylzcc-addtools-button'>
                    <button className='ylzcc-addtools-button-can' type='button' onClick={(e) => { e.preventDefault(); onCancel?.() }}>取消</button>
                    <button className='ylzcc-addtools-button-en' type='button' onClick={(e) => { e.preventDefault(); verify() }}>保存</button>
                </div>
            </Form.Item>

        </Form>
    </Modal>
}

export default AddTools