import React from 'react'
import './index.less'

type ST = {
    type: 'finish' | 'unfinish'
    title: string
}

type StepProps = {
  onClick?: (pre: number, next: number, steps?: Array<ST>) => void  
  steps: Array<ST>
  current: number
  style?: React.CSSProperties
}

const Step: React.FC<StepProps> = (props) => {

    const { onClick, steps, current, style } = props

    return (
        <div className='step-arrow' style={style}>
            {
                steps.map((item, index) => {
                    let type = 'middle'
                    if (index === 0) {
                        type = 'start'
                    } else if (index === steps.length - 1) {
                        type = 'end'
                    }
                    return <div className='step-arrow-item' key={index} data-current={current === index} data-status={item.type} data-type={type} onClick={() => {
                        onClick?.(current, index, steps)
                    }}>
                        {item.title}
                    </div>
                })
            }
        </div>
    )
}

export default Step