

// 支持的文件类型
export const fileTypes =  {
    'py': 'python',
    'json': 'json',
    'txt': 'txt',
    'java': 'java',
    'word': 'word',
    'pdf': 'pdf',
    'html': 'html',
    'js': 'js',
    'css': 'css',
    'md': 'md'
} as any