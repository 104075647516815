import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import { Button, Drawer } from 'antd'
import Content from './Content'
import Table, { ColumnsType } from 'antd/es/table'
import { getQueryStringValue } from '../../../../../utils'
import { getQuestionExampleList, saveExamProjectAns } from '../../../../../api/modules/community'

type ScoreDrawerMarkProps = {
    title?: React.ReactNode   // 标题
    open: boolean             // 显示
    onClose: () => void       // 关闭
}

type TableType = {
    index: number            // 标识
    typeDesc: string         // 类型描述
    content: string          // 内容
    score: number            // 分数
    [key: string]: any
}

const ScoreDrawerMark: React.FC<ScoreDrawerMarkProps> = (props) => {

    const { open, onClose, title = '' } = props

    // 考试记录编码
    const examRecordCode = getQueryStringValue('examRecordCode')

    // 题目编码
    const questionCode = getQueryStringValue('questionCode')

    const dom = useRef<any>(null)            // 表格dom  用来计算高度
    const [length, setLength] = useState(0)  // 高度

    // 动态设置表格高度
    useEffect(() => {
        const el = dom.current as HTMLDivElement
        if (open && el) {
            setLength(el.clientHeight - 80)
        }
    }, [open])


    const [tableData, setTableData] = useState<any>([]) // 表格数据
    const [score, setScore] = useState<any>([])         // 表格数据对应的分数
    const [total, setTotal] = useState<any>(0)         // 总分

    const columns: ColumnsType<TableType> = [
        {
            title: '判分点',
            dataIndex: 'index',
            className: 'order',
            align: 'center',
            width: '15%',
            render: (value) => <div className='centerstart'>{value + 1}</div>
        },
        {
            title: '类型',
            dataIndex: 'typeDesc',
            className: 'type',
            align: 'center',
            width: '15%',
            render: (value) => <div className='centerstart'>{value}</div>
        },
        {
            title: '内容',
            dataIndex: 'content',
            className: 'scoredrawermarkwork-content',
            align: 'center',
            width: '40%',
            render: (value: string) => <div className='content'>
                <Content content={value} />
            </div>
        },
        {
            title: '分值',
            dataIndex: 'score',
            className: 'type',
            align: 'center',
            width: '15%',
            render: (value) => <div className='centerstart'>{value}</div>
        },
        {
            title: '得分',
            dataIndex: 'userScore',
            className: 'scoredrawermarkwork-score',
            align: 'center',
            width: '15%',
            render: (value: number, record: any) => <div className='scoredrawermarkwork-score-con'>
                <input type="text" className='input' value={score[record.index]} onInput={(e) => changeScore(record.index, e)} onBlur={(e) => saveScore(record.index, e, record)} />
            </div>
        }
    ]

    // 监听题目是否发生变化
    useEffect(() => {
        if (!open || !examRecordCode || !questionCode) return
        fetchMark(questionCode, examRecordCode)
    }, [open])

    // 获取判分相关数据
    const fetchMark = async (questionCode: string, examRecordCode: string) => {
        const inpOut = await getQuestionExampleList({ questionCode, examRecordCode }).catch(() => { })
        if (!inpOut) return
        setTableData(inpOut)
        let to = 0
        const score = inpOut?.map((item: any) => {
            to += item.userScore || 0
            return item.userScore || 0
        })
        setScore(score)
        setTotal(to)
    }

    // 分数控制
    const changeScore = (index: number, e: any) => {
        const inputValue = e.target.value;
        // if (inputValue === '') return
        let max = 100
        if (/^\d*$/.test(inputValue) && Number(inputValue) >= 0 && Number(inputValue) <= max) {
            const sc = [...score]
            sc[index] = Number(inputValue) <= tableData[index]?.score ? Number(inputValue) : tableData[index]?.score
            setScore(sc)
        }
    }

    // 分数保存
    const saveScore = async (index: number, e: any, record: any) => {
        const res = score.reduce((total: number, num: number) => total + num)
        setTotal(res)
    }

    // 接口保存
    const save = async () => {
        const res = score.reduce((total: number, num: number) => total + num)
        setTotal(res)
        const code = await saveExamProjectAns({
            examRecordCode,
            questionCode: tableData[0]?.questionCode,
            items: tableData?.map((item: any, index: number) => {
                return {
                    exampleId: item.exampleId,
                    score: score[index]
                }
            })
        }).catch(err => {
            console.log(err)
        })
        onClose?.()
    }


    return (
        <Drawer
            rootClassName={'scoredrawermarkwork'}
            placement={'bottom'}
            closable={false}
            onClose={onClose}
            open={open}
        >
            <p className='scoredrawermarkwork-title'>{title}</p>
            <div className='scoredrawermarkwork-tab' ref={dom}>
                <Table
                    columns={columns}
                    rowClassName={''}
                    dataSource={tableData}
                    pagination={false}
                    rowKey={'index'}
                    scroll={{ y: length }}
                />
            </div>
            <div className='scoredrawermarkwork-footer'>
                <Button htmlType='button' onClick={onClose}>取消</Button>
                <Button htmlType='button' type='primary' onClick={save}>保存（总和：{total})</Button>
            </div>
        </Drawer>
    )
}

export default ScoreDrawerMark