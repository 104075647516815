import { Button, message, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import './index.less'
import ChooseModal from './ChooseModal'
import SuccessModal from './SuccessModal'
import Loading from '../../../components/Loading'
import { getQueryStringValue } from '../../../utils/index'
import { getJudgeTaskResult, getQuestionExampleList, updateExampleScore } from '../../../api/modules/community'

type ProjectMarkProps = {
  podAppId?: string //环境启动的id
  [other: string]: any
}

const ProjectMark: React.FC<ProjectMarkProps> = (props) => {

  const { podAppId } = props

  // 考试记录编码
  const examRecordCode = getQueryStringValue('examRecordCode')

  // 题目编码
  const questionCode = getQueryStringValue('questionCode')

  // 是否显示判分服务
  const [data, setData] = useState<any>([])

  // 显示判分弹框
  const [showChooseModal, setShowChooseModal] = useState(false)

  // 加载框
  const [loading, setLoading] = useState(false)

  // 判分结果
  const [result, setResult] = useState<any>()

  // 显示结果
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  // 点击判分服务
  const onMark = () => {
    setShowChooseModal(true)
  }

  // 轮询判分运行结果
  const fetchJudgeTaskResult = (taskId: string) => {
    let time = 0;
    const interval = setInterval(() => {
      // 超时
      if (time === 20) {
        clearInterval(interval)
        setLoading(false)
        Modal.warning({
          title: "判分失败！",
          content: '判分服务运行失败，请检查判分服务或环境是否正常运行。',
          okText: '我知道了',
          centered: true,
          width: 400
        })
        return
      }
      time++
      getJudgeTaskResult({ taskId }).then(res => {
        const _result = res?.result
        if (_result && _result?.length) {
          // 取第一个结果
          setResult(_result[0])
          setLoading(false)
          setShowSuccessModal(true)
          clearInterval(interval)
        }
      })
    }, 5000)
  }

  // 确认并保存
  const onSave = () => {
    updateExampleScore({
      examRecordCode,
      items: [{
        exampleId: result?.exampleId,
        score: result?.score
      }],
      questionCode
    }).then(() => {
      setShowSuccessModal(false)
      message.success('保存成功！')
    }).catch(res => {
      message.error(res?.message || '请求出错')
    })
  }

  // 获取客观判分点
  const fetchData = () => {
    getQuestionExampleList({
      examRecordCode,
      questionCode,
      type: 'INTERFACE'
    }).then(res => {
      setData(res)
    }).catch(() => { })
  }

  // 加载数据
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {
        data?.length ? (
          <div className='project-mark'>
            <Button ghost onClick={onMark}>判分服务</Button>
          </div>
        ) : <></>
      }
      {
        showChooseModal && 
        <ChooseModal examRecordCode={examRecordCode} questionCode={questionCode} podAppId={podAppId} data={data} open={showChooseModal} onCancel={() => setShowChooseModal(false)} onOk={(value: any) => {
          setShowChooseModal(false)
          setLoading(true)
          fetchJudgeTaskResult(value?.taskId)
        }} />
      }
      <SuccessModal open={showSuccessModal} result={result} onCancel={() => setShowSuccessModal(false)} onOk={onSave} />
      {
        loading ? <Loading data="判分服务运行中，服务较大，请稍等片刻~"></Loading> : <></>
      }
    </>
  )
}

export default ProjectMark;