import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import Ques from './Ques'
import SvgIcon from '../SvgIcon'

type QuesAnsProps = {
    onClose: () => void   // 关闭显示  
}

const QuesAns: React.FC<QuesAnsProps> = (props) => {

    const { onClose } = props

    // 当前选中   1 问答  2 笔记
    const [current, setCurrent] = useState<1 | 2>(1)


    return (
        <div className='quesans' >
            <div className='quesans-main'>
                {
                    current === 1 ? <Ques current={current} onClose={onClose}/> : <></>
                }
            </div>
        </div>
    )
}

export default QuesAns