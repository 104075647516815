import React, { useEffect, useRef, useState } from 'react'
import './index.less'

type ContentProps = {
    content: string         // 内容
}

const Content: React.FC<ContentProps> = (props) => {

    const { content } = props

    const dom = useRef<any>(null)

    const [open, setOpen] = useState(false)
    const [hover, setHover] = useState(false)

    useEffect(() => {
        const el = dom.current
        if (el && el?.scrollWidth > el?.clientWidth) {
            setHover(true)
        } else {
            setHover(false)
        }
    }, [])

    return (
        <div className='content-layout'>
            <div className={open ? 'content-layout-con' : 'content-layout-con content-layout-hidden'} ref={dom} >
                {content}
            </div>
            {
                hover && <svg style={{ transform: open ? 'rotate(0deg)' : 'rotate(180deg)' }} width="10" height="10" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                    setOpen(!open)
                }}>
                    <path d="M2.35501 6.64137L7.02168 12.8634C7.08378 12.9462 7.1643 13.0134 7.25687 13.0597C7.34944 13.1059 7.45152 13.13 7.55501 13.13C7.65851 13.13 7.76059 13.1059 7.85316 13.0597C7.94573 13.0134 8.02625 12.9462 8.08835 12.8634L12.755 6.64137C12.8293 6.54233 12.8745 6.42455 12.8857 6.30124C12.8968 6.17794 12.8733 6.05397 12.818 5.94323C12.7626 5.83249 12.6775 5.73936 12.5722 5.67427C12.4669 5.60918 12.3455 5.57471 12.2217 5.57471H2.88835C2.76454 5.57471 2.64318 5.60918 2.53786 5.67427C2.43254 5.73936 2.34743 5.83249 2.29206 5.94323C2.23669 6.05397 2.21326 6.17794 2.22437 6.30124C2.23549 6.42455 2.28073 6.54233 2.35501 6.64137Z" fill='#1677ff' />
                </svg>
            }
        </div>
    )
}

export default Content