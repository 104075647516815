import http from "../index";

// 创建文件
export async function touch(params: any) {
    return http.post(
        `/fs/touch?key=${params?.key}&repo=${params?.repo}&_private=${params?.private ?? ''}&path=${encodeURIComponent(params?.path ?? '')}&workdir=${params?.workdir ?? ''}`,
        params?.data
    );
}

// 创建目录
export async function mkdir(params: any) {
    return http.post(
        `/fs/mkdir?key=${params?.key}&repo=${params?.repo}&_private=${params?.private ?? ''}&path=${encodeURIComponent(params?.path ?? '')}&workdir=${params?.workdir ?? ''}`,
        params?.data
    );
}

// 重命名
export async function rename(params: any) {
    return http.post(
        `/fs/rename?key=${params?.key}&repo=${params?.repo}&_private=${params?.private ?? ''}&path=${encodeURIComponent(params?.path ?? '')}&workdir=${params?.workdir ?? ''}`,
        params?.data
    );
}

// 删除
export async function rm(params: any) {
    return http.post(
        `/fs/rm?key=${params?.key}&repo=${params?.repo}&_private=${params?.private ?? ''}&path=${encodeURIComponent(params?.path ?? '')}&workdir=${params?.workdir ?? ''}`,
        params?.data
    );
}

// 切片上传
export async function stream(params: any) {
    return http.post(
        `/fs/stream?key=${params?.key}&repo=${params?.repo}&path=${encodeURIComponent(params?.path ?? '')}&workdir=${params?.workdir ?? ''}&file=${encodeURIComponent(params?.file ?? '')}`,
        params?.data
    );
}

// 上传
export async function upload(params: any) {
    return http.post(
        `/fs/upload?key=${params?.key ?? ''}&repo=${params?.repo}&_private=${params?.private ?? ''}&path=${encodeURIComponent(params?.path ?? '')}&workdir=${params?.workdir ?? ''}&file=${encodeURIComponent(params?.file ?? '')}`,
        params?.data, {
        headers: {
            "Content-Type": 'multipart/form-data'
        }
    }
    );
}

// 读取文件
export async function read(params: any) {
    return http.get(`/fs/read`, {
        repo: params?.repo,
        path: encodeURIComponent(params?.path ?? ''),
        file: encodeURIComponent(params?.file ?? ''),
        sign: params?.sign,
        workdir: params?.workdir
    });
}

// 写入文件
export async function write(params: any) {
    return http.post(`/fs/write?repo=${params.repo}&path=${encodeURIComponent(params?.path ?? '')}&file=${encodeURIComponent(params?.file ?? '')}&sign=${params.sign}&workdir=${params?.workdir ?? ''}`, {
        data: params.data
    });
}

// 克隆文件
export async function gitClone(params: any) {
    return http.post(`/fs/clone?repo=${params.repo}&_private=${params?.private ?? ''}&path=${encodeURIComponent(params?.path ?? '')}&workdir=${params?.workdir ?? ''}`, {
        path: params.gitPath
    });
}

// 运行配置
export async function searchFs(params: any) {
    return http.get(`/fs/search?extendName=${params.extendName}&repo=${encodeURIComponent(params.repo)}&path=${encodeURIComponent(params?.path ?? '')}&workdir=${params?.workdir ?? ''}`);
}

// 刷新 资源管理
export async function refreshResource(data: any) {
    return http.get(`/fs/refresh`, data);
}

// 资源读取文件夹下目录
export async function getFssFolder(params: any) {
    return http.get(`/fss/folder/${encodeURIComponent(params?.repo)}/${encodeURIComponent(params?.path)}/workdir/${encodeURIComponent(params?.file)}`);
}

// 资源下载功能：读取文件内容（blob）
export async function getFssResource(params: any) {
    return http.get(`/fss/resource/${encodeURIComponent(params?.repo)}/${encodeURIComponent(params?.path)}/workdir/${encodeURIComponent(params?.file)}`, {}, { responseType: 'blob' });
}