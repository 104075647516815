import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import Editor, { EditorProps, loader } from '@monaco-editor/react';
import './index.less'
import config from '@/config';

enum LanguageEnums {
  "abap" = "abap",
  "cls" = "apex",
  "azcli" = "azcli",
  "bat" = "bat",
  "cmd" = "bat",
  "c" = "c",
  "h" = "c",
  "mligo" = "cameligo",
  "clj" = "clojure",
  "cljs" = "clojure",
  "cljc" = "clojure",
  "edn" = "clojure",
  "coffee" = "coffeescript",
  "cpp" = "cpp",
  "cc" = "cpp",
  "cxx" = "cpp",
  "hpp" = "cpp",
  "hh" = "cpp",
  "hxx" = "cpp",
  "cs" = "csharp",
  "csx" = "csharp",
  "cake" = "csharp",
  "css" = "css",
  "dart" = "dart",
  "dockerfile" = "dockerfile",
  "ecl" = "ecl",
  "fs" = "fsharp",
  "fsi" = "fsharp",
  "ml" = "fsharp",
  "mli" = "fsharp",
  "fsx" = "fsharp",
  "fsscript" = "fsharp",
  "go" = "go",
  "graphql" = "graphql",
  "gql" = "graphql",
  "handlebars" = "handlebars",
  "hbs" = "handlebars",
  "tf" = "hcl",
  "tfvars" = "hcl",
  "hcl" = "hcl",
  "html" = "html",
  "htm" = "html",
  "shtml" = "html",
  "xhtml" = "html",
  "mdoc" = "html",
  "jsp" = "html",
  "asp" = "html",
  "aspx" = "html",
  "jshtm" = "html",
  "ini" = "ini",
  "properties" = "ini",
  "gitconfig" = "ini",
  "java" = "java",
  "jav" = "java",
  "js" = "javascript",
  "es6" = "javascript",
  "jsx" = "javascript",
  "mjs" = "javascript",
  "jl" = "julia",
  "kt" = "kotlin",
  "less" = "less",
  "lex" = "lexon",
  "lua" = "lua",
  "m3" = "m3",
  "i3" = "m3",
  "mg" = "m3",
  "ig" = "m3",
  "md" = "markdown",
  "markdown" = "markdown",
  "mdown" = "markdown",
  "mkdn" = "markdown",
  "mkd" = "markdown",
  "mdwn" = "markdown",
  "mdtxt" = "markdown",
  "mdtext" = "markdown",
  "s" = "mips",
  "dax" = "msdax",
  "msdax" = "msdax",
  "m" = "objective-c",
  "pas" = "pascal",
  "p" = "pascal",
  "ligo" = "pascaligo",
  "pl" = "perl",
  "php" = "php",
  "php4" = "php",
  "php5" = "php",
  "phtml" = "php",
  "ctp" = "php",
  "dats" = "postiats",
  "sats" = "postiats",
  "hats" = "postiats",
  "pq" = "powerquery",
  "pqm" = "powerquery",
  "ps1" = "powershell",
  "psm1" = "powershell",
  "psd1" = "powershell",
  "jade" = "pug",
  "pug" = "pug",
  "py" = "python",
  "rpy" = "python",
  "pyw" = "python",
  "cpy" = "python",
  "gpy" = "python",
  "gypi" = "python",
  "r" = "r",
  "rhistory" = "r",
  "rmd" = "r",
  "rprofile" = "r",
  "rt" = "r",
  "cshtml" = "razor",
  "redis" = "redis",
  "rst" = "restructuredtext",
  "rb" = "ruby",
  "rbx" = "ruby",
  "rjs" = "ruby",
  "gemspec" = "ruby",
  "pp" = "ruby",
  "rs" = "rust",
  "rlib" = "rust",
  "sb" = "sb",
  "scala" = "scala",
  "sc" = "scala",
  "sbt" = "scala",
  "scm" = "scheme",
  "ss" = "scheme",
  "sch" = "scheme",
  "rkt" = "scheme",
  "scss" = "scss",
  "sh" = "shell",
  "bash" = "shell",
  "aes" = "sophia",
  "sol" = "sol",
  "sql" = "sql",
  "st" = "st",
  "iecst" = "st",
  "iecplc" = "st",
  "lc3lib" = "st",
  "swift" = "swift",
  "sv" = "systemverilog",
  "svh" = "systemverilog",
  "tcl" = "tcl",
  "twig" = "twig",
  "ts" = "typescript",
  "tsx" = "typescript",
  "vb" = "vb",
  "v" = "verilog",
  "vh" = "verilog",
  "xml" = "xml",
  "dtd" = "xml",
  "ascx" = "xml",
  "csproj" = "xml",
  "config" = "xml",
  "wxi" = "xml",
  "wxl" = "xml",
  "wxs" = "xml",
  "xaml" = "xml",
  "svg" = "xml",
  "svgz" = "xml",
  "oft" = "xml",
  "xsl" = "xml",
  "yaml" = "yaml",
  "yml" = "yaml",
  'json' = 'json',
  'python2' = 'python',
  'python3' = 'python'
}

interface Props extends EditorProps {
  readOnly?: boolean //只读模式
  onChange?: (value: any) => void //editor内容改变
  [other: string]: any
}

// 中文配置
loader.config({
  "paths": { vs: config.monacoCDN },
  "vs/nls": { availableLanguages: { "*": "zh-cn" } }
})

let MiniCodeEditor: React.FC<Props> = (props, ref) => {

  const { readOnly = false, value = '', language, onChange } = props

  //当前代码
  const [codeValue, setCodeValue] = useState(value)

  // 加载中
  const [showLoading, setShowLoading] = useState(true)

  const editorRef = useRef<any>(null);
  const monaco = useRef<any>(null);


  // 重置新的内容
  useEffect(() => {
    setCodeValue(value)
  }, [value])

  useImperativeHandle(ref, () => ({
    getCode: () => {
      return codeValue
    },
    setCode: (value: any) => {
      setCodeValue(value)
    },
    // 滚动到顶部
    scrollToTop: () => {
      editorRef?.current?.setScrollPosition({ scrollTop: 0 })
    }
  }))
 
  return (
    <>
      <Editor
        theme="vs-dark"
        {...props}
        value={codeValue}
        language={language ? LanguageEnums[language as keyof typeof LanguageEnums] || language : 'plaintext'}
        onChange={(v: any) => {
          onChange?.(v)
          setCodeValue(v)
        }}
        onMount={(editor: any, _monaco) => {
          editorRef.current = editor;
          monaco.current = _monaco
          // 格式化
          editor.getAction('editor.action.formatDocument')?.run()
          setShowLoading(false)
        }}
        options={{
          folding: true,
          lineDecorationsWidth: 1,
          wordWrap: 'on',
          minimap: {
            enabled: false
          },
          readOnly: readOnly
        }}
      />
      {
        showLoading && (
          <div className='min-codeEditor-loading'>
            <div className='min-codeEditor-loading-in'>
              <div className='min-codeEditor-loading-in-line'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p>加载中…</p>
            </div>
          </div>
        )
      }
    </>
  );
};

MiniCodeEditor = forwardRef(MiniCodeEditor as any)

export default MiniCodeEditor