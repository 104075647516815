import React, { useEffect, useState } from 'react';
import './index.less'

const Loading: React.FC<any> = (props) => {
  const { data, btnShow } = props

  const [loadingText, setLoadingText] = useState(data)

  //导入超过三秒后文字变化
  useEffect(() => {
    if (btnShow) {
      setTimeout(() => {
        setLoadingText('文档过大，导入较慢，请再稍等片刻~')
      }, 3000);
    }
  }, [btnShow])

  // 监听文案变化
  useEffect(() => {
    setLoadingText(data)
  }, [data])

  return (
    <div className={'loading'}>
      <div className='loading-line'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p>{loadingText}</p>
      {btnShow && <div className='btn' onClick={() => {
        window?.parent?.postMessage({ type: 'shutopen' }, '*')
      }}>
        后台导入
      </div>}
    </div>
  );
};

export default Loading;