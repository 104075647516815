import React, { useEffect, useState, useRef } from 'react'
import { Tooltip } from 'antd'
import './index.less'
import Loading from '../../Loading'

// 前端的环境状态
enum EnvStatus {
  Startuping, //启动中
  Running, //运行中
  Closing, //关闭中
  Resetting //重置中
}

type EnvCloudProps = {
  mark?: boolean //判分服务
  containers?: any //数据
  status?: any //环境状态
  overtime?: boolean //启动是否超过5s
}

const EnvCloud: React.FC<EnvCloudProps> = (props) => {

  const { mark = false, containers = [], status, overtime } = props

  const envRef = useRef<any>()

  // 容器数据
  const [data, setData] = useState<any>([])

  // 提取数字
  const matchNum = (str: string) => {
    const regex = /\d+/g;
    const matches = str?.match(regex);
    return matches
  }

  // 处理cpu和内存展示数据
  useEffect(() => {
    if (containers?.length) {
      if (mark) {
        containers?.map((v: any) => {
          if (v?.uses) {
            v.cpuP = `${(v?.uses?.cpuNumber / v?.limits?.cpuNumber).toFixed(1)}%`
            v.memoryP = `${(v?.uses?.memoryNumber / v?.limits?.memoryNumber).toFixed(1)}%`
          }
          v.memory = v?.uses?.memory
          return v
        })
      } else {
        containers?.map((v: any) => {
          v.cpuP = v?.cpu ? `${(v?.cpu?.replace('m', '') / 10).toFixed(1)}%` : '0%'
          if (v?.memory && v?.limits?.memory) {
            // 获取内存使用数值
            const memory = matchNum(v?.memory)
            let _memory = memory?.length ? Number(memory[0]) : 0
            const unit = v?.memory?.replace(_memory, '')?.toLowerCase()
            if (unit?.indexOf('g') > -1) {
              _memory = _memory * 1024
            }
            // 获取内存总数值
            const limitMemory = matchNum(v?.limits?.memory)
            let _limitMemory = limitMemory?.length ? Number(limitMemory[0]) : 0
            const _unit = v?.limits?.memory?.replace(_limitMemory, '')?.toLowerCase()
            if (_unit?.indexOf('g') > -1) {
              _limitMemory = _limitMemory * 1024
            }
            v.memoryP = `${(_memory / _limitMemory * 100).toFixed(1)}%`
          } else {
            v.memoryP = '0%'
          }
          return v
        })
      }
      setData([...containers])
    }
  }, [containers, mark])

  // 判断是否超出浏览器可视范围
  useEffect(() => {
    const width = window?.innerWidth
    const rect = envRef?.current?.getBoundingClientRect()
    if (rect?.right > width) {
      envRef.current.style.left = `calc(50% - ${rect?.right - width + 10}px)`
    }
  }, [])

  return (
    <div className='cloud-env-main' ref={envRef}>
      {
        status === EnvStatus.Running ? (
          <div className='cloud-env'>
            <div className='cloud-env-header'>
              <div className='cloud-env-header-name'>云端环境</div>
            </div>
            <div className='cloud-env-ul'>
              {
                data?.map((v: any) => (
                  <div className='cloud-env-ul-li' key={v?.container}>
                    <Tooltip overlayClassName='cloud-env-tooltip' placement="bottomLeft" arrow={false} title={v?.container}>
                      <div className='cloud-env-ul-li-name'>{v?.container}</div>
                    </Tooltip>
                    <div className='cloud-env-ul-li-info'>
                      <div>
                        <div>
                          <span>CPU</span>
                          <span>{v?.limits?.cpu}核</span>
                        </div>
                        <div>
                          <span><span style={{ width: v?.cpuP }}></span></span>
                          <span>{v?.cpuP}</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span>内存</span>
                          <span>{v?.memory || '--'}/{v?.limits?.memory}</span>
                        </div>
                        <div>
                          <span><span style={{ width: v?.memoryP }}></span></span>
                          <span>{v?.memoryP}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        ) : (
          <div className='cloud-env-loading'>
            <Loading data={overtime ? "当前人数较多，正在努力调度资源中，请稍等2-3分钟～" : "环境启动中…"} />
          </div>
        )
      }
    </div>
  )
}

export default EnvCloud;