import { Checkbox, Form, FormInstance, Input, Select, TimePicker } from 'antd'
import React, { useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import SvgIcon from '@/components/SvgIcon'
import MiniRichEditor from '../MiniRichEditor'
import { handleTimeSecond } from '@/utils/time'
import KnowledgeFormItem from '../KnowledgeFormItem'

type ContentProps = {
    form?: FormInstance
    formData: any
    time: boolean
    initTime: string
    disableTime: boolean
    veriryTime?: (speed: number, id?: string) => any
    chapterId: string             // 小节id
    courseCode: string            // 课程code
    languageList?: any
}

const Content: React.FC<ContentProps> = (props) => {

    const { form, time, initTime, veriryTime, formData, disableTime, chapterId, courseCode, languageList } = props

    const RichDom = useRef<any>(null)

    useEffect(() => {
        form?.setFieldsValue({
            codeLanguage: formData?.codeLanguage,
            topic: formData?.topic ?? '',
            title: formData?.title || '',
            buttonName: formData?.buttonName || '去答题',
            time: formData?.time ? dayjs(formData.time, 'HH:mm:ss') : dayjs(initTime, 'HH:mm:ss'),
            required: !!formData?.required,
            knowledgeIds: formData?.knowledgeIds
        })
    }, [formData])

    return (
        <div className='content0'>
            <Form.Item
                label='编程语言'
                name='codeLanguage'
                rules={[
                    { required: true, message: '请选择编程语言', validateTrigger: "onSubmit", whitespace: true },
                ]}
                validateFirst
            >
                <Select
                    placeholder="请选择编程语言"
                    fieldNames={{ label: 'languageAlias', value: 'languageName' }}
                    options={languageList}
                    // options={[
                    //     {
                    //         label: 'java',
                    //         value: 'java'
                    //     },
                    //     {
                    //         label: 'python',
                    //         value: 'python'
                    //     },
                    //     {
                    //         label: 'c',
                    //         value: 'c'
                    //     },
                    // ]}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                />
            </Form.Item>

            <Form.Item
                label='标题'
                name='topic'
                rules={[
                    { required: true, message: '请输入标题', validateTrigger: "onSubmit", whitespace: true },
                    { min: 3, message: '请至少输入3个字', validateTrigger: 'onSubmit', whitespace: true }
                ]}
                validateFirst
            >
                <Input
                    showCount
                    maxLength={50}
                    placeholder='请输入标题'
                    allowClear
                />
            </Form.Item>

            <Form.Item
                label='题干'
                name='title'
                required
                rules={[
                    // { required: true, message: '请输入题干内容', validateTrigger: "onSubmit", whitespace: true },
                    {
                        required: true, validator(rule, value) {
                            const res = RichDom?.current?.validateField()
                            if (!res?.success) {
                                return Promise.reject('请输入题干内容')
                            }
                            return Promise.resolve()
                        }, validateTrigger: "onSubmit"
                    }
                ]}
                validateFirst
            >
                <div>
                    <MiniRichEditor
                        ref={RichDom}
                        height={220}
                        value={formData?.title ?? ''}
                        placeholder='请输入题干'
                        onChange={(value: string) => {
                            form?.setFieldValue('title', value)
                        }}
                    />
                </div>
            </Form.Item>

            <Form.Item
                label='按钮名称'
                name='buttonName'
                rules={[
                    { required: true, message: '请输入按钮名称', validateTrigger: "onSubmit", whitespace: true },
                    // { min: 3, message: '请至少输入3个字', validateTrigger: 'onSubmit', whitespace: true }
                ]}
                validateFirst
            >
                <Input
                    showCount
                    maxLength={6}
                    placeholder='请输入按钮名称'
                    allowClear
                    style={{ width: '180px' }}
                />
            </Form.Item>

            {
                time && <Form.Item
                    label="触发时间"
                    name='time'
                    rules={[
                        { required: true, message: '请输入触发时间', validateTrigger: "onSubmit", type: 'object' },
                        {
                            validator(_, _value) {
                                if (veriryTime) {
                                    const res = veriryTime(handleTimeSecond(_value), formData?.id)
                                    if (res.success) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(res.message ?? '')
                                    }
                                }
                                return Promise.resolve()
                            },
                            validateTrigger: "onSubmit"
                        }
                    ]}
                    validateFirst
                >
                    <TimePicker
                        disabled={disableTime}
                        defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                        placeholder='请输入触发时间'
                        showNow={false}
                        style={{ width: '180px' }}
                    />
                </Form.Item>
            }

            <KnowledgeFormItem form={form} chapterId={chapterId} courseCode={courseCode}/>

            <Form.Item label='是否必答' >
                <Form.Item
                    name='required'
                    valuePropName="checked"
                    validateFirst
                    noStyle
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <div className='item-tip'>
                    <SvgIcon iconClass={'promptIcon'} fontSize='14px' />
                    <span>勾选必答后，学习者必须执行该编码测验才可以进行下一步操作。</span>
                </div>
            </Form.Item>

        </div>
    )
}

export default Content