
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: '', //课程code
  code: '', //机构code
  key: '', //用户code
  // sign: '', //调用凭证
  modal: 0, //形态（0：学习，1：创作）
  section: {
    path: '',
    type: '',
    image: '',
    video: '' //是否上传过视频
  }, //当前小节路径
  RTSave: false, //是否实时保存
  resourceFile: '', //资源管理器最后选中的文件
  runFile: '' //最后运行的文件
};

export const courseSlice = createSlice({
  name: "courseSpace",
  initialState,
  reducers: {
    // reducer函数 state当前组件的数据 ，第二个参数为{payload:{},type:"""}
    updateCourse(state, { payload }) {
      state.id = payload.id
      state.code = payload.code
      state.key = payload.key
      // state.sign = payload.sign
      state.modal = payload.modal
    },

    // 更新节
    updateSection(state, { payload }) {
      if (payload.section?.path !== undefined) {
        state.section.path = payload.section.path
      }
      if (payload.section?.type !== undefined) {
        state.section.type = payload.section.type
      }
      if (payload.section?.image !== undefined) {
        state.section.image = payload.section?.image
      }
      if (payload.section?.video !== undefined) {
        state.section.video = payload.section?.video
      }
    },

    // 是否实时保存
    updateRTSave(state, { payload }) {
      state.RTSave = payload.RTSave
    },

    // 资源管理选中文件
    updateResourceFile(state, { payload }) {
      state.resourceFile = payload.resourceFile
    },

    // 最后运行的文件
    updateRunFile(state, { payload }) {
      state.runFile = payload.runFile
    }
  },
});
export const { updateCourse, updateSection, updateRTSave, updateResourceFile, updateRunFile } = courseSlice.actions;

export default courseSlice.reducer;
