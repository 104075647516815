import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import './index.less'
import { Checkbox, Form, Input, Spin, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { getPortImg } from '../../../api/modules/community';

interface EnvFormProps {
    data?: any                                // 环境初始信息
    setContainerName?: (name: string) => void // 修改容器名称
    verifyImage?: (id: string, image: string) => any        // 验证镜像
    edited?: boolean                          // 是否可以编辑
    [key: string]: any
}

let EnvForm: React.FC<EnvFormProps> = (props, ref) => {

    const { data = {}, setContainerName, verifyImage, edited = true } = props

    const [form] = Form.useForm()

    const [portImg, setPortImg] = useState<any>([])    // 选择的图片列表

    // 选择图片
    const choiceImg = (index: any, image: string) => {
        form?.setFieldValue(['ports', index, 'image'], image)
        changePortsList('update', index, { image })
    }

    // 获取image的图片
    useEffect(() => {
        getPortImg().then(res => {
            const img = res?.course ?? []
            setPortImg(img)
        }).catch(() => { })
    }, [])

    // 是否展开
    const [show, setShow] = useState<any>({
        ports: false,
        command: false,
        env: false,
        volumeMounts: false
    })
    // 所有添加的端口 管理   按顺序修改  包含端口中的图片
    const [portsList, setPortsList] = useState<any>([])

    // 展开收起
    const changeShow = (key: 'ports' | 'command' | 'env' | 'volumeMounts') => {
        const res = { ...show }
        res[key] = !res[key]
        setShow(res)
    }

    // 修改当前添加的端口
    const changePortsList = (type: 'add' | 'move' | 'update' = 'add', index: number, data?: object) => {
        let res: any
        if (type === 'add') {
            res = [...portsList]
            res.push({ ...data })
        } else if (type === 'move') {
            res = portsList?.slice(0, index)
            res = res.concat(portsList?.slice(index + 1))
        } else {
            res = [...portsList]
            res[index] = {
                ...res[index],
                ...data
            }
        }
        setPortsList(res)
    }

    // 镜像校验状态
    const [code, setCode] = useState(0)

    // 暴露表单句柄
    useImperativeHandle(ref, () => ({
        // 表单的校验是否通过
        validateFields: form?.validateFields,
        // 获取表单数据
        getData: () => {
            const { name = '', image = '', cpu = '', memory = '', ports = [], start = '', after = '', stop = '', env = [], volumeMounts = [] } = form?.getFieldsValue()
            return {
                id: data?.id,
                name: name?.trim(),
                image: image?.trim(),
                resources: {
                    limits: {
                        cpu: cpu ? parseFloat(cpu) : '',
                        memory: memory?.trim()
                    }
                },
                command: start?.trim(),
                lifecycle: {
                    preStop: {
                        exec: {
                            command: after?.trim()
                        }
                    },
                    postStart: {
                        exec: {
                            command: stop?.trim()
                        }
                    }
                },
                env,
                ports,
                volumeMounts,
                portsOpen: show?.ports,
                commandOpen: show?.command,
                envOpen: show?.env,
                volumeMountsOpen: show?.volumeMounts,
                imageCode: code
            }
        },
        // 镜像校验是否通过
        validateCode: () => {
            return {
                success: code === 3,
                code
            }
        },
        // 整体表单的校验是否通过
        validate: async (): Promise<boolean> => {
            const res = await form?.validateFields().catch(() => {})
            if (!res) return false
            if (code === 3) {
                return true
            } else {
                return false
            }
        }
    }));

    // 验证镜像
    const verifiedImage = async () => {
        const { image = '' } = await form?.validateFields(['image'])
        setCode(1)
        const res = await verifyImage?.(data?.id, image.trim())
        switch (res?.code) {
            case 1:  
                setTimeout(() => {
                    verifiedImage()
                }, 3000);
                break;
            case 2:
                form.setFields([{
                    name: 'image',
                    errors: [res?.message]
                }])
                break
            case 3:
                break;
        }
        setCode(res?.code ?? 0)
    }


    useEffect(() => {
        const {
            name = '',
            image = '',
            resources = {},
            ports = [],
            command = '',
            lifecycle = {},
            env = [],
            volumeMounts = [],
            portsOpen = false,
            commandOpen = false,
            envOpen = false,
            volumeMountsOpen = false,
            imageCode = 0
        } = data
        // 设置表单
        const newPorts = ports?.map((item: any) => ({ ...item, containerPort: item?.containerPort?.toString() }))
        setPortsList(newPorts)
        form?.setFieldsValue({
            name,
            image,
            cpu: resources?.limits?.cpu?.toString() ?? '',
            memory: resources?.limits?.memory?.toString() ?? '',
            ports: newPorts,
            start: command,
            after: lifecycle?.preStop?.exec?.command ?? '',
            stop: lifecycle?.postStart?.exec?.command ?? '',
            env,
            volumeMounts
        })
        // 设置展开收起
        setShow({
            ports: portsOpen,
            command: commandOpen,
            env: envOpen,
            volumeMounts: volumeMountsOpen
        })
        setCode(imageCode)
    }, [data])


    return (
        <div className='envform'>
            <Form
                name="Env-Form"
                colon={false}
                validateTrigger="onBlur"
                autoComplete="off"
                form={form}
                disabled={!edited}
            >
                <Form.Item
                    label="容器名称"
                    name='name'
                    rules={[
                        { required: true, message: '请输入容器名称', validateTrigger: "onSubmit", whitespace: true },
                        {
                            validator: (_, value) => {
                                const re = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/g
                                // const re = /.*[\u4e00-\u9fa5]+.*$/g
                                if (re.test(value?.trim())) {
                                    return Promise.resolve()
                                }
                                return Promise.reject('请输入60字以内的英文字符')
                            }
                        }
                    ]}
                    validateFirst
                >
                    <Input
                        placeholder="请输入容器名称"
                        maxLength={60}
                        allowClear
                        onChange={(dom) => {
                            setContainerName?.(dom.target.value)
                        }}
                    />
                </Form.Item>

                <div className='envform-image'>
                    <Form.Item
                        label="镜像名称"
                        name='image'
                        rules={[
                            { required: true, message: '请输入镜像名称', validateTrigger: "onSubmit", whitespace: true },
                        ]}
                        validateFirst
                    >
                        <Input
                            placeholder="请输入镜像名称"
                            maxLength={200}
                            allowClear
                            disabled={edited ? (code === 1 ? true : false) : true}
                            onChange={(dom) => {
                                setCode(0)
                            }}
                        />
                    </Form.Item>
                    <div className='envform-image-tip'>
                        {
                            code === 0 ? <span className='span' style={{pointerEvents: edited ? 'all' : 'none'}} onClick={verifiedImage}>验证镜像</span> : 
                            code === 1 ? <><span className='span nospan'>验证镜像</span><Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} /></> :
                            code === 2 ? <img className='img' src={require('../../../assets/images/rederror.png')} alt="" /> :
                            code === 3 ? <img className='img' src={require('../../../assets/images/greensuccess.png')} alt="" /> : <></>
                        }
                    </div>
                </div>

                <Form.Item style={{ marginBottom: '0px' }} label={<span style={{ marginRight: '20px', paddingLeft: '10px' }}>资源限制</span>}>
                    <Form.Item
                        label="CPU"
                        name='cpu'
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (value) {
                                        const re = /^[0-9]+(\.?[0-9])?$/g
                                        if (re.test(value)) {
                                            const num = parseFloat(value)
                                            if (num >= 0.1 && num <= 4) {
                                                return Promise.resolve() 
                                            }
                                            return Promise.reject('请输入0.1 - 4的数值')
                                        }
                                        return Promise.reject('请输入0.1 - 4的数值')
                                    } else {
                                        return Promise.resolve()
                                    }
                                }
                            }
                        ]}
                        style={{ display: 'inline-block', marginRight: '30px' }}
                        validateFirst
                    >
                        <Input
                            placeholder="示例: 1"
                            maxLength={200}
                            allowClear
                            suffix={
                                <span style={{ color: '#999999' }}>Core</span>
                            }
                            style={{width: '150px'}}
                        />
                    </Form.Item>

                    <Form.Item
                        label="内存"
                        name='memory'
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (value) {
                                        const num = Number(value)
                                        if (num >= 100 && num <= 8192) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject('请输入100 - 8192的数值')
                                    } else {
                                        return Promise.resolve()
                                    }
                                }
                            }
                        ]}
                        style={{ display: 'inline-block' }}
                        validateFirst
                    >
                        <Input
                            placeholder="示例: 1024"
                            maxLength={200}
                            allowClear
                            suffix={
                                <span style={{ color: '#999999' }}>MiB</span>
                            }
                            style={{width: '150px'}}
                        />
                    </Form.Item>
                </Form.Item>

                <div className='envform-item'>
                    <div className='envform-item-title' onClick={() => { changeShow('ports') }}>
                        <svg style={{ transform: show?.ports ? 'rotate(0deg)' : 'rotate(-90deg)' }} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.35501 6.64137L7.02168 12.8634C7.08378 12.9462 7.1643 13.0134 7.25687 13.0597C7.34944 13.1059 7.45152 13.13 7.55501 13.13C7.65851 13.13 7.76059 13.1059 7.85316 13.0597C7.94573 13.0134 8.02625 12.9462 8.08835 12.8634L12.755 6.64137C12.8293 6.54233 12.8745 6.42455 12.8857 6.30124C12.8968 6.17794 12.8733 6.05397 12.818 5.94323C12.7626 5.83249 12.6775 5.73936 12.5722 5.67427C12.4669 5.60918 12.3455 5.57471 12.2217 5.57471H2.88835C2.76454 5.57471 2.64318 5.60918 2.53786 5.67427C2.43254 5.73936 2.34743 5.83249 2.29206 5.94323C2.23669 6.05397 2.21326 6.17794 2.22437 6.30124C2.23549 6.42455 2.28073 6.54233 2.35501 6.64137Z" />
                        </svg>
                        <span>端口</span>
                    </div>

                    <div className='envform-item-animation' style={{ gridTemplateRows: show?.ports ? '1fr' : '0fr', width: 'calc(100% + 26px)' }}>
                        <div className='envform-item-animation-stamp'>
                            <div className='envform-item-con'>
                            <Form.List name="ports" >
                                    {
                                        (fields, { add, remove }, { errors }) => {
                                            return <>
                                                {
                                                    fields.map(({ key, name, ...field }, index) => {
                                                        return <div className='envform-item-con-ports' key={key}>
                                                            <div className='envform-item-con-border'>
                                                                <div className='envform-item-con-border-line'>
                                                                    <Form.Item
                                                                        label='端口设置'
                                                                        {...field}
                                                                        rules={[
                                                                            { required: true, message: '请输入端口号', validateTrigger: "onSubmit" }, 
                                                                            {
                                                                                validator: (_, value) => {
                                                                                    if (value) {
                                                                                        // const num = Number(value)
                                                                                        const patn = /^(?:[1-9]\d{0,3}|[1-2]\d{4}|30000)$/
                                                                                        if (patn.test(value)) {
                                                                                        // if (num >= 1 && num <= 30000) {
                                                                                            let nums = form?.getFieldValue('ports')
                                                                                            nums = [...nums]
                                                                                            nums.splice(index, 1)
                                                                                            if (nums?.some((item: any) => value === item?.containerPort)) {
                                                                                                return Promise.reject(new Error('端口重复'))
                                                                                            }
                                                                                            return Promise.resolve()
                                                                                        }
                                                                                        return Promise.reject(new Error('请输入1-30000的数字'))
                                                                                    }
                                                                                    return Promise.reject()
                                                                                }
                                                                            }
                                                                        ]}
                                                                        validateFirst
                                                                        required={false}
                                                                        name={[name, 'containerPort']}
                                                                        style={{ width: '0px', flex: '1' }}
                                                                    >
                                                                        <Input placeholder="请输入端口号" maxLength={200} allowClear />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...field}
                                                                        rules={[]}
                                                                        name={[name, 'choice']}
                                                                        style={{ width: 'fit-content', marginLeft: '30px' }}
                                                                        valuePropName="checked"
                                                                    >
                                                                        <Checkbox value={portsList?.[index]?.choice} onChange={(e) => {
                                                                            const data: any = {choice: e.target.checked}
                                                                            if (!e.target.checked) {
                                                                                data.image = null
                                                                                form?.setFieldValue(['ports', index, 'name'], '')
                                                                            }
                                                                            changePortsList('update', index, data)
                                                                        }}>以web工具的形式对外提供访问</Checkbox>
                                                                    </Form.Item>
                                                                </div>

                                                                {
                                                                    portsList?.[index]?.choice && <>
                                                                        <Form.Item
                                                                            label='工具名称'
                                                                            {...field}
                                                                            rules={[
                                                                                { required: true, message: '请输入工具名称', validateTrigger: "onClick" },
                                                                                {
                                                                                    validator: (_, value) => {
                                                                                        if (!value) {
                                                                                            return Promise.resolve()
                                                                                        }
                                                                                        const re = /^[\u4E00-\u9FA5a-zA-Z0-9_-]+$/
                                                                                        if (re.test(value)) {
                                                                                            return Promise.resolve()
                                                                                        }
                                                                                        return Promise.reject('请输入1-10个字符的中英文、数字、_-')
                                                                                    },
                                                                                    validateTrigger: "onBlur"
                                                                                }
                                                                            ]}
                                                                            validateFirst
                                                                            required={false}
                                                                            name={[name, 'name']}
                                                                        >
                                                                            <Input placeholder="请输入工具名称" maxLength={10} allowClear showCount />
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            label='工具图标'
                                                                            {...field}
                                                                            rules={[
                                                                                { required: true, message: '请选择工具图标', validateTrigger: "onSubmit" },
                                                                            ]}
                                                                            required={false}
                                                                            validateFirst
                                                                            name={[name, 'image']}
                                                                        >
                                                                            <div className='envform-item-con-border-tools-list'>
                                                                                {
                                                                                    portImg?.map((item: any) => {
                                                                                        return <div data-disabled={!edited} className={portsList?.[index]?.image === item?.image ? 'envform-item-con-border-tools-list-item envform-item-con-border-tools-list-light' : 'envform-item-con-border-tools-list-item'} key={item?.pictureId} onClick={() => { choiceImg(index, item?.image) }}>
                                                                                            <img src={item?.image} alt="" />
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </Form.Item>
                                                                    </>
                                                                }

                                                            </div>

                                                            <svg data-disabled={!edited} className='svg' width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                                remove(name)
                                                            }}>
                                                                <circle cx="7.99967" cy="8.35221" r="6.16667" />
                                                                <rect x="11.7598" y="7.75195" width="1.2" height="7.51953" rx="0.6" transform="rotate(90 11.7598 7.75195)" />
                                                            </svg>
                                                        </div>
                                                    })
                                                }
                                                <div className={edited ? 'envform-item-con-add' : 'envform-item-con-add adddark'} onClick={() => {
                                                    if (!edited) return
                                                    const num = form?.getFieldValue('ports')
                                                    if (num?.length >= 10) {
                                                        message.destroy()
                                                        message.warning('端口最多新增10个')
                                                        return
                                                    }
                                                    add({ containerPort: '' })
                                                }}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="7.9987" cy="8.00065" r="6.66667" />
                                                        <path d="M13.8311 8.00146C13.8311 11.2232 11.2193 13.835 7.99756 13.835C4.77581 13.835 2.16406 11.2232 2.16406 8.00146C2.16406 4.77971 4.77581 2.16797 7.99756 2.16797C11.2193 2.16797 13.8311 4.77971 13.8311 8.00146Z" fill="white" />
                                                        <rect x="7.5" y="4.00098" width="1" height="8" rx="0.5" />
                                                        <rect x="12" y="7.50098" width="1" height="8" rx="0.5" transform="rotate(90 12 7.50098)" />
                                                    </svg>
                                                    <span>新增</span>
                                                </div>
                                            </>
                                        }
                                    }
                                </Form.List>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='envform-item'>
                    <div className='envform-item-title' onClick={() => { changeShow('command') }}>
                        <svg style={{ transform: show?.command ? 'rotate(0deg)' : 'rotate(-90deg)' }} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.35501 6.64137L7.02168 12.8634C7.08378 12.9462 7.1643 13.0134 7.25687 13.0597C7.34944 13.1059 7.45152 13.13 7.55501 13.13C7.65851 13.13 7.76059 13.1059 7.85316 13.0597C7.94573 13.0134 8.02625 12.9462 8.08835 12.8634L12.755 6.64137C12.8293 6.54233 12.8745 6.42455 12.8857 6.30124C12.8968 6.17794 12.8733 6.05397 12.818 5.94323C12.7626 5.83249 12.6775 5.73936 12.5722 5.67427C12.4669 5.60918 12.3455 5.57471 12.2217 5.57471H2.88835C2.76454 5.57471 2.64318 5.60918 2.53786 5.67427C2.43254 5.73936 2.34743 5.83249 2.29206 5.94323C2.23669 6.05397 2.21326 6.17794 2.22437 6.30124C2.23549 6.42455 2.28073 6.54233 2.35501 6.64137Z" />
                        </svg>
                        <span>命令</span>
                    </div>

                    <div className='envform-item-animation' style={{ gridTemplateRows: show?.command ? '1fr' : '0fr' }}>
                        <div className='envform-item-animation-stamp'>
                            <div className='envform-item-con'>
                                <Form.Item
                                    label={<span style={{ paddingLeft: '1em' }}>启动执行</span>}
                                    name='start'
                                    rules={[
                                        {
                                            message: '禁止全部输入空格', whitespace: true
                                        }
                                    ]}
                                    validateFirst
                                >
                                    <Input
                                        placeholder={`示例: sleep 3600 或[“sleep",“3600"]`}
                                        maxLength={200}
                                        allowClear
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="启动后执行"
                                    name='stop'
                                    rules={[
                                        {
                                            message: '禁止全部输入空格', whitespace: true
                                        }
                                    ]}
                                    validateFirst
                                >
                                    <Input
                                        placeholder={`示例: echo hello world 或[“/bin/sh"，“-c”,“echo hello world"]`}
                                        maxLength={200}
                                        allowClear
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="停止前执行"
                                    name='after'
                                    rules={[
                                        {
                                            message: '禁止全部输入空格', whitespace: true
                                        }
                                    ]}
                                    validateFirst
                                    style={{marginBottom: '0px'}}
                                >
                                    <Input
                                        placeholder={`示例: echo hello world 或[“/bin/sh"，“-c”,“echo hello world"]`}
                                        maxLength={200}
                                        allowClear
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='envform-item'>
                    <div className='envform-item-title' onClick={() => { changeShow('env') }}>
                        <svg style={{ transform: show?.env ? 'rotate(0deg)' : 'rotate(-90deg)' }} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.35501 6.64137L7.02168 12.8634C7.08378 12.9462 7.1643 13.0134 7.25687 13.0597C7.34944 13.1059 7.45152 13.13 7.55501 13.13C7.65851 13.13 7.76059 13.1059 7.85316 13.0597C7.94573 13.0134 8.02625 12.9462 8.08835 12.8634L12.755 6.64137C12.8293 6.54233 12.8745 6.42455 12.8857 6.30124C12.8968 6.17794 12.8733 6.05397 12.818 5.94323C12.7626 5.83249 12.6775 5.73936 12.5722 5.67427C12.4669 5.60918 12.3455 5.57471 12.2217 5.57471H2.88835C2.76454 5.57471 2.64318 5.60918 2.53786 5.67427C2.43254 5.73936 2.34743 5.83249 2.29206 5.94323C2.23669 6.05397 2.21326 6.17794 2.22437 6.30124C2.23549 6.42455 2.28073 6.54233 2.35501 6.64137Z" />
                        </svg>
                        <span>环境变量</span>
                    </div>

                    <div className='envform-item-animation' style={{ gridTemplateRows: show?.env ? '1fr' : '0fr', width: 'calc(100% + 26px)' }}>
                        <div className='envform-item-animation-stamp' >
                            <div className='envform-item-con'>
                                <Form.List name="env" >
                                    {
                                        (fields, { add, remove }, { errors }) => {
                                            return <>
                                                {
                                                    fields.map(({key, name, ...field}) => {
                                                        return <div className='envform-item-con-ports' key={key} >
                                                            <Form.Item
                                                                {...field}
                                                                rules={[
                                                                    {
                                                                        message: '禁止全部输入空格', whitespace: true
                                                                    }
                                                                ]}
                                                                name={[name, 'name']}
                                                                style={{ flex: '1' }}
                                                            >
                                                                <Input placeholder="示例: JAVA_HOME" maxLength={200} allowClear />
                                                            </Form.Item>

                                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: '0px 20px', transform: 'translateY(-10px)' }}>
                                                                <path d="M12.6667 9.68604C12.8367 9.68622 13.0001 9.75129 13.1237 9.86793C13.2472 9.98458 13.3216 10.144 13.3315 10.3136C13.3415 10.4833 13.2863 10.6503 13.1772 10.7806C13.0682 10.9109 12.9135 10.9946 12.7447 11.0147L12.6667 11.0194H3.33341C3.16349 11.0192 3.00005 10.9541 2.8765 10.8375C2.75294 10.7208 2.67859 10.5614 2.66863 10.3918C2.65867 10.2221 2.71386 10.0551 2.82292 9.92481C2.93198 9.79451 3.08668 9.71077 3.25541 9.6907L3.33341 9.68604H12.6667ZM12.6667 5.68604C12.8367 5.68622 13.0001 5.75129 13.1237 5.86793C13.2472 5.98458 13.3216 6.144 13.3315 6.31363C13.3415 6.48326 13.2863 6.65029 13.1772 6.78059C13.0682 6.91089 12.9135 6.99463 12.7447 7.0147L12.6667 7.01937H3.33341C3.16349 7.01918 3.00005 6.95412 2.8765 6.83747C2.75294 6.72082 2.67859 6.5614 2.66863 6.39177C2.65867 6.22214 2.71386 6.05512 2.82292 5.92481C2.93198 5.79451 3.08668 5.71077 3.25541 5.6907L3.33341 5.68604H12.6667Z" fill="#999999" />
                                                            </svg>

                                                            <Form.Item
                                                                {...field}
                                                                rules={[
                                                                    {
                                                                        message: '禁止全部输入空格', whitespace: true
                                                                    }
                                                                ]}
                                                                name={[name, 'value']}
                                                                style={{ flex: '1' }}
                                                            >
                                                                <Input placeholder="示例: /usr/share/java" maxLength={200} allowClear />
                                                            </Form.Item>

                                                            <svg className='svg' data-disabled={!edited} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                                remove(name)
                                                            }}>
                                                                <circle cx="7.99967" cy="8.35221" r="6.16667" />
                                                                <rect x="11.7598" y="7.75195" width="1.2" height="7.51953" rx="0.6" transform="rotate(90 11.7598 7.75195)" />
                                                            </svg>

                                                        </div>
                                                    })
                                                }
                                                <div className={edited ? 'envform-item-con-add' : 'envform-item-con-add adddark'} onClick={() => {
                                                    if (!edited) return
                                                    const num = form?.getFieldValue('env')
                                                    if (num?.length >= 50) {
                                                        message.destroy()
                                                        message.warning('环境变量最多50个')
                                                        return
                                                    }
                                                    add({name: '', value: ''})
                                                }}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="7.9987" cy="8.00065" r="6.66667" />
                                                        <path d="M13.8311 8.00146C13.8311 11.2232 11.2193 13.835 7.99756 13.835C4.77581 13.835 2.16406 11.2232 2.16406 8.00146C2.16406 4.77971 4.77581 2.16797 7.99756 2.16797C11.2193 2.16797 13.8311 4.77971 13.8311 8.00146Z" fill="white" />
                                                        <rect x="7.5" y="4.00098" width="1" height="8" rx="0.5" />
                                                        <rect x="12" y="7.50098" width="1" height="8" rx="0.5" transform="rotate(90 12 7.50098)" />
                                                    </svg>
                                                    <span>新增</span>
                                                </div>
                                            </>
                                        }
                                    }
                                </Form.List>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='envform-item'>
                    <div className='envform-item-title' onClick={() => { changeShow('volumeMounts') }}>
                        <svg style={{ transform: show?.volumeMounts ? 'rotate(0deg)' : 'rotate(-90deg)' }} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.35501 6.64137L7.02168 12.8634C7.08378 12.9462 7.1643 13.0134 7.25687 13.0597C7.34944 13.1059 7.45152 13.13 7.55501 13.13C7.65851 13.13 7.76059 13.1059 7.85316 13.0597C7.94573 13.0134 8.02625 12.9462 8.08835 12.8634L12.755 6.64137C12.8293 6.54233 12.8745 6.42455 12.8857 6.30124C12.8968 6.17794 12.8733 6.05397 12.818 5.94323C12.7626 5.83249 12.6775 5.73936 12.5722 5.67427C12.4669 5.60918 12.3455 5.57471 12.2217 5.57471H2.88835C2.76454 5.57471 2.64318 5.60918 2.53786 5.67427C2.43254 5.73936 2.34743 5.83249 2.29206 5.94323C2.23669 6.05397 2.21326 6.17794 2.22437 6.30124C2.23549 6.42455 2.28073 6.54233 2.35501 6.64137Z" />
                        </svg>
                        <span>持久路径</span>
                    </div>

                    <div className='envform-item-animation' style={{ gridTemplateRows: show?.volumeMounts ? '1fr' : '0fr', width: 'calc(100% + 26px)' }}>
                        <div className='envform-item-animation-stamp'>
                            <div className='envform-item-con'>
                                <Form.List name="volumeMounts" >
                                    {
                                        (fields, { add, remove }, { errors }) => {
                                            return <>
                                                {
                                                    fields.map(({key, name, ...field}) => {
                                                        return <div className='envform-item-con-ports' key={key} >
                                                            <Form.Item
                                                                {...field}
                                                                key={key}
                                                                name={[name, 'mountPath']}
                                                                rules={[
                                                                    {
                                                                        message: '禁止全部输入空格', whitespace: true
                                                                    }
                                                                ]}
                                                                style={{ flex: '1' }}
                                                            >
                                                                <Input placeholder="示例: /var/lib/mysql" maxLength={200} allowClear />
                                                            </Form.Item>

                                                            <svg className='svg' data-disabled={!edited} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                                remove(name)
                                                            }}>
                                                                <circle cx="7.99967" cy="8.35221" r="6.16667" />
                                                                <rect x="11.7598" y="7.75195" width="1.2" height="7.51953" rx="0.6" transform="rotate(90 11.7598 7.75195)" />
                                                            </svg>
                                                        </div>
                                                    })
                                                }
                                                <div className={edited ? 'envform-item-con-add' : 'envform-item-con-add adddark'} onClick={() => {
                                                    if (!edited) return
                                                    const num = form?.getFieldValue('volumeMounts')
                                                    if (num?.length >= 10) {
                                                        message.destroy()
                                                        message.warning('持久路径最多10个')
                                                    }
                                                    add({mountPath: ''})
                                                }}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="7.9987" cy="8.00065" r="6.66667" />
                                                        <path d="M13.8311 8.00146C13.8311 11.2232 11.2193 13.835 7.99756 13.835C4.77581 13.835 2.16406 11.2232 2.16406 8.00146C2.16406 4.77971 4.77581 2.16797 7.99756 2.16797C11.2193 2.16797 13.8311 4.77971 13.8311 8.00146Z" fill="white" />
                                                        <rect x="7.5" y="4.00098" width="1" height="8" rx="0.5" />
                                                        <rect x="12" y="7.50098" width="1" height="8" rx="0.5" transform="rotate(90 12 7.50098)" />
                                                    </svg>
                                                    <span>新增</span>
                                                </div>
                                            </>
                                        }
                                    }
                                </Form.List>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

EnvForm = forwardRef(EnvForm as any)

export default EnvForm