/*
 * 目录模板弹框
 */
import React, { useEffect, useState } from 'react'
import { Modal, Form, Select, Button } from 'antd'
import { getCourseStartupTemplate } from '../../api/modules/community';

const { Option } = Select;

export type TemplateModalProps = {
  open?: boolean
  onOk: (temp: string) => void
}

const TemplateModal: React.FC<TemplateModalProps> = (props) => {
  const { open = false, onOk } = props

  const [temps, setTemps] = useState<any[]>([])

  const [form] = Form.useForm()

  const handleOK = () => {
    const values = form.getFieldsValue()
    onOk(values.temp)
  }

  // 获取目录模板
  const fetch = () => {
    getCourseStartupTemplate().then(res => {
      setTemps(res)
    })
  }

  useEffect(() => {
    if (open) {
      fetch()
    }
  }, [open])

  return (
    <>
      <Modal closable={false} centered title="请先设置目录模板" open={open} footer={
        <Button type='primary' onClick={handleOK}>确定</Button>
      } >
        <Form
          form={form}
          autoComplete="off"
          initialValues={{
            temp: '1'
          }}
        >
          <Form.Item
            label="目录模板"
            name="temp"
          >
            <Select>
              {
                temps.map(v => (
                  <Option value={v.temp} key={v.temp}>{v.value}</Option>
                ))
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default TemplateModal