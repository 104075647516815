import { message, Modal } from 'antd'
import { InfoCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { upload } from '../../../api/modules/fs'

/** 上传文件夹
 * params: upload接口请求参数
 * parent: 放置在哪个目录下
 */
export const uploadFolder = async (params: any, parent: string) => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.webkitdirectory = true
    // 文件大小限制300M！
    const maxSize = 300 * 1024 * 1024
    input.addEventListener('change', function (e: any) {
      const files: FileList | null = e?.target?.files
      if (!files) {
        reject(false)
        return
      }
      // 过滤大文件
      const filesFilter = Array.from(files)?.filter((v: any) => v?.size < maxSize)
      Modal.confirm({
        title: '上传确认',
        content: <div>
          <div>
            <span>经扫描，文件夹内共包含</span>
            <span style={{ color: '#1677FF' }}>{files?.length}</span>
            <span>个文件，其中</span>
            <span style={{ color: '#1677FF' }}>{files?.length - filesFilter?.length}</span>
            <span>个超过300MB，无法上传，可上传文件</span>
            <span style={{ color: '#1677FF' }}>{filesFilter?.length}</span>
            <span>个</span>
          </div>
          <div style={{ color: '#999999', fontSize: '12px', marginTop: '10px' }}>
            <InfoCircleOutlined />
            <span>上传过程中请勿中途离开噢，否则可能导致上传失败。</span>
          </div>
        </div>,
        centered: true,
        width: 400,
        cancelText: '取消上传',
        okText: '继续上传',
        onOk() {
          if (filesFilter?.length) {
            // 每个文件上传参数
            const newFiles = filesFilter.map((v: any) => {
              return {
                ...params,
                file: `${parent}${v?.webkitRelativePath}`,
                data: {
                  file: v
                }
              }
            });
            // 限制并发上传
            limitedUpload(newFiles, 50)
              .then(([results, errors]: any) => {
                const successFiles = results?.filter((v: any) => v?.success)
                if (successFiles?.length === newFiles?.length) {
                  message.success('上传成功')
                } else {
                  Modal.warning({
                    title: '上传失败',
                    icon: <ExclamationCircleFilled />,
                    content: (
                      <div>
                        <span>因网络原因，成功上传</span>
                        <span style={{ color: '#30C213' }}>{successFiles?.length}</span>
                        <span>个文件，失败</span>
                        <span style={{ color: '#1677FF' }}>{newFiles?.length - successFiles?.length}</span>
                        <span>个，请稍后重试。</span>
                      </div>
                    ),
                    okText: '我知道了',
                    centered: true,
                    width: 400
                  })
                }
                return resolve(true)
              }).catch(errors => {

              })
          } else {
            reject(false)
          }
        }
      })
    })

    input.click()
  })

}

// 限制上传接口并发数量
const limitedUpload = (files: any, limit: number) => {
  return new Promise((resolve, reject) => {
    const totalFiles = files?.length; // 总文件数量
    const uploadQueue: any = [...files]; // 使用队列来管理待上传的文件
    const results: any = []; // 存储所有上传结果  
    const errors: any = []; // 存储所有上传错误  
    let running = 0;

    // 从队列中取出文件并开始上传
    const next = () => {
      if (uploadQueue?.length === 0 || running === limit) {
        return;
      }
      running++;
      const file = uploadQueue.shift();

      upload(file)
        .then(result => {
          results.push(result);
        })
        .catch(error => {
          errors.push(error);
        })
        .finally(() => {
          running--;
          if (uploadQueue?.length > 0) {
            next();
          } else if (running === 0) {
            resolve([results, errors])
          }
        });
    }

    // 开始上传指定数量的文件
    for (let i = 0; i < Math.min(limit, totalFiles); i++) {
      next();
    }
  })
}