

// 对秒进行格式化    hTemp 当没有小时，是否不显示 默认 true
export function formatSeconds(time:number, hTemp: boolean = true): string {
    time = Math.floor(time)
    const h = Math.floor(time / 60 / 60)
    const m = Math.floor(time / 60 % 60)
    const s = time % 60
    let str = ''
    if (hTemp) {
        if (h > 0) {
            str += format(h) + ':'
        }
    } else {
        str += format(h) + ':'
    }
    str += format(m) + ':'
    str += format(s)
    return str

  function format(time: number) {
    return time.toString().length > 1 ? `${time}` : `0${time}`;
  }
}

// 转换为时分秒
export const formatTime = (seconds: any) => {
  seconds = Math.floor(seconds);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const remainingSeconds = Math.floor(seconds - hours * 3600 - minutes * 60);

  const hoursDisplay =
    hours > 0 ? (hours < 10 ? "0" + hours : hours) + ":" : "";
  const minutesDisplay =
    minutes > 0 ? (minutes < 10 ? "0" + minutes : minutes) + ":" : "00:";
  const secondsDisplay =
    remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

  return hoursDisplay + minutesDisplay + secondsDisplay;
};

export function handleTimeSecond(time: any) {
  const h = time.hour()
  const m = time.minute()
  const s = time.second()
  return h * 3600 + m * 60 + s
}
