import React, { createContext, useEffect, useState } from 'react'
import './index.less'
import { Button, Drawer, message, Space } from 'antd'
import KnowledgeLabel from '../KnowledgeLabel'
import Row from './Row'
import { getKnowledgeTree, saveChildrenKnowledge } from '@/api/modules/community'
import { v4 as uuid } from "uuid"

type KnowledgeDrawerProps = {
    open: boolean
    onClose: () => void
    finish: (data: string[]) => Promise<any>
    courseCode: string     // 课程code
    value?: any[]       // 初始选择的知识点
}
export const rowContext = createContext<any>({})

const KnowledgeDrawer: React.FC<KnowledgeDrawerProps> = (props) => {

    const { open, onClose, finish, value, courseCode } = props

    const [tree, setTree] = useState<any>([])                       // 列表数
    const [checkedList, setCheckedList] = useState<any>([])         // 选择的知识点

    useEffect(() => {
        if (open) {
            fetchTree()
            setCheckedList(value ?? [])
        }
    }, [open, value])

    const fetchTree = async () => {
        const tree = await getKnowledgeTree(courseCode).catch(() => { })
        setTree(handleTree(tree ?? []))
    }

    // 将获取的结构添加元数据，用来做一些展示的控制
    const handleTree = (tree: any[]) => {
        return tree.reduce((total, item) => {
            item._type = 'dir'               // 默认节点的类型为目录，如果是输入框就为input
            if (item.children?.length) {
                item._open = true                // 控制展开还是收起
                item._hasChildren = true         // 是否有展开收取  ，有子节点才有  直接提取出来
                item.children = handleTree(item.children)
            } else {
                item._open = false
                item._hasChildren = false
            }
            item._checked = value?.some((it: any) => it.knowledgeId === item.knowledgeId)
            total.push(item)
            return total
        }, [])
    }

    // ---------------------------------表格相关方法
    // 添加一个输入框
    const addRowInput = (data: any) => {
        // 判断是否存在多个输入框，如果存在则取消这次添加
        const count = (tree: any[]) => {
            return tree.reduce((total, item) => {
                if (!total) {
                    if (item._type === 'input') {
                        total += 1
                    }
                    if (item.children?.length) {
                        total += count(item.children)
                    }
                }
                return total
            }, 0)
        }
        if (count(tree) > 0) {
            message.destroy()
            message.warning('请先保存编辑中的知识点再添加~')
            return
        }
        const row = {
            _type: 'input',
            _checked: false,
            _open: false,
            _hasChildren: false,
            _parent: { ...data, children: undefined },
            knowledgeId: uuid(),
            parentKnowledgeId: data.knowledgeId
        }
        let exit = false
        const handel = (tree: any[]) => {
            return tree.reduce((total, item) => {
                if (!exit) {
                    if (item.knowledgeId === data.knowledgeId) {
                        exit = true
                        if (item.children?.length) {
                            item.children.push(row)
                        } else {
                            item.children = [row]
                        }
                        item._open = true
                        item._hasChildren = true
                        total.push(item)
                        return total
                    }
                    if (item.children?.length) {
                        item.children = handel(item.children)
                    }
                }
                total.push(item)
                return total
            }, [])
        }
        const _tree = handel(tree)
        setTree(_tree)
    }

    // 添加子级节点
    const addKnowledge = async (data: any, name: string) => {
        if (/^[\s]*$/.test(name)) {
            message.destroy()
            message.warning('请输入知识点')
            return
        }
        const res = await saveChildrenKnowledge({
            courseCode,
            name,
            level: data._parent.level,
            labCode: data._parent.labCode,
            parentKnowledgeId: data._parent.knowledgeId
        }).catch((err) => {
            message.destroy()
            message.error(err.message ?? '保存失败')
        })
        if (res) {
            changeRow(data.knowledgeId, {
                ...res,
                // type: 'CUSTOM',
                // name: name,
                // knowledgeId: data.knowledgeId,
                // parentKnowledgeId: data._parent.knowledgeId,
                _open: false,
                _hasChildren: false,
                _type: 'dir',
                _checked: false
            }, true)
        }
    }

    // 改变某一行的数据 
    const changeRow = (knowledgeId: string, data: any, over = false) => {
        let exit = false
        const handel = (tree: any[]) => {
            return tree.reduce((total, item) => {
                if (!exit) {
                    if (item.knowledgeId === knowledgeId) {
                        total.push(over ? { ...data } : { ...item, ...data })
                        exit = true
                        return total
                    }
                    if (item.children?.length) {
                        item.children = handel(item.children)
                    }
                }
                total.push(item)
                return total
            }, [])
        }
        const _tree = handel(tree)
        setTree(_tree)
    }

    // 移除某一行
    const removeRow = (knowledgeId: string) => {
        let parentKnowledgeId = ''
        const handel = (tree: any[]) => {
            return tree.reduce((total, item) => {
                if (!parentKnowledgeId) {
                    if (item.knowledgeId === knowledgeId) {
                        parentKnowledgeId = item.parentKnowledgeId
                        return total
                    }
                    if (item.children?.length) {
                        item.children = handel(item.children)
                    }
                }
                total.push(item)
                return total
            }, [])
        }
        let _tree = handel(tree)
        // 节点移除后需要更新他的父节点
        if (parentKnowledgeId) {
            let already = false
            const handel = (tree: any[]) => {
                return tree.reduce((total, item) => {
                    if (!already) {
                        if (item.knowledgeId === parentKnowledgeId) {
                            already = true
                            if (!item.children?.length) {
                                item._open = false
                                item._hasChildren = false
                            }
                            total.push(item)
                            return total
                        }
                        if (item.children?.length) {
                            item.children = handel(item.children)
                        }
                    }
                    total.push(item)
                    return total
                }, [])
            }
            _tree = handel(_tree)
        }
        setTree(_tree)
    }



    // 改变选中
    const changeSelect = (value: any, checked: boolean) => {
        if (checked && checkedList?.length >= 10) {
            message.destroy()
            message.warning('至多可关联10个知识点')
            return
        }
        if (checked) {
            setCheckedList([...checkedList, value])
        } else {
            setCheckedList(checkedList.filter((item: any) => value.knowledgeId !== item.knowledgeId))
        }
        changeRow(value.knowledgeId, { _checked: checked })
    }

    const save = () => {
        finish?.(checkedList)
        onClose()
    }



    return (
        <>
            {
                open && <Drawer
                    rootClassName={'knowledge-drawer'}
                    placement='bottom'
                    closable={false}
                    onClose={onClose}
                    open={open}
                    width={'100vw'}
                >
                    <svg onClick={onClose} className='close' width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_1818_10944" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="2" width="21" height="20">
                            <rect x="2.89893" y="2" width="20" height="20" />
                        </mask>
                        <g mask="url(#mask0_1818_10944)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.9748 5.7151C20.1918 5.49814 20.1918 5.14639 19.9748 4.92943C19.7579 4.71247 19.4061 4.71247 19.1892 4.92943L12.9004 11.2182L6.61213 4.92989C6.39517 4.71294 6.04341 4.71294 5.82645 4.92989C5.6095 5.14685 5.60949 5.49861 5.82645 5.71557L12.1147 12.0039L5.8327 18.2859C5.61575 18.5028 5.61574 18.8546 5.8327 19.0716C6.04966 19.2885 6.40142 19.2885 6.61838 19.0716L12.9004 12.7895L19.1829 19.072C19.3999 19.289 19.7516 19.289 19.9686 19.072C20.1855 18.8551 20.1855 18.5033 19.9686 18.2864L13.6861 12.0039L19.9748 5.7151Z" />
                        </g>
                    </svg>

                    <div className='container'>
                        <div className='container-left'>
                            <p className='container-title'>请勾选知识点设置关联</p>
                            <div className='container-left-table'>
                                <rowContext.Provider value={{ addRowInput, changeRow, addKnowledge, removeRow, changeSelect }}>
                                    {
                                        tree.map((item: any) => {
                                            return <Row key={item.knowledgeId} value={item} />
                                        })
                                    }
                                </rowContext.Provider>
                            </div>
                        </div>
                        <div className='container-right'>
                            <p className='container-title'>已选知识点</p>

                            <div className='container-right-labels'>
                                {
                                    checkedList.map((item: any) => {
                                        return <KnowledgeLabel key={item.knowledgeId} title={item.name} cancel={() => { changeSelect(item, false) }} />
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <Space align='center' size={30} style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                        <Button style={{ minWidth: '88px' }} onClick={onClose}>取消</Button>
                        <Button type='primary' style={{ minWidth: '88px' }} onClick={save}>确定</Button>
                    </Space>
                </Drawer>
            }
        </>
    )
}

export default KnowledgeDrawer