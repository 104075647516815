import React, { memo, useEffect } from 'react'
import './index.less'
import { Form, FormInstance, Input, InputNumber, Select } from 'antd'
import SvgIcon from '@/components/SvgIcon'
import ListItem from './ListItem'

type VerifyProps = {
    form?: FormInstance
    formData: any
}

const Verify: React.FC<VerifyProps> = memo((props) => {

    const { form, formData } = props

    const verifyMode = Form.useWatch('verifyMode', form)
    const paramNum = Form.useWatch('paramNum', form)

    useEffect(() => {
        form?.setFieldsValue({
            verifyMode: formData?.verifyMode ? formData.verifyMode : 'INPUT_OUTPUT',
            paramNum: formData?.paramNum ? formData.paramNum : 1,
            example: formData?.example ? formData.example : [{input: [''], output: ''}],
            testExample: formData?.testExample ? formData.testExample : [{input: [''], output: ''}]
        })
    }, [formData])

    useEffect(() => {
        if (!paramNum) return
        const { example, testExample } = form?.getFieldsValue(['example', 'testExample'])
        if (paramNum === example[0].input.length) {
            return
        }
        form?.setFieldsValue({
            example: example.map((item: any) => ({...item, input: newArr(item.input, paramNum)})),
            testExample: testExample.map((item: any) => ({...item, input: newArr(item.input, paramNum)}))
        })

        function newArr(arr: string[], length: number) {
            let result: string[] = []
            if (arr.length < length) {
                result = arr.concat(Array.from({length: length - arr.length}, () => ''))
                return result
            }
            return arr.slice(0, length)
        }
    }, [paramNum])

    return (
        <div className='content2'>
            <Form.Item
                label='校验方式'
                name='verifyMode'
                rules={[
                    { required: true, message: '请选择校验方式', validateTrigger: "onSubmit", whitespace: true },
                ]}
                style={{ marginRight: '50px' }}
                validateFirst
            >
                <Select
                    placeholder="请选择校验方式"
                    // fieldNames={{ label: 'languageName', value: 'languageName' }}
                    options={[
                        {
                            value: 'INPUT_OUTPUT',
                            label: '输入输出校验',
                        },
                        {
                            value: 'NONE',
                            label: '无校验'
                        }
                    ]}
                    onChange={(value) => {
                        'NONE' === value && form?.setFieldsValue({
                            paramNum: 1,
                            example: [{input: [''], output: ''}],
                            testExample: [{input: [''], output: ''}]
                        })
                    }}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                />
            </Form.Item>

            {
                (verifyMode === 'INPUT_OUTPUT') && <>
                    <Form.Item
                        label='输入参数'
                        name='paramNum'
                        rules={[
                            { required: true, message: '请输入参数个数', validateTrigger: "onSubmit", type: 'number' }
                        ]}
                        validateFirst
                    >
                        <InputNumber
                            min={1}
                            max={10}
                            suffix={<span style={{ color: '#333333' }}>个</span>}
                            style={{ width: '100px' }}
                        />
                    </Form.Item>

                    <Form.Item
                        label='样例'
                        required
                        style={{marginBottom: '10px'}}
                    >
                        <div className='list-tip'>
                            <SvgIcon iconClass={'promptIcon'} fontSize='14px' />
                            <span>考生可见，帮助考生理解输入输出</span>
                        </div>
                        <Form.List name="example">
                            {
                                (fields, { add, remove }, { errors }) => {
                                    return <>
                                        {
                                            fields.map((field) => {
                                                return <ListItem key={field.key} field={field} remove={remove} length={fields.length}/>
                                            })
                                        }
                                        <div className='list-add' data-disabled={fields?.length >= 10} onClick={() => {
                                            if (fields?.length < 10) {
                                                add({input: Array.from({length: paramNum ?? 1}, () => ''), output: ''})
                                            }
                                        }}>
                                            <SvgIcon iconClass='ques-add' style={{ width: '16px', height: '16px' }} />
                                            <span>新增样例</span>
                                        </div>
                                    </>
                                }
                            }
                        </Form.List>
                    </Form.Item>

                    <Form.Item
                        label='测试用例'
                        required
                        style={{marginBottom: '10px'}}
                    >
                        <div className='list-tip'>
                            <SvgIcon iconClass={'promptIcon'} fontSize='14px' />
                            <span>考生不可见，用于测试考生代码正确性</span>
                        </div>
                        <Form.List name="testExample">
                            {
                                (fields, { add, remove }, { errors }) => {
                                    return <>
                                        {
                                            fields.map((field) => {
                                                return <ListItem key={field.key} field={field} remove={remove} length={fields.length}/>
                                            })
                                        }
                                        <div className='list-add' data-disabled={fields?.length >= 10} onClick={() => {
                                            if (fields?.length < 10) {
                                                add({input: Array.from({length: paramNum ?? 1}, () => ''), output: ''})
                                            }
                                        }}>
                                            <SvgIcon iconClass='ques-add' style={{ width: '16px', height: '16px' }} />
                                            <span>新增测试用例</span>
                                        </div>
                                    </>
                                }
                            }
                        </Form.List>
                    </Form.Item>
                </>
            }
        </div>
    )
})

export default Verify