import React from 'react'
import './index.less'
import { Divider, Form, Input } from 'antd'

type ListItemProps = {
    field: any
    remove: any
    length: number
}

const ListItem: React.FC<ListItemProps> = (props) => {

    const { field, remove, length } = props

    return (
        <div className='list-item'>
            <div className='list-item-left'>
                <Form.Item label='输入' style={{marginBottom: ' 0'}}>
                    <Form.List name={[field.name, 'input']}>
                        {
                            (fields, { add, remove }, { errors }) => {
                                return <>
                                    {
                                        fields.map((field, index) => {
                                            return <Form.Item
                                                {...field}
                                                rules={[
                                                    { required: true, message: '请输入内容', validateTrigger: "onSubmit", whitespace: true }
                                                ]}
                                                required={false}
                                                validateFirst
                                            >
                                                <Input
                                                    prefix={<span>参数{index + 1}<Divider style={{height: '16px', borderColor: '#d9d9d9'}} type="vertical" /></span>}
                                                    placeholder='请输入内容'
                                                    allowClear
                                                />
                                            </Form.Item>
                                        })
                                    }
                                </>
                            }
                        }
                    </Form.List>
                </Form.Item>

                <Form.Item
                    label='输出'
                    rules={[
                        { required: true, message: '请输入内容', validateTrigger: "onSubmit", whitespace: true },
                    ]}
                    name={[field.name, 'output']}
                    required={false}
                    validateFirst
                >
                    <Input
                        placeholder='请输入内容'
                        allowClear
                    />
                </Form.Item>
            </div>

            <svg className='list-item-remove' data-disabled={length <= 1} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                length > 1 && remove(field.name)
            }}>
                <circle cx="7.99967" cy="8.35221" r="6.16667" />
                <rect x="11.7598" y="7.75195" width="1.2" height="7.51953" rx="0.6" transform="rotate(90 11.7598 7.75195)" />
            </svg>
        </div>
    )
}

export default ListItem