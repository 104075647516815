import { LibroJupyterConfiguration } from "@difizen/libro-jupyter";
import {
  inject,
  singleton,
  ApplicationContribution,
  ConfigurationService,
} from "@difizen/mana-app";

@singleton({ contrib: ApplicationContribution })
export class LibroApp implements ApplicationContribution {
  @inject(ConfigurationService)
  protected configurationService: ConfigurationService;

  onViewStart() {
    this.configurationService.set(LibroJupyterConfiguration.AllowPreferredSession, false);
  }
}
