import React from 'react'
import './index.less'
import SvgIcon from '@/components/SvgIcon'

type KnowledgeLabelProps = {
    title: string
    cancel?: () => void
}

const KnowledgeLabel: React.FC<KnowledgeLabelProps> = (props) => {

    const { title, cancel } = props

    return (
        <div className='knowledge-label'>
            <span>{title}</span>
            <SvgIcon onClick={cancel} iconClass='modalclose' fontSize='16px' />
        </div>
    )
}

export default KnowledgeLabel