/*
 * 新增编辑目录信息
 */
import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import classNames from 'classnames'
import SvgIcon from '../../SvgIcon'
import './index.less'
import { statActive } from '../../../api/modules/community'

type ScoreModalProps = {
  show?: boolean //是否显示
  params?: any //请求参数
  passMark?: any //及格线
  next?: any //是否有下一节
  onClose?: () => void //关闭弹框
  onRightClick: () => void // 返回首页 或者 去下一节
  onTryAgain: () => void //再试一次
}

const ScoreModal: React.FC<ScoreModalProps> = (props) => {
  const { show, params, passMark, next, onTryAgain, onRightClick } = props

  const [imgShow, setImgShow] = useState(false)

  // 总分
  const [totalScope, setTotalScope] = useState(0)

  // 及格
  const [pass, setPass] = useState(true)

  // 获取分值
  const fetchStatActive = () => {
    statActive(params).then(res => {
      const activityUserStat = res?.activityUserStat
      let _totalScope = activityUserStat ? activityUserStat[params.userId]?.totalScope : 0
      _totalScope = _totalScope || 0

      setTotalScope(_totalScope)
      if (_totalScope < passMark) {
        setPass(false)
      } else {
        setPass(true)
      }
    })
  }

  useEffect(() => {
    if (show) {
      fetchStatActive()
    }
  }, [show])

  return (
    <div className='score'>
      <Modal
        closable={false}
        centered
        open={show}
        forceRender={true}
        className='score-modal'
        width={700}
        footer={[
          <Button key="back" style={{ width: '124px', height: '40px' }} onClick={onTryAgain}>
            再试一次
          </Button>,
          <Button key="submit" type="primary" style={{ marginLeft: '60px', width: '124px', height: '40px' }} onClick={onRightClick}>
            {next ? '去下一节' : '返回首页'}
          </Button>
        ]}
      >
        <div className='score-content'>
          <div className={classNames('scorevalue', { 'scorevalue-no': !pass })}>
            <div className='scorevalue-back1' style={{ top: imgShow ? '10px' : '-10px' }}></div>
            <div className='scorevalue-back2' style={{ top: imgShow ? '-10px' : '10px', left: imgShow ? '10px' : '-10px' }}></div>
            <div className='scorevalue-back2' style={{ top: imgShow ? '10px' : '-10px', left: imgShow ? '-10px' : '10px' }}></div>
            <div className='scorevalue-back2' style={{ top: imgShow ? '10px' : '-10px', left: imgShow ? '10px' : '-10px' }}></div>
            <div className='scorevalue-back3' style={{ top: imgShow ? '-10px' : '10px', left: imgShow ? '-10px' : '10px' }}></div>
            <div className='scorevalueNum'>{totalScope}</div>
            <div className='scoreType'>
              <SvgIcon iconClass={pass ? 'passIcon' : 'nopass'} style={{ width: "64px", height: '25px' }} />
            </div>

          </div>
          <div className='scoreText'>
            <div style={{ width: '22px', height: '22px', marginRight: '10px' }}><SvgIcon iconClass='success' fontSize='22px' /></div>
            <span>恭喜你完成了本次考试！</span>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ScoreModal