import React, { useEffect, useState } from 'react';
import './index.less';
import SvgIcon from '../../../../../components/SvgIcon'
import { Button, Form, Input, Modal, Select, message } from 'antd';
import { courseTypes } from '../../../../../utils/commonTypes'
import { blogCrawlGitbook, blogVerifyGit, crawlGitbook, examQuestionDetail, gitbookStatus, saveBankQuestion, saveExamQuestion, saveQuestion, verifyGit } from '../../../../../api/modules/community';
import { searchFs } from '../../../../../api/modules/fs';
import { getQueryStringValue } from '../../../../../utils/index';




type runModalProps = {
  ifTrue: boolean,
  params: any,
  section: any,
  examDetai?: any
  changeValue?: () => void;
}


const RunModal: React.FC<runModalProps> = (props) => {
  const { ifTrue, params, examDetai, changeValue } = props


  const [form] = Form.useForm();

  const partner = getQueryStringValue("partner")

  const repo = getQueryStringValue("repo")

  const sign = getQueryStringValue("sign")

  const modal = getQueryStringValue("modal")

  //考试编码
  const examRecordCode = getQueryStringValue('examRecordCode')
  // 考卷编码
  const examCode = getQueryStringValue('examCode')


  //题目编码
  const questionCode = getQueryStringValue('questionCode')

  //用户ID
  const userId = getQueryStringValue('userId')

  const [defaultValue, setDefaultValue] = useState()

  //获取文件中的sh
  const getSearchFsList = () => {
    searchFs({
      partner,
      repo,
      extendName: 'sh',
      workdir: true,
      path: params.path,
      sign
    }).then(res => {
      let selectList: any = []
      res?.forEach((item: any) => {
        selectList.push({
          lable: item.path,
          value: item.path,
        })
      })
      setPathSelect(
        selectList
      )
    })
  }


  const onFinish = async (value: any) => {
    if (!questionCode) return

    let res: any = null
    if (examRecordCode) { // 考试中保存
      res = await saveQuestion({ userId, questionCode, examRecordCode, answer: value?.seletPath ?? '' }).catch(() => {})
    } else if (examCode) { // 考卷中保存
      res = await saveExamQuestion({ ...examDetai, userId, questionCode, examCode, answer: value?.seletPath ?? '' }).catch(() => {})
    } else {               // 题库中保存
      res = await saveBankQuestion({ ...examDetai, userId, questionCode, answer: value?.seletPath ?? '' }).catch(() => {})
    }
    if (res) {
      changeValue?.()
      message.success('保存成功！')
      window.postMessage({
        type: 'reGetExamDetail'
      })
    }
  }

  const [pathSelect, setPathSelect] = useState()

  useEffect(() => {
    if (ifTrue && examDetai) {
      setDefaultValue(examDetai?.userAnswer)
      if (examDetai?.userAnswer) {
        form.setFields([{
          name: 'seletPath',
          value: examDetai?.userAnswer
        }])
      }

      getSearchFsList()
    }
  }, [ifTrue, examDetai])

  return (
    <Modal wrapClassName={'importModal'} open={ifTrue} footer={''} width={'530px'} destroyOnClose centered>
      <div className='modalAll'>
        <div className='modalAll-title'>运行部署设置</div>
        <div style={{ width: '100%', marginTop: '30px', paddingRight: '10px' }}>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{}}
          >

            <Form.Item label='脚本路径' style={{ paddingBottom: '15px' }}>
              <Form.Item
                name='seletPath'
                noStyle
              >
                <Select
                  allowClear
                  defaultValue={defaultValue}
                  placeholder='请选择以.sh为结尾的脚本文件'
                  options={pathSelect}
                />
              </Form.Item>

            </Form.Item>


            <Form.Item noStyle>
              <div style={{ marginTop: '10px', textAlign: 'center' }}>
                <Button className="bottonLeft" onClick={() => {
                  changeValue?.()
                }} >
                  取消
                </Button>
                <Button type="primary" htmlType="submit" className="bottonRigth">
                  保存
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default RunModal;