import { ManaModule } from "@difizen/mana-app";
import { CloudLabLibroContentContribution } from "./libro-content-contribution";
import { LibroApp } from "./libro-app";
import { CloudLabLibroContentSaveContribution } from "./libro-save-content-contribution";
import { KernelStatusAndSelectorProvider, LibroModel } from "@difizen/libro-jupyter";
import { CloudLabLibroModel } from "./libro-model";
import { KernelStatusSelector } from "./kernel-status-and-selector";
import { CloudLabLibroService } from "./cloud-lab-libro-service";
import { CloudlabLibroCommandContribution } from "./libro-command-contribution";

export const CloudLabLibroModule = ManaModule.create().register(
    {
        token:LibroModel,
        useClass:CloudLabLibroModel,
    },
    {
        token: KernelStatusAndSelectorProvider,
        useValue: KernelStatusSelector,
    },
CloudLabLibroContentContribution,CloudlabLibroCommandContribution,CloudLabLibroContentSaveContribution,LibroApp,CloudLabLibroService);
