import React, { useContext, useEffect, useRef, useState } from 'react'
import './index.less'

import { message, Modal, Button, Form, Input, Select } from 'antd'
import ExperEnv from '../ExperEnv'
import { courseTypes } from '../../utils/commonTypes'
import SharedDataContext from '../../utils/share'
import NotebookExperEnv from '../NotebookExperEnv'

export type CourseProps = {
    open: boolean             // 打开
    isUpdate: boolean         // 是否更新    false：添加  true：编辑
    form: any                 // 表单
    topType?: string          // 是从哪里类型开始
    TopRepoEnvStatus?: any    // 最外层的信息
    labCode?: string          // 实验室code
    courseInfoList?: any      // 总的表单
    user?: string             // 用户
    creator?: any             // 用户昵称
    userId?: string           // 用户id
    onOK: (data: any) => void // 确认
    onCancel: () => void      // 取消
    // relax: () => void         // 释放环境
    courseType: any           // 课程类型
    [other: string]: any
}

const CourseModal: React.FC<CourseProps> = (props) => {

    const { courseType, isUpdate, open, onOK, onCancel, form = {}, topType, courseInfoList, TopRepoEnvStatus, user, userId, labCode, relax, creator } = props

    const [formRef] = Form.useForm()

    // 获取共享数据
    const { section, setSection } = useContext(SharedDataContext)

    // 显示数据先关
    const [titleName, setTitleName] = useState<"阶段" | "章" | "小节" | "">("")          // 标题名称后缀

    // 表单数据相关
    const [dirLevel, setDirLevel] = useState<any>([])                      // 目录层级
    const [currentDirLevel, setCurrentDirLevel] = useState<any>(null)      // 当前选择的目录层级

    const [contentType, setContentType] = useState<any>([])                // 内容类型
    const [currentContentType, setCurrentContentType] = useState<any>(null)// 当前内容类型

    const [currentName, setCurrentName] = useState<string>('')             // 当前层级或小节的名称

    const [currentLocation, setCurrentLocation] = useState('')             // 当前添加位置  路径

    const [currentExperEnv, setCurrentExperEnv] = useState<any>(null)      // 当前的实验环境

    //当前环境时长
    const [durationTime, setDurationTime] = useState('30')

    // const [experTools, setExperTools] = useState<any>([])                  // 实验工具
    const [currentExperTools, setCurrentExperTools] = useState<any>(null)  // 当前实验工具

    const [experPort, setExperPort] = useState<any>([])                    // 端口工具

    // const [isInit, setIsInit] = useState(true)                             // 是不是初次加载


    // 保存
    const save = () => {
        if (!verify()) return
        const data = {
            title: currentName.trim(),
            path: isUpdate ? currentLocation : '',
            parent: isUpdate ? form?.parent : currentLocation,
            describe: form?.describe ? JSON.parse(JSON.stringify(form?.describe)) : {},

            // template: JSON.parse(JSON.stringify(form?.template)) || {}
        }
        // 兼容 ---- 将 旧数据修正
        // if (isUpdate) {
        //     data.describe.category = data.describe.category === 'content' || data.describe.category === '' ? 'content' : 'chapter'
        // }
        if (!isUpdate) {
            if (!form?.describe) {
                const courseList = courseInfoList?.filter((it: any) => it?.parent === '')
                data.describe.index = courseList?.length ?? 0
            } else {
                data.describe.index = form?.children?.length || 0
            }
            // 现在 修复--------------category两种取值   content   或  chapter 
            // data.describe.category = currentDirLevel === 'section' ? 'content' : currentDirLevel ? 'chapter' : 'content'
            // 之前
            data.describe.category = currentDirLevel === 'section' ? 'content' : currentDirLevel ?? 'content'
            // console.log(currentDirLevel)
            data.describe.type = currentDirLevel === 'section' ? currentContentType : currentDirLevel ?? currentContentType
            // console.log(data.describe.type)
            const type: any = {
                "stage": '阶段',
                "chapter": '章',
                "section": {
                    "text": '文档',
                    "notebook": 'Notebook',
                    "video": '视频',
                    "exam": '考试',
                }
            }
            if (!currentDirLevel || currentDirLevel === 'section') {
                data.describe.title = type['section'][currentContentType]
            } else {
                data.describe.title = type[currentDirLevel]
            }
        }
        // 特殊情况   课程没有类型
        if (form && !data?.describe?.type) {
            data.describe.type = currentContentType
        }
        const ports = experPort?.map((item: any) => {
            const type = item?.type ?? 'system'
            return { ...item, type }
        })
        const template = {
            ...data?.describe?.template,
            experEnv: currentExperEnv,
            expertools: currentExperTools,
            ports,
            duration: currentExperEnv ? Number(durationTime) : 30
        }
        data.describe.template = template
        //如果是博客需要手动修改section，因为博客模式下，section不会重新下发，兼容体验课程
        if (courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE) {
            setSection({ ...section, template })
        }
        onOK?.(data)
    }

    // 保存之前的校验
    const verify = (): boolean => {
        if (currentExperEnv && titleName === '小节') {
            console.log(currentExperEnv, durationTime);
            if (!durationTime) {
                message.destroy()
                message.warning('请设置环境使用时长')
                return false
            }
            if (!(15 <= Number(durationTime) && Number(durationTime) <= 180)) {
                message.destroy()
                message.warning('请输入环境使用时长为15分钟~180分钟之间')
                return false
            }
        }


        // 编辑校验
        if (isUpdate) {
            if (Object.keys(form).length && !form?.describe?.type && !currentContentType) {
                message.destroy()
                message.warning(`请选择内容类型`)
                return false
            }
            // console.log(currentName)
            if (!currentName.trim()) {
                message.destroy()
                message.warning(`请输入${titleName === '小节' ? '小节' : '层级'}名称`)
                return false
            }
            if (currentName.includes('#') || currentName.includes('%')) {
                message.destroy()
                message.warning(`请输入除“#”、“%”外的30个字符`)
                return false
            }
            if (!/^[^\\/:*?`'"<>|\r\n\t]+$/g.test(currentName)) {
                message.destroy()
                message.warning(`当前输入包含特殊字符`)
                return false
            }
            if (!form?.title) {
                message.destroy()
                message.warning(`请输入${titleName === '小节' ? '小节' : '层级'}名称`)
                return false
            }
        } else {  // 添加校验
            if (!currentDirLevel && dirLevel?.[0]?.type !== 'section') {
                message.destroy()
                message.warning(`请选择${titleName === '小节' ? '内容' : '层级'}类型`)
                return false
            }
            if (dirLevel?.[0]?.type == 'section' && !currentContentType) {
                message.destroy()
                message.warning(`请选择小节内容类型`)
                return false
            }
            if (currentDirLevel === 'section' && !currentContentType) {
                message.destroy()
                message.warning('请选择小节类型！')
                return false
            }
            if (!currentName.trim()) {
                message.destroy()
                message.warning(`请输入${titleName === '小节' ? '小节' : '层级'}名称`)
                return false
            }
            if (currentName.includes('#') || currentName.includes('%')) {
                message.destroy()
                message.warning(`请输入除“#”、“%”外的30个字符`)
                return false
            }
            if (!/^[^\\/:*?`'"<>|\r\n\t]+$/g.test(currentName)) {
                message.destroy()
                message.warning(`当前输入包含特殊字符`)
                return false
            }
            // if (!currentExperEnv) {
            //     message.destroy()
            //     message.warning(`请选择实验环境`)
            //     return false
            // }

        }
        return true
    }


    // 根据传进来的数据判断当先的标题名称后缀
    useEffect(() => {
        if (Object.keys(form).length !== 0) {
            if (form?.describe?.type) {
                switch (form?.describe?.type) {
                    case "video":
                    case "exam":
                    case "text":
                        setTitleName("小节")
                        break
                    case "stage":
                        isUpdate ? setTitleName("阶段") : setTitleName("章")
                        break
                    case "chapter":
                        isUpdate ? setTitleName("章") : setTitleName("小节")
                        break
                }
            } else {
                setTitleName("小节")
            }
        } else {
            setTitleName("")
        }
    }, [form, topType])

    // 添加状态   初始相关数据加载
    useEffect(() => {
        if (!open) return
        if (isUpdate) return
        const dirList = [
            {
                title: '阶段',
                category: 'stage',
                type: 'stage',
                value: 'stage'
            },
            {
                title: '章',
                category: 'chapter',
                type: 'chapter',
                value: 'chapter'
            },
            {
                title: '小节',
                category: 'content',
                type: 'section',
                value: 'section'
            },
            {
                title: '文档',
                category: 'content',
                type: 'text',
                value: 'text'
            },
            {
                title: 'Notebook',
                category: 'content',
                type: 'notebook',
                value: 'notebook'
            },
            {
                title: '考试',
                category: 'content',
                type: 'exam',
                value: 'exam'
            },
            {
                title: '视频',
                category: 'content',
                type: 'video',
                value: 'video'
            }
        ]
        // 1 目录层级数据加载
        if (Object.keys(form).length !== 0) {
            const dir = form?.describe?.type
            switch (dir) {
                case dirList[0].type:
                    setDirLevel([dirList[1]])
                    break
                case dirList[1].type:
                    setDirLevel([dirList[2]])
                    break
                default:
                    break
            }
        } else {
            if (topType) {
                switch (topType) {
                    case dirList[0].type:
                        setDirLevel([dirList[0]])
                        break
                    case dirList[1].type:
                        setDirLevel([dirList[1]])
                        break
                    case dirList[3].type:
                    case dirList[4].type:
                    case dirList[5].type:
                    case dirList[6].type:
                        setDirLevel([dirList[2]])
                        break
                    default:
                        break
                }
            } else {
                setDirLevel(dirList.slice(0, 3))
            }
        }
        setCurrentDirLevel(null)

        // 2 内容类型数据加载
        const contentList = [
            {
                title: '文档',
                category: 'content',
                type: 'text',
                value: 'text'
            },
            {
                title: '视频',
                category: 'content',
                type: 'video',
                value: 'video'
            },
            {
                title: '考试',
                category: 'content',
                type: 'exam',
                value: 'exam'
            },
            {
                title: 'Notebook',
                category: 'content',
                type: 'notebook',
                value: 'notebook'
            },
        ]
        setContentType(contentList)
        setCurrentContentType(null)

        // 3 设置当前小节或层级的名称
        setCurrentName('')

    }, [open, isUpdate])

    // 编辑状态   初始相关数据加载
    useEffect(() => {
        if (!open) return
        if (!isUpdate) return
        // 1 编辑态处理目录层级数据加载   不会显示
        setDirLevel([])
        setCurrentDirLevel(null)
        // 2 编辑态不会显示内容类型     当没有类型的时候也要加载
        if (form?.describe?.type) {
            setContentType([])
        } else {
            const contentList = [
                {
                    title: '文档',
                    category: 'content',
                    type: 'text',
                    value: 'text'
                },
                {
                    title: '视频',
                    category: 'content',
                    type: 'video',
                    value: 'video'
                },
                {
                    title: '考试',
                    category: 'content',
                    type: 'exam',
                    value: 'exam'
                },
                {
                    title: 'Notebook',
                    category: 'content',
                    type: 'notebook',
                    value: 'notebook'
                },
            ]
            setContentType(contentList)
        }
        setCurrentContentType(null)

        // 3 设置当前小节或层级的名称
        setCurrentName(form?.title)

    }, [open, isUpdate])


    // 两种态都需要加载的数据
    useEffect(() => {
        if (!open) return

        // 设置为初次加载状态
        // setIsInit(true)

        //  设置当前添加位置
        setCurrentLocation(Object.keys(form).length === 0 ? '/' : form?.path)

        // 设置环境数据
        // getExperEnv({ owner: userId, lab: labCode }).then((res: any) => {
        //     setExperEnv(res)

        let now: any
        if (Object.keys(form).length === 0 && TopRepoEnvStatus?.template) {
            now = TopRepoEnvStatus?.template
        } else if (Object.keys(form).length !== 0 && form?.describe?.template) {
            now = form?.describe?.template
        } else if (Object.keys(form).length !== 0 && !form?.describe?.template) {
            now = TopRepoEnvStatus?.template
        }


        setCurrentExperEnv(now?.experEnv || '')

        setDurationTime(now?.duration?.toString() || '30')
        setCurrentExperTools(now?.expertools || '')

        setExperPort(now?.ports || [])

    }, [open])

    return (
        <Modal
            centered
            title={courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE ? `设置${courseType === courseTypes.BLOG ? '博客' : ''}实验环境` : isUpdate ? `编辑${titleName}` : `添加${titleName}`}
            open={open ?? false}
            closable={false}
            wrapClassName="modal-myself"
            destroyOnClose={true}
            footer={[
                <Button
                    key="back"
                    className='buttom1'
                    onClick={() => {
                        onCancel?.()
                    }}>
                    关闭
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={save}>
                    保存
                </Button>
            ]}>
            <Form
                form={formRef}
                name="control-hooks"
                autoComplete="off"
            >

                {
                    !isUpdate && dirLevel?.[0]?.type !== 'section' ? <Form.Item label="目录层级" key='title'>
                        <Select
                            fieldNames={{ label: 'title', value: 'type' }}
                            placeholder="请选择目录层级"
                            value={currentDirLevel}
                            options={dirLevel}
                            onChange={(value) => {
                                setCurrentDirLevel(value)
                            }} />
                    </Form.Item> : <></>
                }

                {
                    (Object.keys(form).length && !form?.describe?.type) || (dirLevel?.[0]?.type === 'section' || currentDirLevel == 'section') ? <Form.Item label='内容类型' key='value'>
                        <Select
                            fieldNames={{ label: 'title', value: 'type' }}
                            placeholder="请选择内容类型"
                            value={currentContentType}
                            options={contentType}
                            onChange={(value) => {
                                setCurrentContentType(value)
                            }} />
                    </Form.Item> : <></>
                }

                {
                    courseType === courseTypes.COURSE &&
                    <Form.Item label={titleName === '小节' ? '小节名称' : '层级名称'}>
                        <Input
                            placeholder={`请输入${titleName === '小节' ? '小节' : '层级'}名称`}
                            value={currentName}
                            showCount
                            maxLength={30}
                            onChange={(e) => {
                                setCurrentName(e.target.value)
                            }} />
                    </Form.Item>
                }

                <ExperEnv currentExperEnv={currentExperEnv} setCurrentExperEnv={setCurrentExperEnv} currentExperTools={currentExperTools} setCurrentExperTools={setCurrentExperTools} experPort={experPort} setExperPort={setExperPort} userId={userId ?? ''} labCode={labCode ?? ''} form={formRef} titleName={titleName} currentDirLevel={currentDirLevel} currentContentType={currentContentType} durationTime={durationTime} setDurationTime={setDurationTime} creator={creator} />

                {
                    !isUpdate ? <Form.Item label="添加位置">
                        <Input
                            disabled={true}
                            placeholder="当前添加位置"
                            defaultValue={currentLocation}
                        />
                    </Form.Item> : <></>
                }

            </Form>
        </Modal>
    )
}

export default CourseModal