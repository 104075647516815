import { ContentSaveContribution, LibroJupyterModel, NotebookOption } from '@difizen/libro-jupyter';
import { getOrigin, inject, ModalService, singleton } from '@difizen/mana-app';
import { CloudLabLibroModel } from './libro-model';
import { textToBase64 } from '../../utils';
  
@singleton({ contrib: ContentSaveContribution })
export class CloudLabLibroContentSaveContribution implements ContentSaveContribution {
    @inject(ModalService) protected readonly modalService: ModalService;

    canHandle = (options: NotebookOption) => {
        return options.loadType === 'cloud-lab-libro-loader' ? 100 : 1;
    };

    textToBase64(text: string) {
        const encoder = new TextEncoder();
        const uint8Array: any = encoder.encode(text);
        let binary = ''
        const chunkSize = 2048;
        for (let i = 0; i < uint8Array.length; i+=chunkSize) {
            binary += String.fromCharCode(...uint8Array.slice(i, i + chunkSize));
        }
        return btoa(binary);
    }

    saveContent = async (options: NotebookOption, model: LibroJupyterModel) => {
        const jupyterModel = getOrigin(model) as CloudLabLibroModel;
        try {
            const notebookContent = model.toJSON();
            const strNotebookContent = JSON.stringify(notebookContent);
            jupyterModel.wsReady.then(()=>{
                const data = strNotebookContent ? this.textToBase64(strNotebookContent) : ''; 
                jupyterModel.sendWsMsg(JSON.stringify({
                    type: "write",
                    data: data
                }))

            })
        }catch (e: any) {
        throw new Error('File Save Error', e);
        }
    };
}
  