import { jupyterServiceStatus, KernelSelector, kernelStatus, LibroJupyterModel, LibroView, ServerManager, ServerStatus, statusToColor } from "@difizen/libro-jupyter";
import { LoadingOutlined, StopOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useInject, ViewInstance } from "@difizen/mana-app";
import { l10n } from '@difizen/mana-l10n';
import { Badge } from "antd";
import './index.less'
import { CloudLabLibroModel } from "./libro-model";
import { CloudLabLibroService } from "./cloud-lab-libro-service";

const getServiceStatusInfo = (
    serverManager: ServerManager | undefined,
    libroModel: LibroJupyterModel | undefined,
  ): ServerStatus => {  
    if (!serverManager || serverManager.launching) {
      return jupyterServiceStatus['loading'];
    }
  
    if (
      !libroModel ||
      !(libroModel instanceof LibroJupyterModel) ||
      libroModel.kernelConnecting === true ||
      libroModel.kernelConnecting === undefined
    ) {
      return kernelStatus['connecting'];
    }
  
    if (!libroModel.kernelConnection) {
      return {
        color: statusToColor.blocking,
        text: 'no kernel',
        category: 'Kernel',
        text_zh: l10n.t('无内核'),
      };
    }
  
    return kernelStatus[libroModel.kernelConnection.status];
  };

export const KernelStatusSelector: React.FC = () => {
    const libroView = useInject<LibroView>(ViewInstance);
    const serverManager = useInject(ServerManager);
    const libroModel = (libroView?.model as CloudLabLibroModel);
    const cloudLabLibroService = useInject(CloudLabLibroService)

    const { color, text, text_zh, category } = getServiceStatusInfo(
      serverManager,
      libroModel,
    );

    if(cloudLabLibroService.globalEnvStatus!==1){
      return (
          <div className="libro-kernel-and-container-status">
              <div className="libro-container-and-service-status">
                  <ExclamationCircleOutlined style={{color:"#ff4d4f"}}/>                    
                  <span className="libro-server-unready-tip">{l10n.t('服务未启动，需启动环境才能执行')}</span>
              </div>
        </div>
      )
  }
    if (serverManager.loaded) {
      const showBadge =
        (libroModel.kernelConnection && !libroModel.kernelConnection.isDisposed) ||
        text === 'connecting';
      const isKernelBusy = text === 'busy';
  
      return (
        <div className="libro-kernel-and-container-status">
          <div className="libro-kernel-status-and-selector">
            <span className="kernel">kernel：</span>
            <KernelSelector />
            {showBadge &&
              (isKernelBusy ? (
                <Badge
                  className="libro-kernel-badge"
                  key="libro-kernel-badge"
                  color={kernelStatus['busy'].color}
                  text={kernelStatus['busy'].text_zh}
                />
              ) : (
                <Badge
                  className="libro-kernel-badge"
                  key="libro-kernel-badge"
                  color={color}
                  text={text_zh}
                />
              ))}
          </div>
        </div>
      );
    }
  
    return (
      <div className="libro-kernel-and-container-status">
        <div className="libro-container-and-service-status">
          {text !== 'failed' && (
            <>
              <LoadingOutlined className="loading-icon" />
              <span className="no-kernel">{l10n.t('Kernel 准备中...')}</span>
            </>
          )}
          {text === 'failed' && category === 'JupyterService' && (
            <>
              <StopOutlined className="failed-icon" />
              <span className="kernel-prepare-failed">{l10n.t('Kernel 准备失败')}</span>
            </>
          )}
        </div>
      </div>
    );
  };