import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react'
import './index.less'
import SvgIcon from '../../SvgIcon'
import ListContainer from '../ListContainer'
import QuesItem from '../QuesItem'
import { getQuesAndAns } from '../../../api/modules/community'
import { Button, Tooltip } from 'antd'
import SharedDataContext from '../../../utils/share'
import DetailQues from '../DetailQues'
import PublishQues from '../PublishQues'
import Header from '../Header'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

type QuesProps = {
    current: any
    onClose: () => void
    [key: string]: any
}


const Ques: React.FC<QuesProps> = (props) => {

    const { current, onClose } = props

    const [pageSize, setPageSize] = useState(20)   // 每页条数
    const [hasMore, setHasMore] = useState(true)   // 是否有下一页
    const [data, setData] = useState<any>([])      // 列表数据

    const [fresh, setFresh] = useState(false)      // 是否刷新中
    const [empty, setEmpty] = useState(false)      // 是否列表为空
    const [showFooter, setShowFooter] = useState(false) // 显示底部的按钮
    const ListContainerRef = useRef<any>(null)     // 滚动列表ref
    const [component, setComponents] = useState<'ques' | 'publish' | 'detail'>('ques') // 当前显示的组件
    const [detailId, setDetailId] = useState('')    // 问答详情的id

    // 获取共享数据
    const { section, labCode, couseCode, courseType } = useContext(SharedDataContext)

    // 分页查询   是否清空列表重新请求
    const fetchList = async (isClear: boolean = false) => {
        if (isClear) {
            setFresh(true)
        }
        const res = await getQuesAndAns({
            current: isClear ? 1 : Math.ceil(data.length / pageSize) + 1,
            size: pageSize,
            chapter: section.path,
            labCode,
            code: couseCode,
        }).finally(() => {
            setFresh(false)
        })
        setHasMore(res?.hasNext ?? false)
        setEmpty(!res?.total)
        if (isClear) {
            setData(res?.rows ?? [])
        } else {
            setData([...data, ...res?.rows])
        }
    }

    // 监听小节变化获取问答
    useEffect(() => {
        if (component !== 'ques') return
        if (section.path && labCode && couseCode) {
            fetchList(true)
        }
    }, [section.path, labCode, couseCode, component])

    useEffect(() => {
        setComponents('ques')
    }, [section.path])

    // 前往问答详情详情
    const gotoDetail = (id: string) => {
        setComponents('detail')
        setDetailId(id)
    }

    // 发布问答
    const publish = () => {
        setComponents('publish')
    }


    return (
        <>
            <TransitionGroup component={null}>
                {
                    component === 'ques' ? <CSSTransition
                        key={component}
                        timeout={400}
                        classNames={{
                            enter: 'ques-css-enter',
                            enterActive: 'ques-css-enter-active',
                            exit: 'ques-css-exit',
                            exitActive: 'ques-css-exit-active'
                        }}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className='ques-lay'>
                            <Header current={current} onClose={onClose} />
                            <div className='ques-co'>
                                <div className='ques'>
                                    <ListContainer
                                        ref={ListContainerRef}
                                        hasMore={hasMore}
                                        fetchData={fetchList}
                                        topDistance={(value: number) => {
                                            if (value > 56) {
                                                !showFooter && setShowFooter(true)
                                            } else {
                                                showFooter && setShowFooter(false)
                                            }
                                        }}
                                        showMore={!empty}
                                    >
                                        {
                                            data?.length ? <div className='ques-publishtip'>
                                                <SvgIcon iconClass='ques-tip' style={{ width: '16px', height: '16px' }} />
                                                <p>学习有疑问？快去<span onClick={publish}>发布问答</span>试试吧~</p>
                                            </div> : <></>
                                        }
                                        {
                                            fresh ? <div className='ques-loading'>
                                                <SvgIcon iconClass='ques-loading' style={{ width: '12px', height: '12px' }} className='ques-loading-rotate' />
                                                <span>刷新中…</span>
                                            </div> : <></>
                                        }
                                        {
                                            empty ? <div className='ques-nodata'>
                                                <img src={require('../../../assets/images/nodata.png')} alt="" />
                                                <span className='span'>这里还没有任何内容，快去发布问答试试吧~</span>
                                                <Button type='primary' onClick={publish}>立即发布</Button>
                                            </div> : data?.map((item: any) => {
                                                return <QuesItem data={item} key={item?.id} onClick={gotoDetail} courseType={courseType} />
                                            })
                                        }
                                    </ListContainer>

                                    {
                                        showFooter ? <div className='ques-footer-right'>
                                            <Tooltip placement="bottomLeft" arrow={false} title={'发布问答'} overlayClassName='item-tooltip'>
                                                <div className='ques-footer-right-item' onClick={publish}>
                                                    <SvgIcon iconClass={'ques-footer-publish'} style={{ width: '20px', height: '20px' }} />
                                                </div>
                                            </Tooltip>

                                            <Tooltip placement="bottomLeft" arrow={false} title={'回到最新'} overlayClassName='item-tooltip'>
                                                <div className='ques-footer-right-item' onClick={() => {
                                                    fetchList(true)
                                                    ListContainerRef?.current?.scrollToTop()
                                                }}>
                                                    <SvgIcon iconClass={'ques-footer-top'} style={{ width: '20px', height: '20px' }} />
                                                </div>
                                            </Tooltip>
                                        </div> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </CSSTransition> :
                        component === 'detail' ? <CSSTransition
                            key={component}
                            timeout={400}
                            classNames={{
                                enter: 'right-to-left-enter',
                                enterActive: 'right-to-left-active',
                                exit: 'left-to-right-exit',
                                exitActive: 'left-to-right-active'
                            }}
                            mountOnEnter
                            unmountOnExit
                        >
                            <DetailQues id={detailId} back={() => {
                                setComponents('ques')
                            }} onClose={onClose} />
                        </CSSTransition> :
                            component === 'publish' ? <CSSTransition
                                key={component}
                                timeout={400}
                                classNames={{
                                    enter: 'right-to-left-enter',
                                    enterActive: 'right-to-left-active',
                                    exit: 'left-to-right-exit',
                                    exitActive: 'left-to-right-active'
                                }}
                                mountOnEnter
                                unmountOnExit
                            >
                                <PublishQues back={() => {
                                    setComponents('ques')
                                }} onClose={onClose} />
                            </CSSTransition> :
                                <></>
                }

            </TransitionGroup>
        </>
    )
}

export default Ques