import React, { useEffect, useState } from 'react'
import './index.less'
import SvgIcon from '../../../../../components/SvgIcon'
import { getQueryStringValue, isJsonString } from '../../../../../utils'
import { getUserDetails } from '../../../../../api/modules/community'
import config from '../../../../../config'

type CollaborationProps = {
  [other: string]: any
}

const Collaboration: React.FC<CollaborationProps> = (props) => {

  // 仓库
  const repo = getQueryStringValue('repo')

  // 用户
  const user = getQueryStringValue('key')

  // 考试的类型
  const examType = getQueryStringValue("examType")

  // 显示同时在线
  const [showMultiUser, setShowMultiUser] = useState<boolean>(false)

  // 同时在线用户
  const [multiUser, setMultiUser] = useState<any>([])

  //是否需要重连
  const [reConnect, setReConnect] = useState(true)

  useEffect(() => {
    if (examType === '0') return
    if (!reConnect) return
    const ws = new WebSocket(`${config.wsURL}?key=${user}&repo=${repo}&component=stats`);
    setReConnect(false)
    ws.onmessage = function (res: any) {
      const data = res?.data
      if (isJsonString(data)) {
        let _data = JSON.parse(data)
        console.log('协同 => ', _data);
        // 过滤掉本人
        _data = _data.filter((v: any) => v.key !== user)
        _data = _data.map((v: any) => v?.key)

        if (!_data.length) {
          setMultiUser([])
          return
        }

        getUserDetails({ userIds: _data }).then(res => {
          setMultiUser(res)
        })
      }
    }
    ws.onclose = (res: any) => {
      if (res.code < 3000) {
        const _timer = setTimeout(() => {
          setReConnect(true)
          clearTimeout(_timer)
        }, 1000);
      }
    }
    return () => {
      ws?.readyState === 1 && ws?.close(4999)
    }
  }, [reConnect])

  // 控制位置
  useEffect(() => {
    const ele: any = document.getElementsByClassName('collaboration')
    const inviteEle:any = document.getElementsByClassName('inviteIcon')
    if (!ele?.length) return
    const _ele = ele[0]
    if (multiUser?.length) {
      _ele.style.padding = '4px 0'
      if (inviteEle?.length) {
        // 隐藏邀请协作的icon
        inviteEle[0].style.display = 'none'
      }
    } else {
      _ele.style.padding = 0
      if (inviteEle?.length) {
        // 显示邀请协作的icon
        inviteEle[0].style.display = 'initial'
      }
    }
  }, [multiUser])

  return (
    <>
      {
        multiUser?.length ? (
          <div className='multi-user'
            onMouseEnter={() => setShowMultiUser(true)}
            onMouseLeave={() => setShowMultiUser(false)}
          >
            <div>
              <div className='multi-user-icon'>
                {multiUser[0]?.detail?.avatar ?
                  <img src={multiUser[0]?.detail?.avatar} style={{ width: '28px', borderRadius: '28px' }} alt="" /> : <SvgIcon iconClass='user' fontSize='28px' />
                }
                <div className='multi-user-online'>
                  <SvgIcon iconClass='online' fontSize='10px' />
                </div>
              </div>

            </div>
            {
              showMultiUser ? (
                <div className='multi-user-modal'>
                  <div className='multi-user-modal-contain'>
                    <div className='multi-user-modal-contain-title'>
                      同时在线
                      <div className='line'></div>
                    </div>
                    <div className='multi-user-modal-contain-users'>
                      {
                        multiUser?.map((v: any) => (
                          <div className='multi-user-modal-contain-users-user' key={v?.userId}>
                            <div>
                              {v?.detail?.avatar ?
                                <img src={v?.detail?.avatar} style={{ width: '20px', borderRadius: '20px' }} alt="" /> : <SvgIcon iconClass='user' fontSize='20px' />
                              }

                            </div>
                            <div>{v?.detail?.nickname}</div>
                          </div>
                        ))
                      }
                    </div>

                  </div>
                </div>
              ) : <></>
            }
          </div>
        ) : <></>
      }
    </>
  )
}

export default Collaboration;