import React from 'react'
import './index.less'
import RichEditor from '../../../components/RichEditor'

type DetailQuesItem = {
    data: any
}

const DetailQuesItem: React.FC<DetailQuesItem> = (props) => {

    const { data } = props

    return (
        <div className='detailquesitem'>
            <div className='detailquesitem-left'>
                <img src={data?.userInfo?.avatar} alt="" />
            </div>

            <div className='detailquesitem-right'>
                <p className='detailquesitem-right-msg'>
                    <span>{data?.userInfo?.displayName}</span>
                    <span>{data?.createDateTime}</span>
                </p>
                {
                    data?.content ? <div className='detailquesitem-right-content'>
                        <RichEditor readOnly autoHeight={{min: 0, max: 1000}} defaultValue={data?.content} isBorder={false}/>
                    </div> : <></>
                }
            </div>
        </div>
    )
}

export default DetailQuesItem