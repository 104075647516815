import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import SvgIcon from '../SvgIcon'
import { Tooltip } from 'antd'

type SiderTopTitleProps = {
    title: React.ReactNode               // 导航栏 title
    open?: boolean                       // 是否打开  默认打开
    changeOpen?: (open: boolean) => void // 改变当前状态
    menus?: React.ReactNode              // 菜单项
    follow?: boolean                     // 菜单项是否跟随open的展示 默认true
    showOpenTriangle?: boolean           // 三角形开关是否显示   默认可以
    [key: string]: any
}

const SiderTopTitle: React.FC<SiderTopTitleProps> = (props) => {

    const { title, open = true, menus, children, changeOpen, follow = true, showOpenTriangle = true } = props

    const dom = useRef<any>(null)
    const [showtoolTip, setShowToolTip] = useState(false)

    useEffect(() => {
        if (dom.current && dom.current.scrollWidth > dom.current.clientWidth) {
            setShowToolTip(true)
        } else {
            setShowToolTip(false)
        }
    }, [title])

    return <div id='sidertoptitle' style={{ height: '100%' }}>
        <div className='sidertoptitle' >
            {
                showOpenTriangle && <div className='sidertoptitle-left'>
                    <SvgIcon onClick={() => { changeOpen?.(!open) }} iconClass={'blue-bottom'} fontSize='12px' style={{ transform: open ? 'rotate(0deg)' : 'rotate(-90deg)', transition: '.2s' }} />
                    {
                        showtoolTip ? <Tooltip placement="bottomLeft" title={title} arrow={false} overlayClassName='item-tooltip'>
                            <span ref={dom} className='sidertoptitle-left-title'>{title}</span>
                        </Tooltip> : <span ref={dom} className='sidertoptitle-left-title'>{title}</span>
                    }
                </div>
            }
            {
                ((follow && open) || !follow) && <div className='sidertoptitle-right'>
                    {menus}
                </div>
            }
        </div>
        {
            (children && open) && <div className='sidertoptitle-container'>
                {children}
            </div>
        }
    </div>
}

export default SiderTopTitle