import { Tooltip } from 'antd'
import React, { Fragment, useState, useEffect } from 'react'
import SvgIcon from '../../SvgIcon'
import './index.less'

export type TreeProps = {
  theme?: 'dark' | 'light'
  treeData?: Array<any>
  [other: string]: any
}

const Tree: React.FC<TreeProps> = (props) => {

  const {
    theme = 'dark',
    treeData = []
  } = props

  // 折叠的index
  const [foldKeys, setFoldKeys] = useState<number[]>([])

  // 目录数据
  const [data, setData] = useState<any>([])

  // 标题1-6
  const [depths, setDepths] = useState<number[]>([])

  // 判断是否出现省略号，出现显示tooltip
  const onMouseEnter = (e: any, index: number) => {
    const target = e?.target
    if (target?.scrollWidth > target?.clientWidth) {
      data[index].showTooltip = true
      setData([...data])
    }
  }

  // 不显示tootip
  const onMouseLeave = (_: any, index: number) => {
    data[index].showTooltip = false
    setData([...data])
  }

  // 选中
  const onSelect = (index: number) => {
    foldKeys.includes(index) ? foldKeys.splice(foldKeys.indexOf(index), 1) : foldKeys.push(index)
    setFoldKeys([...foldKeys])
  }

  // 寻找type为text的标签
  const findText: any = (arr: any) => {
    const obj = arr[0]
    if (obj?.type === 'text') {
      return obj?.value
    } else if (obj?.children?.length) {
      return findText(obj?.children)
    }
  }

  useEffect(() => {
    treeData?.forEach((v: any) => v.hidden = false)
    if (foldKeys?.length) {
      foldKeys?.forEach((_index: any) => {
        const depth = treeData[_index]?.depth
        for (let index = _index + 1; index < treeData?.length; index++) {
          const element = treeData[index];
          if (element?.depth > depth) {
            element.hidden = true
          } else {
            break;
          }
        }
      })
    }
    setData([...treeData])
  }, [treeData, foldKeys])

  // 有几种标题，用于控制左边距
  useEffect(() => {
    let _depths = treeData?.map((v: any) => v?.depth)
    // 去重
    _depths = _depths?.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    _depths?.sort((a, b) => a - b)
    setDepths(_depths)
  }, [treeData])

  return (
    <div className={`blog-tree ${theme === 'dark' ? 'dark-tree' : 'light-tree'}`}>
      {
        data?.map((v: any, index: number) => (
          <Fragment key={`${index + v?.children[0]?.value}`}>
            {
              !v?.hidden ? (
                <div className={`indent-level-${depths?.indexOf(v?.depth) + 1}`} onClick={() => onSelect(index)}>
                  <span className='tree-arrow'>
                    {
                      v?.depth < data[index + 1]?.depth ? <SvgIcon iconClass='down' fontSize='14px' style={foldKeys?.includes(index) ? { transform: 'rotate(-90deg)' } : {}} /> : <></>
                    }
                  </span>
                  <Tooltip open={v?.showTooltip ? true : false} key={`tree-text-${index}`} placement="bottomLeft" arrow={false} title={findText(v?.children)} overlayClassName='item-tooltip'>
                    <span className='tree-text' onMouseEnter={(e: any) => onMouseEnter(e, index)} onMouseLeave={(e: any) => onMouseLeave(e, index)}>{findText(v?.children)}</span>
                  </Tooltip>
                </div>
              ) : <></>
            }
          </Fragment>
        ))
      }
    </div>
  )
}

export default Tree
