import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'antd'
import './index.less'
import SvgIcon from '../../../../components/SvgIcon'

type SuccessModalProps = {
  open?: boolean
  result?: any //运行结果
  onCancel?: () => void
  onOk?: () => void
  [other: string]: any
}

const SuccessModal: React.FC<SuccessModalProps> = (props) => {
  const { open, result, onCancel, onOk } = props

  const [loading, setLoading] = useState(false)

  // 初始化按钮加载状态
  useEffect(() => {
    if (open) {
      setLoading(false)
    }
  }, [open])

  // 是否显示展开按钮
  const [show, setShow] = useState(false)
  // 展开还是收起
  const [hover, setHover] = useState(false)
  const dom = useRef<any>(null)

    useEffect(() => {
      if (open) {
        setTimeout(() => {
          const el = dom.current
          if (el && el?.scrollWidth > el?.clientWidth) {
            setShow(true)
            setHover(false)
          } else {
            setShow(false)
            setHover(false)
          }
        }, 100);
      }
    }, [open])

  return (
    <Modal
      wrapClassName="result-modal"
      open={open}
      width={700}
      closable={false}
      centered={true}
      footer={[
        <Button key='cancel' onClick={onCancel}>
          重新选择判分
        </Button>,
        <Button key='save' type="primary" loading={loading} onClick={() => {
          setLoading(true)
          onOk?.()
        }}>
          确认并保存
        </Button>
      ]}
    >
      <div className='result-modal-content'>
        <div className='result-modal-content-title'>
          <SvgIcon iconClass='success' fontSize='22' />
          <span>判分成功！</span>
        </div>
        <div className='content'>
          <span>判分内容</span>
          <span ref={dom} className={hover ? '' : 'hidden'}>{result?.content}</span>
          {
            show && <span onClick={() => {setHover(!hover)}}>{hover ? '收起' : '展开'}</span>
          }
        </div>
        <div className='score'>
          <span>得分</span>
          <span>{result?.score}</span>
        </div>
      </div>
    </Modal>
  )
}

export default SuccessModal;