import React, { useEffect, useState } from 'react';
import './index.less';
import SvgIcon from '../../../../../components/SvgIcon'
import { Button, Form, Input, Modal, message } from 'antd';
import { courseTypes } from '../../../../../utils/commonTypes'
import { blogCrawlGitbook, blogVerifyGit, crawlGitbook, gitbookStatus, verifyGit } from '../../../../../api/modules/community';



type EnlargeProps = {
  ifTrue: boolean,
  courseCode: string
  courseType: string
  changeValue?: () => void;
}

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const ImportModal: React.FC<EnlargeProps> = (props) => {
  const { ifTrue, courseCode, courseType, changeValue } = props

  // 注释
  const [mdShow, setMdShow] = useState<boolean>(false);

  //是否处于提交
  const [ifSubmit, setIfSubmit] = useState(false)

  //是否处于导入状态
  const [importLoding, setImportLoding] = useState(false);

  //是否处于导入校验中
  const [ifLoding, setIfLoding] = useState(false);

  const [form] = Form.useForm();

  const [fields, setFields] = useState<FieldData[]>(
    [
      { name: ['gitBookMd'], value: '' },
    ]);

  const onFinish = (values: any) => {


    if (values.gitBookMd) {
      if (courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE) {
        const httpsBlobRegex = /^https:\/\/.*?(blob).*$/;
        if (values.gitBookMd.match(httpsBlobRegex) !== null) {
          changeValue?.()
          window.postMessage({
            type: 'import',
            value: true
          })
          blogCrawlGitbook({
            code: courseCode,
            gitBookMd: values.gitBookMd
          }).then(res => {
            message.warning('导入成功！')
            window.postMessage({
              type: 'import',
              value: false
            })
          }).catch(err => {
            message.warning('导入失败！')
            window.postMessage({
              type: 'import',
              value: false
            })
          })
        } else {
          form.setFields([{
            name: 'gitBookMd',
            errors: ['请输入正确文档地址']
          }])
        }

      } else {
        verifyGit({
          gitBookMd: values.gitBookMd
        }).then(res => {
          changeValue?.()
          window.postMessage({
            type: 'import',
            value: true
          })
          crawlGitbook({
            code: courseCode,
            gitBookMd: values.gitBookMd
          }).then(res => {
            message.warning('导入成功！')
            window.postMessage({
              type: 'import',
              value: false
            })
          }).catch(err => {
            message.warning('导入失败！')
            window.postMessage({
              type: 'import',
              value: false
            })
          })
        }).catch(err => {
          form.setFields([{
            name: 'gitBookMd',
            errors: ['请输入正确文档地址']
          }])
        })
      }


    } else {
      form.setFields([{
        name: 'gitBookMd',
        errors: ['请输入文档地址']
      }])
    }
  };

  const handGit = async () => {
    if (!ifSubmit) return
    if (fields[0].value) {
      //区分博客 或者课程的校验
      // if (courseType === courseTypes.BLOG) {
      //   blogVerifyGit({
      //     gitBookMd: fields[0].value
      //   }).then(res => {

      //   }).catch(err => {
      //     form.setFields([{
      //       name: 'gitBookMd',
      //       errors: ['请输入正确文档地址']
      //     }])
      //   })
      // } else {
      //   verifyGit({
      //     gitBookMd: fields[0].value
      //   }).then(res => {

      //   }).catch(err => {
      //     form.setFields([{
      //       name: 'gitBookMd',
      //       errors: ['请输入正确文档地址']
      //     }])
      //   })
      // }
    } else {
      form.setFields([{
        name: 'gitBookMd',
        errors: ['请输入文档地址']
      }])
    }
  }

  // 每次进入清空
  useEffect(() => {
    if (ifTrue) {
      setFields([
        { name: ['gitBookMd'], value: '' },
      ])
      if (courseType === courseTypes.COURSE) {
        gitbookStatus({
          code: courseCode
        }).then(res => {
          if (res.isCrawl) {
            message.warning('正在导入中！')
            changeValue?.()
          } else {
            setImportLoding(ifTrue)
          }

        })
      } else {
        setImportLoding(ifTrue)
      }
    } else {
      setImportLoding(ifTrue)
    }

  }, [ifTrue])


  return (
    <Modal wrapClassName={'importModal'} open={importLoding} footer={''} width={'530px'} destroyOnClose centered>
      <div className='modalAll'>
        <div className='modalAll-title'>导入md文档</div>
        <div style={{ width: '100%', marginTop: '30px', paddingRight: '10px' }}>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{}}
            fields={fields}
            onFieldsChange={(_, allFields) => {

              setFields(allFields);
            }}
          >

            <Form.Item label='导入md文档' style={{ paddingBottom: '15px' }}>
              <Form.Item
                name='gitBookMd'
                rules={[
                  { validator: handGit },
                ]}
                noStyle
              >
                <Input placeholder="请输入md文档的GitHub地址" autoComplete="off" allowClear onFocus={() => {
                  setIfSubmit(false)
                }}
                />
              </Form.Item>
              <div className='gitBookMdIcon' onClick={() => {
                setMdShow(true)
              }}> <SvgIcon iconClass={'helpIcon'} style={{ width: '16px', height: '16px' }} /></div>
            </Form.Item>


            <Form.Item noStyle>
              <div style={{ marginTop: '10px', textAlign: 'center' }}>
                <Button className="bottonLeft" onClick={() => {
                  changeValue?.()
                }} >
                  取消
                </Button>
                <Button type="primary" htmlType="submit" className="bottonRigth" onClick={() => {
                  setIfSubmit(true)
                }}>
                  {'导入'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Modal wrapClassName={'tishi'} open={mdShow} footer={''} width={636} style={{ top: "35%", }}>
        <div style={{ position: 'relative' }}>
          <div className='closrIcon' onClick={() => {
            setMdShow(false)
          }}> <SvgIcon iconClass={'close'} style={{ width: '20px', height: '20px' }} /></div>
          <div className="modal-course-content-tip-titel">md文档导入说明</div>
          {
            courseType === courseTypes.BLOG || courseType === courseTypes.EXPERIENCE ? <div className="modal-course-content-tip-con">
              <div>1.支持GitHub/Gitee上的md文档导入</div>
              <div>2.导入后，会覆盖之前的内容，请谨慎操作</div>
              <div>3.示例地址</div>
              <div style={{ paddingLeft: '13px' }}>
                <div>https://github.com/kcl-lang/kcl-lang.io/blob/main/i18n/zh-CN/docusaurus-plugin-content-blog/2023-09-08-dcm-using-kcl/index.md</div>
                <div>https://gitee.com/kusionstack/kcl-lang.io/blob/main/blog/2023-03-17-kcl-playground-0.4.5-release/index.md</div>
              </div>
            </div> : <div className="modal-course-content-tip-con">
              <div>1.支持mdbook 导入转化到课程中。</div>
              <div>2.导入地址要求：需要是内容的入口GitHub地址，私有项目文档不可导入。</div>
              <div>3.地址示例</div>
              <div style={{ paddingLeft: '13px' }}>
                <div>https://github.com/walter201230/Python/blob/master/README.md</div>
                <div>https://github.com/walter201230/ReadingAndThinking/blob/master/SUMMARY.md</div>
                <div>https://github.com/walter201230/ReadingAndThinking/blob/master/README.md</div>
              </div>
            </div>
          }
        </div>
      </Modal>
    </Modal>
  );
}

export default ImportModal;