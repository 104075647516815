import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import './index.less'
import { IDomEditor, IEditorConfig, IToolbarConfig, Boot } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import '@wangeditor/editor/dist/css/style.css'
import markdownModule from '@wangeditor/plugin-md'
import { uploadImg } from '../../api/modules/community'

Boot.registerModule(markdownModule)

type MiniRichEditorProps = {
    value?: string                    // 值  默认为空
    readOnly?: boolean                // 只读
    border?: boolean                  // 需要边框
    height?: number | string
    placeholder?: string

    onChange?: (value: string) => void
    ref?: any
}

let MiniRichEditor: React.FC<MiniRichEditorProps> = (props, ref) => {

    const { value = '', readOnly = false, border = true, height = 300, placeholder = '请输入内容...', onChange } = props

    const [html, setHtml] = useState(value)
    // 检验状态控制 true  检验失败  false 没有任何变化
    const [status, setStatus] = useState(false)

    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null)

    // 工具栏配置
    const [toolbarConfig, setToolbarConfig] = useState<Partial<IToolbarConfig>>({
        toolbarKeys: [
            "undo",
            "redo",
            "fullScreen",
            "|",
            "headerSelect",
            "bold",
            "underline",
            "italic",
            "color",
            "bgColor",
            // "|",
            // "fontSize",
            // "fontFamily",
            // "lineHeight",
            "|",
            "bulletedList",
            "numberedList",
            // "|",
            {
                key: "group-justify",
                title: "对齐",
                iconSvg: `<svg viewBox="0 0 1024 1024"><path d="M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path></svg>`,
                menuKeys: [
                    "justifyLeft",
                    "justifyRight",
                    "justifyCenter",
                    "justifyJustify"
                ]
            },
            {
                "key": "group-indent",
                "title": "缩进",
                "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z\"></path></svg>",
                "menuKeys": [
                    "indent",
                    "delIndent"
                ]
            },
            "insertLink",
            {
                "key": "group-image",
                "title": "图片",
                "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z\"></path></svg>",
                "menuKeys": [
                    "insertImage",
                    "uploadImage"
                ]
            },
            "insertTable",
            "codeBlock",
            "divider",
            "|",

        ]
    })

    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: placeholder,
        autoFocus: false,
        MENU_CONF: {
            uploadImage: {
                async customUpload(file: File, insertFn: any) {
                    const formData = new FormData()
                    formData.append('file', file)
                    const res = await uploadImg({ file: formData })
                    if (res?.url) {
                        insertFn(res?.url, res?.url, res?.url)
                    }
                }
            }
        }
    }

    // 退出销毁实例   wangeditor销毁有问题，做一个延迟处理
    useEffect(() => {
        return () => {
            if (editor == null) return
            setTimeout(() => {
                editor?.destroy()
                setEditor(null)
            }, 500);
        }
    }, [])

    // 当前编辑器形态
    useEffect(() => {
        if (readOnly) {
            editor?.disable()
        } else {
            editor?.enable()
        }
    }, [readOnly, editor])

    useEffect(() => {
        setHtml(value)
    }, [value])

    useImperativeHandle(ref, () => ({
        editor,
        // 获取内容
        getHtml: () => {
            if (editor?.isEmpty()) {
                return ''
            } else {
                const text = editor?.getHtml() ?? ''
                const res = replace_p_nbsp_br(text)
                if (/\S+/.test(res)) {
                    return text
                } else {
                    return ''
                }
            }
        },
        // 校验  只能校验有没有值  校验失败边框会变红(仅在有边框时会生效)
        validateField: (): { success: boolean, value: string } => {
            const res = {
                success: false,
                value: html
            }
            if (html) {
                res.success = true
                setStatus(false)
            } else {
                setStatus(true)
            }
            return res
        }
    }), [editor, html])

    // 当校验不通过时监听校验通过
    useEffect(() => {
        if (status) {
            if (html) {
                setStatus(false)
            } else {
                setStatus(true)
            }
        }
    }, [status, html])

    // 替换字符串中p标签空白字符等
    const replace_p_nbsp_br = useCallback((str: string = '') => {
        return str.replace(/<\/?p>/g, '')
            .replace(/&nbsp;/gi, '')
            .replace(/<br\/?>/g, '')
    }, [])

    return (
        <div style={{ border: border ? status ? '1px solid #ff4d4f' : '1px solid #E5E5E5' : undefined, zIndex: 100 }} className='mini-richeditor'>
            {
                !readOnly && <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #E5E5E5' }}
                />
            }
            <Editor
                defaultConfig={editorConfig}
                value={html}
                onCreated={setEditor}
                onChange={(editor: IDomEditor) => {
                    if (!editor?.isEmpty()) {
                        const html = editor?.getHtml() ?? ''
                        const res = replace_p_nbsp_br(html)
                        if (/\S+/.test(res)) {
                            onChange?.(html)
                            setHtml(html)
                        } else {
                            onChange?.('')
                            setHtml('')
                        }
                    } else {
                        setHtml('')
                    }
                }}
                mode="default"
                style={{ height: typeof height === 'number' ? height + 'px' : height, overflowY: 'hidden' }}
            />
        </div>
    )
}

MiniRichEditor = forwardRef(MiniRichEditor as any)

export default MiniRichEditor