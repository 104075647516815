import React, { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useRef } from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { TreeProps } from '../../types'
import Tree from '../Tree'

let ProviderDnd: React.FC<TreeProps> = (props, ref) => {

    const treeRef = useRef(null)

    useImperativeHandle(ref, () => ({
        // 所有数据
        data: (treeRef?.current as any)?.data,
        // 当前tab
        resourceTab: (treeRef?.current as any)?.resourceTab,
        // 当前选中
        selected: (treeRef?.current as any)?.selected,
        // 刷新数据  废弃 外面刷新就行
        // refresh: (treeRef?.current as any)?.refresh,
        //上传文件时显示加载状态
        uploadFileChange: (treeRef?.current as any)?.uploadFileChange
    }))

    return (
        <DndProvider backend={HTML5Backend}>
            <Tree {...props} ref={treeRef} />
        </DndProvider>
    )
}

ProviderDnd = forwardRef(ProviderDnd as ForwardRefRenderFunction<any, TreeProps>)

export default ProviderDnd