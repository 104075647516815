import JSZip from 'jszip';
import { getFssResource } from '../../../api/modules/fs'

/*
* 文件夹下载
* 文件夹目录
* params：文件内容接口请求参数
* zip: 是否通过zip下载
*/
export const downloadFolder = async (data: any, params: any, zip: boolean, directoryHandle?: any) => {
  if (zip) {
    createZip(data, params)
  } else {
    // exclusive: false 允许如果目录已存在则不解析错误 
    const options = { create: true, exclusive: false };
    const newFolderHandle = await directoryHandle.getDirectoryHandle(params?.title, options);
    const arr = dataToTree(data)
    const newArr = await createMultiLevelDirectory(newFolderHandle, arr)
    await limitedDownload(flattenArray(newArr), params)
    return true
  }
}

// 创建文件夹
const createMultiLevelDirectory = async (handle: any, data: any) => {
  for await (const iterator of data) {
    if (iterator?.isFolder) {
      const currentHandle = await handle.getDirectoryHandle(iterator?.title, { create: true })
      if (iterator?.children?.length) {
        iterator.children = await createMultiLevelDirectory(currentHandle, iterator?.children)
      }
    } else {
      iterator.handle = handle
    }
  }
  return data
}

// 限制下载文件的并发数
const limitedDownload = (files: any, params: any) => {
  return new Promise((resolve, reject) => {
    const totalFiles = files?.length; // 总文件数量
    const queue: any = [...files]; // 使用队列来管理待上传的文件
    const limit = 50; //限制最大并发数
    let running = 0;

    // 从队列中取出文件并开始下载
    const next = async () => {
      if (queue?.length === 0 || running === limit) {
        return;
      }
      running++;
      const file = queue.shift();

      try {
        const content = await getFssResource({
          ...params,
          file: `${params?.file}/${file?.path}`
        })
        createFileInDirectory(file?.handle, file?.title, content).finally(() => {
          running--;
          if (queue?.length > 0) {
            next();
          } else if (running === 0) {
            resolve(true)
          }
        })
      } catch (error) {
        running--;
        if (queue?.length > 0) {
          next();
        } else if (running === 0) {
          resolve(true)
        }
      }
    }

    // 开始下载文件
    for (let i = 0; i < Math.min(limit, totalFiles); i++) {
      next();
    }
  })
}

// 在目录中创建文件
const createFileInDirectory = async (directoryHandle: any, fileName: any, content: any) => {
  try {
    const fileHandle = await directoryHandle.getFileHandle(fileName, { create: true });
    const writable = await fileHandle.createWritable();
    await writable.write(content);
    await writable.close();
    return true
  } catch (err) {
    console.error(`Error creating file: ${err}`);
    return false
  }
}

// 创建zip
const createZip = (data: any, params: any) => {
  const arr = dataToTree(data)
  const zip = new JSZip();
  const folder = zip.folder(params?.title)
  const newArr = createMultiLevelZip(folder, arr)
  const flattenArr = flattenArray(newArr)
  limitedDownloadZip(flattenArr, params).then(() => {
    zip.generateAsync({ type: 'blob' }).then((content: any) => {
      let a = document.createElement('a');
      a.download = `${params?.title}.zip`;
      a.href = URL.createObjectURL(content);
      a.click();
      URL.revokeObjectURL(content);
    })
  })
  // downloadAsZip(arr, folder, params).then(() => {
  //   zip.generateAsync({ type: 'blob' }).then((content: any) => {
  //     let a = document.createElement('a');
  //     a.download = `${params?.title}.zip`;
  //     a.href = URL.createObjectURL(content);
  //     a.click();
  //     URL.revokeObjectURL(content);
  //   })
  // })
}

// 创建zip多层级的文件夹
const createMultiLevelZip = (zip: any, data: any) => {
  for (const iterator of data) {
    if (iterator?.isFolder) {
      const newZip = zip.folder(iterator?.title);
      if (iterator?.children?.length) {
        iterator.children = createMultiLevelZip(newZip, iterator?.children)
      }
    } else {
      iterator.zip = zip
    }
  }
  return data
}

// 限制读取文件并发数
const limitedDownloadZip = (files: any, params: any) => {
  return new Promise((resolve, reject) => {
    const totalFiles = files?.length; // 总文件数量
    const queue: any = [...files]; // 使用队列来管理待上传的文件
    const limit = 50; //限制最大并发数
    let running = 0;

    // 从队列中取出文件并开始下载
    const next = async () => {
      if (queue?.length === 0 || running === limit) {
        return;
      }
      running++;
      const file = queue.shift();

      getFssResource({
        ...params,
        file: `${params?.file}/${file?.path}`
      })
        .then(res => {
          file?.zip?.file(file?.title, res, { binary: true })
        })
        .catch(() => { })
        .finally(() => {
          running--;
          if (queue?.length > 0) {
            next();
          } else if (running === 0) {
            resolve(true)
          }
        })
    }

    // 开始下载文件
    for (let i = 0; i < Math.min(limit, totalFiles); i++) {
      next();
    }
  })
}

// 遍历文件创建zip(适用于文件数量少的)
// const downloadAsZip = (data: any, zip: any, params: any) => {
//   const promises = data.map((v: any) => {
//     if (v.isFolder) {
//       const newFold = zip.folder(v.title);
//       return downloadAsZip(v?.children, newFold, params)
//     } else {
//       return getFssResource({
//         ...params,
//         file: `${params?.file}/${v?.path}`
//       }).then(res => {
//         zip.file(v?.title, res, { binary: true })
//       }).catch(() => { })
//     }
//   })
//   return Promise.all(promises);
// }

// 数组转换为树型结构
const dataToTree = (data: any) => {
  const map: any = {};
  data?.forEach((i: any) => {
    let v = i
    if (v?.isFolder) {
      v.children = []
    }
    map[v?.path] = v;
    const parent = v?.parent;
    if (parent && !map[parent]?.children?.includes(v)) {
      map[parent].children.push(v);
    }
  })
  return Object.values(map).filter((v: any) => !v?.parent);
}

// 扁平化数组
const flattenArray = (data: any) => {
  const arr: any = [];
  const traverse = (items: any) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item?.children?.length) {
        traverse(item.children);
      } else {
        arr.push(item);
      }
    }
  }
  traverse(data);
  return arr;
}