import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import { Button, Modal } from 'antd'
import PortItem from '../../../components/PortItem'
import AddTools from '../../../components/AddTools'
import SvgIcon from '../../../components/SvgIcon'
import {getQueryStringValue} from "../../../utils/index";

interface ManageProps {
    open: boolean,              // 是否显示
    onCancel: () => void        // 关闭显示
    getList: () => Promise<any> // 获取列表
    modal: string               // 模式
    finish?: (data: any, defaultOpen: number) => void// 保存
    currentChapter?: any,        // 当前小节的所有信息
    setCurrentDefault: (port : number)=>void, //设置默认打开工具端口
    currentDefault: number       //默认打开工具端口
    section:any
}

enum UserType {
    SYSTEM = 'system',         // 系统
    CREATOR = 'creator',       // 创作者
    LEARNER = 'learner'        // 学习者
} 

const Manage: React.FC<ManageProps> = (props) => {

    //Pod.yaml路径
    const location = getQueryStringValue("location")


    const { setCurrentDefault,currentDefault, open, onCancel, getList, modal, finish, currentChapter } = props

    const [showAddTools, setShowAddTools] = useState(false)      // 显示添加工具

    const [ports, setPorts] = useState<any>([])                  // 端口工具列表
    const [portCreatorNum, setPortCreatorNum] = useState(0)      // 当前用户自定义的端口工具

    // 计算数量
    useEffect(() => {
        const res = ports?.filter((item: any) => modal === '1' ? item?.type === UserType.CREATOR : item?.type === UserType.LEARNER) || []
        setPortCreatorNum(res?.length)
    }, [ports])

    // 保存
    const save = () => {
        finish?.(ports, currentDefault)
    }

    // 请求列表
    const fetchList = async () => {
        //预览博客
        if (location){
            setPorts(props.section?.template?.ports)
            return
        }

        const res = await getList?.()
        if (!res?.some((item: any) => item?.port === currentDefault)) {
            setCurrentDefault(currentDefault===0 ? 0 : -1)
        }
        setPorts(res)
    }

    // 选择端口工具
    const choicePort = (port: number, check: boolean) => {
        const res = ports?.map((item: any) => {
            if (port == item.port) {
                return { ...item, choice: check }
            }
            return { ...item }
        })
        setPorts(res)
    }

    // 删除 port
    const delPort = (port: number) => {
        const res = ports?.filter((item: any) => {
            return item.port !== port
        })
        setPorts(res)
    }

    // 编辑端口
    const editorPort = (data: any) => {
        const res = ports?.map((item: any) => {
            if (item?.port === data?.oldPort) {
                return {
                    ...item,
                    name: data?.name,
                    port: parseInt(data?.port),
                    image: data?.image
                }
            }
            return {...item}
        })
        setPorts(res)
    }

    // 改变当前默认的选中
    const changeCurrentDefault = (port: number, check: boolean) => {
        if (check) {
            setCurrentDefault(port)
        }else{
            setCurrentDefault(-1)
        }
    }

    // 初次加载数据
    useEffect(() => {
        if (open) {
            fetchList()
        } else {
            setPorts([])
        }
    }, [open])

    return <Modal
        open={open}
        wrapClassName={'ylzcc-manage-modal'}
        onCancel={onCancel}
        footer={false}
        closable={false}
        centered={true}
        width={492}
        maskClosable={false}
        destroyOnClose={true}
    >
        <p className='manage-title'>实验工具管理</p>
        {
            // ports?.length ? 
            <>
                <div className='manage-listtitle'>
                    <span>图标</span>
                    <span style={{marginLeft: '52px'}}>工具名称</span>
                    <span style={{marginLeft: '97px'}}>操作</span>
                    <span style={{marginLeft: '41px'}}>是否{modal === '1' ? '启动' : '可用'}</span>
                    <span style={{marginLeft: '10px'}}>默认打开</span>
                </div>
                <div className='manage-list'>
                    {
                        <PortItem 
                            image={require('../../../assets/images/toolsImg/shell.png')} 
                            name={'shell'} 
                            port={0}
                            checked={true}
                            edited={false}
                            defaultOpen={true}
                            modal={modal === '1' ? true : false}
                            setCurrentDefault={setCurrentDefault}
                            currentDefault={currentDefault}
                            defaultCheck={currentDefault === 0}
                            defaultOpenFun={(check: boolean) => { changeCurrentDefault(0, check)}}
                        />
                    }
                    {
                        ports?.map((item: any) => {
                            return <PortItem 
                                key={item?.id} 
                                image={item?.image} 
                                name={item?.name} 
                                port={item?.port}
                                setCurrentDefault={setCurrentDefault}
                                currentDefault={currentDefault}
                                checked={item?.choice && (item.isUse || item.isUse === undefined) }
                                changeChecked={(check: boolean) => { choicePort(item?.port, check)}}
                                edited={modal === '1' ? item?.type === UserType.CREATOR : item?.type === UserType.LEARNER}
                                del={modal === '1' ? (
                                    item?.type === UserType.CREATOR ? () => {delPort(item?.port)} : null
                                ) : (
                                    item?.type === UserType.LEARNER ? () => {delPort(item?.port)} : null
                                )}
                                editor={modal === '1' ? (
                                    item?.type === UserType.CREATOR ? editorPort : null
                                ) : (
                                    item?.type === UserType.LEARNER ? editorPort : null
                                )}
                                ports={ports}
                                modal={modal === '1' ? true : false}
                                defaultOpen={true}
                                defaultCheck={item?.port === currentDefault}
                                defaultOpenFun={(check: boolean) => { changeCurrentDefault(item?.port, check)}}
                            />
                        })
                    }
                </div>
                <span className='manage-add' onClick={() => {
                    if (portCreatorNum < 10) {
                        setShowAddTools(true)
                    }
                }} style={{color: portCreatorNum < 10 ? '#1677ff' : '#aeaeae', pointerEvents: portCreatorNum < 10 ? 'auto' : 'none', cursor: portCreatorNum < 10 ? 'pointer' : 'default'}}>
                    <SvgIcon iconClass={'addnocolor'} style={{ width: '16px', height: '16px' }} fill='inherit'/>
                    <span>添加工具</span>
                </span>
                
                <div className='manage-footer'>
                    <Button style={{minWidth: '88px'}} htmlType='button' onClick={onCancel}>取消</Button>
                    <Button type='primary' htmlType='button' style={{minWidth: '88px'}} onClick={save}>保存</Button>
                </div>
            </>
            //  : <div className='manage-nodata'>
            //     <SvgIcon iconClass={'nodata'} style={{width: '80px', height: '80px', marginBottom: '20px'}} />
            //     <span className='manage-nodata-tip'>请先添加自定义工具吧~</span>
            //     <Button type='primary' htmlType='button' onClick={() => {
            //         if (portCreatorNum < 10) {
            //             setShowAddTools(true)
            //         }
            //     }}>添加工具</Button>
            // </div>
        }
        {
            !ports?.length ? <SvgIcon iconClass={'close'} style={{width: '16', height: '16px'}} className='manage-close' fill='inherit' onClick={() => {onCancel?.()}}/> : <></>
        }
        <AddTools open={showAddTools} onCancel={() => {setShowAddTools(false)}} ports={ports} finish={(data: any) => {
            const port = {
                id: data?.port,
                name: data.name,
                port: parseInt(data.port),
                image: data.image,
                choice: true,
                isUse:true,
                type: modal === '1' ? UserType.CREATOR : UserType.LEARNER
            }
            setPorts([...ports, port])
        }} />
    </Modal>
}

export default Manage