import React, { useContext, useEffect, useState } from 'react'
import './index.less'
import { Form, FormInstance, message } from 'antd'
import KnowledgeLabel from '../KnowledgeLabel'
import KnowledgeDrawer from '../KnowledgeDrawer'
import { courseTypes } from '@/utils/commonTypes'
import SharedDataContext from '@/utils/share'

type KnowledgeFormItemProps = {
    form?: FormInstance
    chapterId: string             // 小节id
    courseCode: string            // 课程code
}

const KnowledgeFormItem: React.FC<KnowledgeFormItemProps> = (props) => {

    const { form, chapterId, courseCode } = props

    const { courseType } = useContext(SharedDataContext)

    const knowledgeIds: any[] | undefined = Form.useWatch('knowledgeIds', form)
    const [add, setAdd] = useState(false)    // 添加弹框

    // 设置表单当前字段
    const setField = (data: any[]) => {
        form?.setFieldValue('knowledgeIds', data)
    }

    // 取消该知识点
    const cancel = (index: number) => {
        const arr = knowledgeIds!.slice()
        arr.splice(index, 1)
        setField(arr)
    }

    const finish = async (data: any[]) => {
        setField(data ?? [])
    }

    if (courseType !== courseTypes.COURSE) {
        return null
    }

    return (
        <>
            <Form.Item
                label="关联知识点"
                name='knowledgeIds'
            >
                <div className='knowledge-form-item'>
                    {
                        knowledgeIds?.map((item: any, index: number) => {
                            return <KnowledgeLabel key={item.knowledgeId} title={item.name} cancel={() => {cancel(index)}} />
                        })
                    }
                    <button type='button' className='knowledge-form-item-add' onClick={() => { 
                        if (knowledgeIds && knowledgeIds.length >= 10) {
                            message.destroy()
                            message.warning('至多可关联10个知识点')
                            return
                        }
                        setAdd(true)
                     }}>+添加</button>
                </div>
            </Form.Item>

            <KnowledgeDrawer open={add} courseCode={courseCode} onClose={() => { setAdd(false) }} finish={finish} value={knowledgeIds}/>
        </>
    )
}

export default KnowledgeFormItem