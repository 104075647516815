import React, { useContext, useEffect, useRef, useState } from 'react'
import './index.less'
import TopNav from '../TopNav'
import SvgIcon from '../../../components/SvgIcon'
import ListContainer from '../ListContainer'
import DetailQuesItem from '../DetailQuesItem'
import { Button, Dropdown, Modal, Tooltip, message } from 'antd'
import RichEditor from '../../../components/RichEditor'
import SharedDataContext from '../../../utils/share'
import { delQues, getDetail, getDetailRecover, getUrlConfig, setDetailRecover, syncIssue, syncIssueStatus } from '../../../api/modules/community'
import { courseTypes } from '../../../utils/commonTypes'

type DetailQuesProps = {
    id: string       // 详情id
    back: () => void // 返回
    onClose: () => void   // 关闭显示 
}

const DetailQues: React.FC<DetailQuesProps> = (props) => {

    const { id, back, onClose } = props

    // 获取共享数据
    const { section, labCode, couseCode, userInfo, labDetail, courseType } = useContext(SharedDataContext)

    const ListContainerRef = useRef<any>(null)

    const [detail, setDetail] = useState<any>({})     // 问答详情
    const [pageSize, setPageSize] = useState(20)      // 每页条数
    const [hasMore, setHasMore] = useState(true)      // 是否有下一页
    const [data, setData] = useState<any>([])         // 列表数据
    const [empty, setEmpty] = useState(false)         // 是否列表为空
    const [total, setTotal] = useState()              // 评论数
    const [editorValue, setEditorValue] = useState('')// 编辑器内容

    const [recover, setRecover] = useState(false)  //是否显示回复评论

    // 获取详情
    const fetchDetail = async () => {
        const res = await getDetail(labCode, couseCode, id)
        setDetail(res)
    }

    // 获取列表
    const fetchList = async (clear: boolean = false) => {
        const res = await getDetailRecover({
            current: clear ? 1 : Math.ceil(data?.length / pageSize) + 1,
            size: pageSize,
            id
        })
        setHasMore(res?.hasNext ?? false)
        setEmpty(!res?.total)
        setTotal(res.total)
        if (clear) {
            setData(res?.rows ?? [])
        } else {
            setData([...data, res?.rows])
        }
    }

    // 相关数据初始化
    useEffect(() => {
        fetchDetail()
        fetchList(true)
    }, [id])

    // 回复接口请求中
    const tamp = useRef<boolean>(false)

    // 回复评论
    const subRecover = async () => {
        if (tamp.current) return
        if (!editorValue) {
            message.destroy()
            message.warning('请输入评论内容')
            return
        }
        try {
            tamp.current = true
            await setDetailRecover({
                labCode,
                code: couseCode,
                id,
                content: editorValue
            }).finally(() => {
                tamp.current = false
            })
            message.destroy()
            message.success('回复成功')
            fetchList(true)
            setRecover(false)
            setTimeout(() => {
                const dom = document.getElementById('detailques-con-ques-id')
                ListContainerRef?.current?.scrollToTop?.(dom?.offsetHeight ? dom?.offsetHeight + 16  : 0)
            }, 100);
            // ListContainerRef?.current?.scrollToTop?.()
        } catch (error: any) {
            tamp.current = false
            if (error?.status === 404) {
                Modal.warning({
                    title: '温馨提示',
                    content: <p >噢噢!该问答已删除，去看看其他待解决的问答吧~</p>,
                    okText: '我知道了',
                    centered: true,
                    onOk() {
                        back?.()
                    }
                })
                return
            }
            message.destroy()
            message.warning(error?.message ?? '回复失败')
        }
    }

    // 删除问答
    const deleteQues = async () => {
        Modal.confirm({
            title: "确认删除？",
            content: '删除后，内容将无法恢复，请谨慎操作。',
            cancelText: '取消',
            okText: '确定',
            centered: true,
            width: 400,
            onOk() {
               delQues({
                    labCode,
                    code: couseCode,
                    id
                }).then(() => {
                    back?.()
                    message.destroy()
                    message.success('删除成功')
                })
            },
            onCancel() {}
        })
        
    }

    // 同步问答
    const syncQues = async () => {

        // 校验实验室是否已经绑定
        if (!labDetail?.url || !labDetail?.token) {
            message.destroy()
            message.warning('当前实验室未激活issue，无法同步')
            return
        }
        // // 校验当前用户是否绑定
        // if (!userGithub?.unionId || !userGithub?.token) {
        //     Modal.confirm({
        //         title: '暂无权限',
        //         content: '暂时无法操作，请先绑定GitHub账号并设置访问令牌~',
        //         okText: '去设置  ',
        //         centered: true,
        //         width: 400,
        //         cancelText: '我再想想',
        //         onOk() {
        //             if (comUrl) {
        //                 window.open(comUrl + 'user/github')
        //             }
        //         },
        //         onCancel() {}
        //     })
        //     return
        // }
        // 获取课程分享的url域名
        const { value } = await getUrlConfig().catch(() => { })
        // 对地址进一步理
        if (!value) return
        const url = new URL(value)
        const cooperateUrl = url.href.replace(url.host, `${labCode}.${url.host}`) + `os/${couseCode}`
        try {
            const res = await syncIssue({
                labCode,
                code: couseCode,
                id,
                cooperateUrl: cooperateUrl
            })
            issueStatus(id)                                    
            message.destroy()
            message.success('同步成功')
        } catch (error: any) {
            message.destroy()
            message.warning(error?.message ?? "同步失败")
        }
    }

    // 同步issue状态查询
    const issueStatus = async (id: string) => {
        const res = await syncIssueStatus(id)
        if (res?.sync) {
            setTimeout(() => {
                issueStatus(id)
            }, 2000);
        } else {
            fetchDetail()
        }
    }

    let tip = detail?.type?.title ? `#${detail?.type?.title}#` : ''
    tip += detail?.learn ? `, #影响学习进度#` : ''
    tip += detail?.labels?.length ? ', ' + detail?.labels?.join(', ') : ''

    // 菜单
    const menu = [
        {
            label: '删除',
            key: '1',
            onClick: () => {
                deleteQues()
            }
        },
        {
            label: detail?.sync ? '已同步issue' : '同步为issue',
            key: '2',
            disabled: detail?.sync ?? false,
            onClick: () => {
                syncQues()
            }
        }
    ]

    return (
        <div className='detailques'>
            <TopNav title='问答详情' back={back} close={onClose} />

            <div className='detailques-con'>
                <ListContainer
                    ref={ListContainerRef}
                    hasMore={hasMore}
                    fetchData={fetchList}
                    showMore={!empty}
                >
                    <div className='detailques-con-ques' id='detailques-con-ques-id'>
                        <div className='detailques-con-ques-user'>
                            <img src={detail?.userInfo?.avatar} alt="" />
                            <span className='title'>{detail?.userInfo?.displayName}</span>
                            <span className='time'>{detail?.createDateTime}</span>
                            {
                                detail?.isProblemAuthor ? <Dropdown
                                    menu={{ items: menu}}
                                >
                                    <div className='svg'>
                                        <SvgIcon iconClass='ques-sandian' style={{ width: '16px', height: '16px' }} />
                                    </div>
                                </Dropdown> : <></>
                            }
                        </div>

                        <p className='detailques-con-ques-title'>{detail?.title}</p>

                        {
                            detail?.content ? <div className='detailques-con-ques-content'>
                                <RichEditor defaultValue={detail?.content} readOnly isBorder={false} autoHeight={{min: 0, max: 1000}}/>
                            </div> : <></>
                        }

                        {
                            (detail?.type?.title || detail?.learn || detail?.labels?.length) ? <Tooltip placement="bottomLeft" arrow={false} title={tip} overlayClassName='item-tooltip item-tooltip-left'>
                                <div className='detailques-con-ques-labels'>
                                    {
                                        detail?.type?.title ? <span className='detailques-con-ques-labels-type'>#{detail?.type?.title}#</span> : <></>
                                    }
                                    {
                                        detail?.learn ? <span className='detailques-con-ques-labels-type'>#影响学习进度#</span> : <></>
                                    }
                                    {
                                        detail?.labels?.map((item: string) => {
                                            return <span className='detailques-con-ques-labels-label' key={item}>{item}</span>
                                        })
                                    }
                                </div>
                            </Tooltip> : <></>
                        }
                        

                        <div className='detailques-con-ques-from'>
                            {
                                courseType === courseTypes.COURSE && <p className='detailques-con-ques-from-title'>
                                    <span>来自：</span>
                                    <span>{detail?.chapter}</span>
                                </p>
                            }
                            {
                                detail?.cooperate ? <p className='detailques-con-ques-from-title'>
                                    <span>协同链接：</span>
                                    <span onClick={() => {
                                        detail?.cooperateUrl && window.open(detail?.cooperateUrl)
                                    }}>点击进入协同</span>
                                </p> : <></>
                            }
                        </div>

                    </div>

                    <div className='detailques-con-main'>
                        <div className='detailques-con-main-num'>
                            <span>全部评论</span>
                            <span>共{total}条</span>
                        </div>
                        <div className='detailques-con-main-list'>
                            {
                                empty ? <div className='detailques-con-main-list-nodata'>
                                    <img src={require('../../../assets/images/nodata.png')} alt="" />
                                    <span>这里没有评论，都要长草啦！</span>
                                </div> : data?.map((item: any) => {
                                    return <DetailQuesItem data={item} key={item.id}/>
                                })
                            }
                        </div>
                    </div>
                </ListContainer>
            </div>

            <div className='detailques-recover' style={{'--height': recover ? '430px' : '34px'} as any}>
                {
                    recover ? <div className='detailques-recover-submit'>
                        <div className='detailques-recover-submit-left'>
                            <img src={userInfo?.detail?.avatar} alt="" />
                        </div>

                        <div className='detailques-recover-submit-right'>
                            <span className='detailques-recover-submit-right-title'>回复问答</span>
                            <div className='detailques-recover-submit-right-editor'>
                                <RichEditor placeholder='' editorHeight={180} onChange={(value: string) => {setEditorValue(value)}}/>
                            </div>
                            <div className='detailques-recover-submit-right-btn'>
                                <Button onClick={() => {setRecover(false)}}>关闭</Button>
                                <Button type='primary' disabled={editorValue ? false : true} onClick={() => {
                                    if (tamp.current) return
                                    subRecover()
                                }}>回复</Button>
                            </div>
                        </div>
                    </div> :
                    <div className='detailques-recover-btn'>
                        <span onClick={() => {setRecover(true)}}>点此回复问答</span>
                    </div>
                }

            </div>
        </div>
    )
}

export default DetailQues