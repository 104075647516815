import React, { HtmlHTMLAttributes, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import './index.less'
import SvgIcon from '../../../components/SvgIcon'

type ListContainerProps = {
    style?: React.CSSProperties
    children?: React.ReactNode
    hasMore: boolean              // 是否还有加载更多
    fetchData: () => Promise<any> // 下拉加载更过
    loading?: React.ReactNode        // 加载中样式   存在默认样式
    noMore?: React.ReactNode         // 没有更多了   存在默认样式
    topDistance?: (value: number) => void// 滚动距离距离
    showMore?: boolean             // 是否显示底下的没有更过  默认显示
    [key: string]: any
}

let ListContainer: React.FC<ListContainerProps> = (props, ref) => {

    const { style, children, topDistance, hasMore, fetchData, showMore = true } = props

    // 列表元素
    const dom = useRef<any>(null)
    // 加载中
    const [loading, setLoading] = useState(false)

    useImperativeHandle(ref, () => ({
        // 回到顶部
        scrollToTop: (top: number = 0) => {
            const el = dom?.current as HTMLDivElement
            if (!el) return
            el.scrollTo({
                top,
                behavior: "smooth"
            })
        }
    }))

    const onScroll = (e: any) => {
        // 滚动距离
        const top = e.target.scrollTop
        // 列表高度
        const clientHeight = e.target.clientHeight
        // 总体内容高度
        const scrollHeight = e.target.scrollHeight

        topDistance?.(top)
        if (hasMore && !loading && (top + clientHeight > scrollHeight - 1)) {
            setLoading(true)
            fetchData().finally(() => {
                setLoading(false)
            })
        }
    }


    return (
        <div className='listcontainer' style={style} ref={dom} onScroll={onScroll}>
            {children}
            {
                loading ? <div className='listcontainer-moreloading'>
                    <SvgIcon iconClass='ques-loading' style={{ width: '12px', height: '12px' }} className='listcontainer-moreloading-rotate' />
                    <span>加载中…</span>
                </div> : <></>
            }
            {
                hasMore ? <></> :
                showMore ? <div className='listcontainer-moreloading'>
                    <span>没有更多了</span>
                </div> : <></>
            }
        </div>
    )
}

ListContainer = forwardRef(ListContainer as any)

export default ListContainer