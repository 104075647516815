import React, { Fragment, useEffect, useState } from "react"
import { Modal, Switch, Tooltip } from "antd"
import './index.less'
import SvgIcon from "../../../../components/SvgIcon"
import { downloadConfig } from '../../../../api/modules/repo'


type DownloadSettingProps = {
  open: boolean
  data: any
  settings: any //设置数据
  params: any //请求参数
  onClose: (value: any) => void
}

const DownloadSetting: React.FC<DownloadSettingProps> = (props) => {
  const { open, data = [], settings = [], params = {}, onClose } = props

  // 只设置最外层数据
  const [files, setFiles] = useState([])

  // 设置下载 checked：是否选中 value：当前文件
  const onDownloadConfig = (checked: boolean, value: any) => {
    files.forEach((v: any) => {
      if (v?.path === value?.path) {
        v.allowDownload = checked
      }
    })
    setFiles([...files])
    const _files = files?.map((v: any) => {
      return {
        parent: v?.parent,
        path: v?.path,
        title: v?.title,
        allowDownload: v?.allowDownload,
        allowClone: v?.allowClone
      }
    })
    downloadConfig({
      ...params,
      data: _files
    })
  }

  // 过滤数据
  useEffect(() => {
    if (open) {
      const _data = data?.filter((v: any) => v?.parent === '')
      _data.forEach((v: any) => {
        const obj = settings?.find((i: any) => v?.path === i?.path)
        v.allowDownload = obj?.allowDownload || false
        v.allowClone = false
      });
      setFiles(_data)
    }
  }, [open, data, settings])
  return (
    <Modal wrapClassName="download-setting" open={open} closable={false} footer={''} width={700} destroyOnClose centered>
      <>
        <div className="download-setting-close" onClick={() => onClose(files)}>
          <SvgIcon iconClass="close" fontSize="20" />
        </div>
        <div className="download-setting-title">资源下载设置</div>
        <div className="download-setting-list">
          <div className="download-setting-list-th">
            <div>资源名</div>
            <div>允许下载</div>
            <div>允许clone</div>
          </div>
          <div className="download-setting-list-trs">
            {
              files?.length ? files?.map((v: any) => (
                <div className="download-setting-list-tr" key={v?.path}>
                  <div className="file-name">
                    {v?.foldIcon}
                    <Tooltip title={v?.title} arrow={false} placement='bottomLeft' overlayClassName={'item-tooltip'}>
                      <span className="name">{v?.title}</span>
                    </Tooltip>
                  </div>
                  <div>
                    <Switch size="small" checked={v?.allowDownload} onChange={(checked: boolean) => onDownloadConfig(checked, v)} />
                  </div>
                  <div>
                    <Tooltip title={`正在建设中`} arrow={false} placement='bottom' overlayClassName={'item-tooltip'}>
                      <Switch size="small" disabled />
                    </Tooltip>
                  </div>
                </div>
              )) : (
                <div className="download-setting-list-empty">这里还没有内容，请先创建内容再进行下载设置</div>
              )
            }
          </div>
        </div>
      </>
    </Modal>
  )
}

export default DownloadSetting