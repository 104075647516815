import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import hljs from 'highlight.js'

type CodeHighlightProps = {
    lang?: string
    value: string
    style?: React.CSSProperties
}

const language: any = {
    shell: 'PowerShell',
    java: 'java',
    python: 'python'
}

const CodeHighlight: React.FC<CodeHighlightProps> = (props) => {

    const { lang = 'plaintext', value, style } = props
    const dom = useRef<HTMLElement>(null)

    useEffect(() => {
        const res = hljs.highlight(value, {language: language[lang] ?? lang})
        dom.current!.innerHTML = res.value
    }, [value, lang])

    return (
        <pre className='code-highlight' style={style}>
            <code className={`hljs language-${language[lang] ?? lang}`} ref={dom}></code>
        </pre>
    )
}

export default CodeHighlight