import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import './index.less'
import { Checkbox, Input, Space, Typography } from 'antd'
import { CSSTransition } from 'react-transition-group'
import { rowContext } from '.'

type RowProps = {
    value: any
    paddingLeft?: number
}

const Row: React.FC<RowProps> = (props) => {

    const { addRowInput, changeRow, addKnowledge, removeRow, changeSelect } = useContext(rowContext)

    const { value, paddingLeft = 0} = props

    const inp = useRef<any>()

    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        const dom: HTMLElement = inp.current
        if (dom && value._type === 'input') {
            dom.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
        }
    }, [value?._type])

    return (
        <>
            <div className='table-row'>
                <Checkbox checked={value?._checked} onChange={(e) => { changeSelect(value, e.target.checked) }}></Checkbox>
                <div className='table-row-knowledge' style={{paddingLeft: paddingLeft + 'px'}}>
                    {
                        value._hasChildren && <div className='table-row-triangle' onClick={() => changeRow(value.knowledgeId, { _open: !value._open })} style={{ transform: value?._open ? 'rotate(0deg)' : 'rotate(-90deg)' }}></div>
                    }
                    {
                        value._type === 'dir' ? <>
                            <span className='table-row-title' style={{paddingLeft: value._hasChildren ? '0px' : value.type === 'CUSTOM' ? '40px' : '20px'}}>{value?.name}</span>
                        </> : <>
                            <Input showCount maxLength={30} value={inputValue} onChange={(e) => setInputValue(e.target.value)} style={{marginLeft: '40px'}}/>
                        </>
                    }
                </div>

                <Space align="center" size={10} style={{ width: '150px', marginLeft: '10px' }}>
                    {
                        value.type === 'SECTION' ? <>
                            <Typography.Link onClick={() => {addRowInput(value)}}>添加子级</Typography.Link>
                        </> : value._type === 'input' ? <>
                            <Typography.Link onClick={() => {addKnowledge(value, inputValue)}}>添加</Typography.Link>
                            <Typography.Link onClick={() => {removeRow(value.knowledgeId)}}>取消</Typography.Link>
                        </> : <div></div>
                    }
                </Space>
            </div>

            <CSSTransition
                key={value.knowledgeId}
                timeout={300}
                classNames={{
                    enter: 'table-row-enter',
                    enterActive: 'table-row-enter-active',
                    exit: 'table-row-exit',
                    exitActive: 'table-row-exit-active'
                }}
                in={value._open}
                mountOnEnter
                unmountOnExit
            >
                <div>
                    <div style={{ overflow: 'hidden' }}>
                        {
                            value?.children?.map((item: any) => {
                                return <Row value={item} key={item?.knowledgeId} paddingLeft={paddingLeft + 14} />
                            })
                        }
                    </div>
                </div>
            </CSSTransition>

        </>
    )
}

export default memo(Row)