import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import { Form, Input, Modal, Select, message } from 'antd'
import SetEnv from '../SetEnv'
import { getExperEnv } from '../../api/modules/cli'
import PortItem from '../PortItem'
import AddTools from '../AddTools'
import SvgIcon from '../SvgIcon'

interface ExperEnvProps {
    currentExperEnv: string         // 当前的实验环境
    setCurrentExperEnv: Function    // 修改当前的实验环境

    currentExperTools: string       // 当前的实验工具
    setCurrentExperTools: Function  // 修改当前的实验工具

    experPort: any[]                // 端口工具
    setExperPort: Function          // 修改端口工具 

    userId: string                  // 用户id
    labCode: string                 // 实验室 code
    creator?: string                // 创建者
    form: any                       // 表单控制
    titleName?: string               //判断是否为小节
    currentDirLevel?: string
    currentContentType?: string         //内容类型
    durationTime: string             //环境时长
    setDurationTime: Function          // 修改时长 
}

const ExperEnv: React.FC<ExperEnvProps> = (props) => {

    const { currentExperEnv, setCurrentExperEnv, currentExperTools, setCurrentExperTools, experPort, setExperPort, userId, labCode, form, titleName, currentDirLevel, durationTime, setDurationTime, currentContentType, creator } = props

    const [isSetEnv, setIsSetEnv] = useState(false)           // 是否显示自定义环境
    const [isAddTools, setIsAddTools] = useState(false)       // 是否显示添加工具

    const [experEnv, setExperEnv] = useState<any>([])         // 实验环境数组  
    const [experTools, setExperTools] = useState<any>([])     // 实验工具

    const [portCreatorNum, setPortCreatorNum] = useState(0)   // 当前用户自定义的端口工具


    const [showExplain, setShowExplain] = useState(false)

    // 获取环境列表
    const fetchExperEnvList = async () => {
        const res = await getExperEnv({ owner: userId, lab: labCode })
        setExperEnv(res)
        return res
    }

    // 合并端口工具
    const mergePorts = (data: any, target: any) => {
        data = data?.filter((i:any)=>i.choice).map((item: any) => ({ ...item, choice: true, type: item?.type ? item?.type : 'system' })) ?? []
        const res = target?.filter((item: any) => item?.type === 'creator')?.filter((item: any) => !data?.some((it: any) => it.port === item?.port))
        return data.concat(res)
    }

    // 自定义环境完成
    const customFinish = async (current: string) => {
        const res = await fetchExperEnvList()
        const containers = res?.find((item: any) => {
            return item?.location === current
        })
        setCurrentExperEnv?.(current)
        setCurrentExperTools(containers?.containers?.[0]?.name)
        // setExperPort(containers?.containers?.[0]?.ports || [])
        setExperPort((data: any) => mergePorts(containers?.containers?.[0]?.ports, data))
    }

    // 只能输入15-180
    const handleInput = (e: any) => {

        const inputValue = e.target.value;
        if (inputValue === '') {
            setDurationTime(inputValue ?? '')
            return
        }
        let max = 180

        if (/^\d*$/.test(inputValue) && Number(inputValue) <= max) {
            console.log(inputValue);

            setDurationTime(inputValue ?? '')
        }

    }

    // 添加端口完成
    const portFinish = async (data: any) => {
        const port = {
            name: data.name,
            port: parseInt(data.port),
            image: data.image,
            choice: true,
            type: 'creator'
        }
        setExperPort([...experPort, port])
    }

    // 选择端口工具
    const choicePort = (port: number, check: boolean) => {
        const ports = experPort?.map((item: any) => {
            if (port == item.port) {
                return { ...item, choice: check,isUse:check }
            }
            return { ...item }
        })
        setExperPort(ports)
    }

    // 删除 port
    const delPort = (port: number) => {
        const ports = experPort?.filter((item: any) => {
            return item.port !== port
        })
        setExperPort(ports)
    }

    // 初次加载数据
    useEffect(() => {
        // console.log('titleName => ', titleName, 'currentDirLevel => ', currentContentType);

        fetchExperEnvList()
    }, [currentDirLevel, titleName, currentContentType])

    // 更改表单 (通过改变currentExperEnv，表单数据不会更新，需要通过表单自行控制)
    useEffect(() => {
        if (!form) return
        form?.setFieldValue('experEnv', currentExperEnv || undefined)
        form?.setFieldValue('expertools', currentExperTools || undefined)

    }, [currentExperEnv, currentExperTools, durationTime, form])

    // 根据当前实验环境选择当前实验工具列表
    useEffect(() => {
        if (!experEnv || !experEnv?.length) return
        const current = experEnv?.find((item: any) => {
            return item?.location === currentExperEnv
        })
        const containers = current?.containers ?? []
        setExperTools(containers)
    }, [currentExperEnv, experEnv])

    // 计算数量
    useEffect(() => {
        const res = experPort?.filter((item: any) => item?.type === 'creator') || []
        setPortCreatorNum(res?.length)
    }, [experPort])

    // console.log('🚀', experTools?.length, currentContentType, titleName, currentDirLevel)

    return (
        <div className='experenv'>
            <div className='experenv-env'>
                <Form.Item label="实验环境" name='experEnv'>
                    <Select
                        placeholder="请选择"
                        allowClear={true}
                        fieldNames={{ label: 'name', value: 'location' }}
                        options={experEnv}
                        value={currentExperEnv}
                        onChange={(value) => {
                            const current = experEnv?.find((item: any) => {
                                return item?.location === value
                            })
                            setCurrentExperEnv(value)
                            setCurrentExperTools(current?.containers?.[0]?.name)
                            setExperPort((data: any) => mergePorts(current?.containers?.[0]?.ports, data))
                            // setExperPort(current?.containers?.[0]?.ports || [])
                        }}
                    />
                </Form.Item>
                <div className='experenv-env-enter'>
                    {/*<span onClick={() => { setIsSetEnv(true) }}>自定义</span>*/}
                    <a href={`${process.env.REACT_APP_API_URL_OS}/admin/base/labenv/setEnv?labCode=${labCode}&userId=${userId}&title=我的环境&edited=true`} target="_blank">自定义</a>
                </div>
                <SetEnv open={isSetEnv} onCancel={() => { setIsSetEnv(false) }} change={customFinish} userId={userId} labCode={labCode} creator={creator} />
            </div>

            {
                experTools?.length ? <div className='experenv-tools'>
                    <Form.Item label="实验工具" name='expertools' >
                        <Select
                            placeholder="请选择"
                            options={experTools}
                            // value={currentExperTools?.toLowerCase()?.replace(/pod.yaml/g, 'Pod.yaml')}
                            fieldNames={{ label: 'name', value: 'name' }}
                            onChange={(value) => {
                                const current: any = experTools?.find((item: any) => {
                                    return value === item?.name
                                })
                                setCurrentExperTools(value)
                                // setExperPort(current?.ports || [])
                                setExperPort((data: any) => mergePorts(current?.ports || [], data))

                            }}
                        />
                    </Form.Item>
                    {
                        experPort?.length ? <div className='experenv-tools-list'>
                            <div className='experenv-tools-list-con'>
                                {
                                    experPort?.map((item: any) => {
                                        return <PortItem
                                            key={item?.port}
                                            image={item?.image}
                                            name={item?.name}
                                            port={item?.port}
                                            checked={item?.choice && (item.isUse || item.isUse===undefined)}
                                            changeChecked={(check: boolean) => { choicePort(item?.port, check) }}
                                            del={item?.type === 'creator' ? () => { delPort(item?.port) } : null}
                                            editor={item?.type === 'creator' ? (data) => {
                                                const res = experPort?.map((item: any) => {
                                                    if (item?.port === data?.oldPort) {
                                                        return {
                                                            ...item,
                                                            name: data?.name,
                                                            port: data?.port,
                                                            image: data?.image
                                                        }
                                                    }
                                                    return { ...item }
                                                })
                                                setExperPort(res)
                                            } : null}
                                            ports={experPort}
                                        />
                                    })
                                }
                            </div>
                        </div> : <></>
                    }
                    <div className='experenv-tools-add' style={{ color: portCreatorNum < 10 ? '#1677ff' : '#aeaeae', pointerEvents: portCreatorNum < 10 ? 'auto' : 'none', cursor: portCreatorNum < 10 ? 'pointer' : 'default' }}>
                        <div className='experenv-tools-add-btn' onClick={() => {
                            if (portCreatorNum < 10) {
                                setIsAddTools(true)
                            }
                        }}>
                            <SvgIcon iconClass='addnocolor' fontSize='16px' style={{ marginRight: '4px' }} fill='inherit'></SvgIcon>
                            <span>添加工具</span>
                        </div>
                    </div>
                    <AddTools open={isAddTools} onCancel={() => { setIsAddTools(false) }} finish={portFinish} ports={experPort} />
                </div> : <></>
            }
            {
                (experTools?.length && (currentContentType === 'text' || currentContentType === 'video' || currentContentType === 'exam' || titleName === '小节' || currentDirLevel === 'section')) ? <div className='experenv-time'>
                    <div className='timeLable'>
                        <div className='timeLable-text'>环境使用时长：</div>
                    </div>
                    <Input
                        suffix="分钟"
                        value={durationTime}
                        allowClear={false}
                        type="text"
                        onInput={handleInput}
                    />
                    <div className='env-time'>
                        <SvgIcon iconClass='help' onClick={() => setShowExplain(true)} fontSize='16px' />
                        <Modal
                            open={showExplain}
                            wrapClassName={'ylzcc-modal-env-time-help'}
                            onCancel={() => { setShowExplain(false) }}
                            footer={false}
                            centered={true}
                            width={400}
                            destroyOnClose={true}
                        >
                            <div className='explain'>
                                <p><span>1.&nbsp;</span><span>环境使用时长为当前环境单次学习最大可使用时长。</span></p>
                                <p><span>2.&nbsp;</span><span>环境使用时长到达时，该环境将会被关闭。</span></p>
                            </div>
                        </Modal>
                    </div>
                </div> : <></>
            }

        </div>
    )
}

export default ExperEnv