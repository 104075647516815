import React, { Fragment, useEffect, useState } from 'react';
import './index.less'
import Line from '../../Line'

type SiderProps = {
  siderOpen?: any        // 侧边栏相关组件的打开收起状态
  [other: string]: any
}

// 拖动的最小距离
const BORDER = 32
// 拖拉线的高度
const LINE_HEIGHT = 2
// 头部高度
const HEADER_HEIGHT = 48

const Sider: React.FC<SiderProps> = (props) => {

  const { siderOpen, children } = props

  // 子组件
  const [components, setComponents] = useState([])

  // 移动
  const move = (num: any, index: number) => {
    if (num === 0) return
    const elements = document.querySelectorAll('.workspace-sider > div:not(.line, .noline)');
    let element: any
    let elePrev: any
    elePrev = elements[index - 1]
    element = elements[index]
    //#region 
    // if (num > 0) {
      // 向下移动
      // 分割线上方区域增加高度
      // elePrev = elements[index - 1]
      // element = elements[index]
      // for (let i = index; i < elements.length; i++) {
      //   const _element: any = elements[i];
      //   if (_element?.offsetHeight > BORDER) {
      //     element = _element
      //     break
      //   }
      // }
    // } else {
      // 向上移动
      // 分割线下方区域增加高度
      // element = elements[index]
      // elePrev = elements[index - 1]
      // for (let i = index - 1; i >= 0; i--) {
      //   const _element: any = elements[i];
      //   if (_element?.offsetHeight > BORDER) {
      //     elePrev = _element
      //     break
      //   }
      // }
    // }
    //#endregion
    if (!element || !elePrev) return
    // 保持高度变化的原子性  （有些高度已经最小了，无法再小，但是高度可能一直在增大）
    // // 使之变化到最小高度
    if (elePrev?.offsetHeight + num < BORDER) {
      const distance = BORDER - elePrev?.offsetHeight - num
      num += distance
    } else if (element?.offsetHeight - num < BORDER) {
      const distance = BORDER - element?.offsetHeight + num
      num -= distance
    }
    if (num === 0) return
    // num > 0  向下移动 反之 向上移动
    elePrev.style.height = elePrev?.offsetHeight + num + 'px'
    element.style.height = element?.offsetHeight - num + 'px'
  }

  const handle = () => {
    const _children = children?.filter((child: any) => child)
    // 计算展开的个数
    let size = 0
    _children.forEach((element: any) => {
      if (siderOpen[element.props['data-sider']]) {
        size++
      }
    })
    // 计算总体高度
    const container = document.getElementById('workspace-sider') as HTMLDivElement
    // 去除分界线的高度
    const length = container.offsetHeight - LINE_HEIGHT * (_children?.length - 1)
    // 去除没有展开的高度
    const restHeight = length - BORDER * (_children.length - size)
    
    const res = _children.map((item: any, index: number, arr: any) => {
      let pre = false
      if (index > 0) {
        pre = siderOpen[arr[index - 1].props['data-sider']]
      }
      if (siderOpen[item.props['data-sider']]) {
        return {child: item, height: restHeight / size + 'px', open: true, pre}
      }
      return {child: item, height: BORDER + 'px', open: false, pre}
    })
    setComponents(res)
  }

  // 过滤掉undefined的数据
  useEffect(() => {
    handle()
  }, [children, siderOpen])

  // 窗口大小变化时，重设height 
  useEffect(() => {
    const handleResize = () => {
      handle()
      // document.querySelectorAll('.workspace-sider > div:not(.line, .noline)')?.forEach((el: any) => {
      //   // el?.style.removeProperty('height');
      //   el.style.height = `calc((100vh - ${HEADER_HEIGHT}px - ${LINE_HEIGHT * (components?.length - 1)}px) / ${components?.length})`
      // })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [children, siderOpen])

  return (
    <div className='workspace-sider' id='workspace-sider'>
      {
        components?.map((child: any, index: number) => (
          <Fragment key={index}>
            {
              index === 0 ? <></> :
              (child.open && child.pre) ? <Line state='row' move={(num: any) => move(num, index)} /> :
              <div className='noline'></div>
            }
            <div style={{ height: child?.height}}>{child?.child}</div>
          </Fragment>
        ))
      }
    </div>
  );
};

export default Sider;