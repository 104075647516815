import http from "../index"
import config from '../../config'

// 获取websocket地址
export function getWebscoketUrl(props?: any) {
  return `${config?.wsURL}?key=${props?.key ?? ''}&repo=${props?.repo ?? ''}&path=${props?.path ? encodeURIComponent(props?.path) : ''}&workdir=${props?.workdir ?? ''}&component=${props?.component ?? ''}`
}

// 获取当前状态
export async function getRepoStatus(params: any) {
  return http.get(`/repo/status`, {
    repo: params.repo,
    key: params.key,
    sign: params.sign
  });
}

// 同步当前状态
export async function setRepoStatus(params: any) {
  return http.post(`/repo/status?repo=${params?.repo}`, {
    key: params?.key,
    state: params?.path
  });
}

// 更新课程
export async function getGitPull(params: any) {
  return http.get(`/repo/pull`, {
    repo: params.repo,
    sign: params.sign
  });
}

// 获取提交的课程目录
export async function getSubmitCatalog(params: any) {
  return http.get(`/repo/diff`, {
    repo: params.repo,
    sign: params.sign
  });
}

// 创建或更新课程目录
export async function commitCatalog(params: any) {
  let url = `/repo/commit/?repo=${params.repo}&sign=${params.sign}`
  return http.post(url, {
    path: params.path
  });
}

// 刷新 课程目录
export async function refreshCourse(data: any) {
  return http.get(`/repo/refresh`, data);
}

// 下载配置
export async function downloadConfig(params: any) {
  return http.post(`/repo/downloadConfig?repo=${encodeURIComponent(params?.repo)}&path=${encodeURIComponent(params?.path)}`, params?.data);
}

// 获取下载配置
export async function getDownloadConfig(params: any) {
  return http.get(`/repo/downloadConfig`, {
    repo: params?.repo,
    path: params?.path
  });
}