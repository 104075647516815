import React from 'react'
import './index.less'
import SvgIcon from '../../../components/SvgIcon'
import { message } from 'antd'

type HeaderProps = {
    current: number
    onClose: () => void
}

const Header: React.FC<HeaderProps> = (props) => {

    const {current, onClose} = props

    return (
        <div className='quesans-header'>
            <div className={current === 1 ? 'quesans-header-item quesans-header-light' : 'quesans-header-item'}>问答</div>
            <div className='quesans-header-item' style={{cursor: 'default'}} onClick={() => {
                message.destroy()
                message.warning('正在建设中...')
            }}>笔记</div>
            <SvgIcon iconClass='ques-fold' style={{ width: '16px', height: '16px' }} className='quesans-header-fold' onClick={() => {
                onClose?.()
            }}/>
        </div>
    )
}

export default Header