import React from 'react'
import './index.less'
import SvgIcon from '../../SvgIcon'

type LeftSiderProps = {
    open: Function          // 打开某个目录的函数
    examType: any           // 是否是考试场景   "0" 考试场景
    showDirectory: boolean // 博客：无目录不显示 "目录"
}

const LeftSider: React.FC<LeftSiderProps> = (props) => {

    const { open, examType, showDirectory = true } = props

    const menus = [
        {
            id: '1',
            icon: <SvgIcon iconClass={'left-resource'} style={{ width: '20px', height: '20px' }} />,
            title: '目录',
            display: examType !== '0' && showDirectory,
            onClick: () => {
                open?.('courseDirectory')
            }
        },
        {
            id: '2',
            icon: <SvgIcon iconClass={'left-resource'} style={{ width: '20px', height: '20px' }} />,
            title: '资源',
            display: true,
            onClick: () => {
                open?.('resourceManage')
            }
        },
        {
            id: '3',
            icon: <SvgIcon iconClass={'left-tool'} style={{ width: '20px', height: '20px' }} />,
            title: '工具',
            display: true,
            onClick: () => {
                open?.('tools')
            }
        }
    ]

    return (
        <div className='leftsider'>
            {
                menus?.map((item: any) => {
                    if (item?.display) {
                        return <div className='leftsider-item' key={item?.id} onClick={() => {item?.onClick?.()}}>
                            {item?.icon}
                            <span>{item?.title}</span>
                        </div>  
                    } else {
                        return <></>
                    }
                })
            }
        </div>
    )
}

export default LeftSider