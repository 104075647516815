import React, { Suspense, useEffect, useState } from 'react'
import './index.less'
import Itab from './Itab'


export type Tab = {
    id?: string,
    title?: string,
    icon?: string,
    component?: any,
    // component?: React.ReactNode,
    menu?: object[],
    props?: object,
    upperRightComponent?: any
}

type ContainerProps = {
    tabs: Tab[],
    current: string,
    closeTab?: Function,
    closeOtherTabs?: Function,
    changeCurrent?: Function,
    addMore?: Function
}

const Container: React.FC<ContainerProps> = (props) => {

    const { tabs, current, closeTab, closeOtherTabs, changeCurrent } = props

    //菜单
    const [menu, setMenu] = useState<object[]>([])

    // 是否满行
    const [row, setRow] = useState(false)

    // 监听组件变化，获取当前组件的自定义方法
    useEffect(() => {
        if (current) {
            const tab = tabs.find((item: Tab) => {
                return item.id === current
            })
            const two = []
            if (closeTab) {
                two.push({ title: '关闭当前标签', callback: () => { closeTab?.(current) } })
            }
            if (closeOtherTabs && tabs.length > 1) {
                two.push({ title: '关闭其他标签', callback: () => { closeOtherTabs?.() } })
            }
            if (tab?.menu) {
                setMenu([...tab?.menu, ...two])
            } else {
                setMenu(two)
            }
            if (tab?.upperRightComponent) {
                setRow(true)
            } else {
                setRow(false)
            }
        }
    }, [current, tabs])

    useEffect(() => {
        if (current) {
            const el: any = document.getElementById(current)
            el?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        }
    }, [current])

    return (
        <div className='container'>
            <div className="container-nav">
                <div className='between' style={{maxWidth: row ? '80%' : '100%'}}>
                    <div className='container-nav-tabs'>
                        {
                            tabs.map(item => {
                                const iscurrent = item.id === current
                                return <Itab highlight={iscurrent} key={item.id} closeTab={closeTab} currentTab={item} changeCurrent={changeCurrent} menu={menu} />
                            })
                        }
                    </div>
                </div>
                <div className='container-nav-other'>
                    {
                        tabs?.filter(item => item.id === current).map(item => item?.upperRightComponent)
                    }
                </div>
            </div>
            
            <div className='container-content'>
                {
                    tabs?.map(item => {
                        const z = item.id === current ? 10 : -1
                        return (
                            <div style={{ zIndex: z, width: "100%", height: "100%", position: "absolute", left: 0, top: 0 }} key={item.id}>
                                <Suspense >
                                {
                                    <item.component {...(item.props)} />
                                }
                                </Suspense>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Container