import http from "../index"
import config from '../../config'

const ojURL = config.ojURL

// 启动pod
export async function start(params:any) {
  return http.post(`${ojURL}/api/judge/start/pod`,params);
}

// 停止pod
export async function release(params:any) {
  return http.post(`${ojURL}/api/judge/stop/pod`,params);
}