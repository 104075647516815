import React, { useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import './index.less'
import SvgIcon from '../../../../../components/SvgIcon'
import { applyShortChain, getInviteStudy, inviteStudy } from '../../../../../api/modules/community'
import { getQueryStringValue } from '../../../../../utils'
import config from '../../../../../config'

type InviteProps = {
  onCanInvite?: (value: boolean) => void //是否可以邀请协作
  [other: string]: any
}

const Invite: React.FC<InviteProps> = (props) => {

  const { onCanInvite } = props

  // modal：形态（0：学习，1：创作)
  const modal = getQueryStringValue("modal")

  // 仓库
  const repo = getQueryStringValue('repo')

  // 用户
  const key = getQueryStringValue('key')

  //邀请弹窗显示
  const [inviteDig, setInviteDig] = useState<any>(false)

  //inviteUrl
  const [inviteUrl, setInviteUrl] = useState<any>('')
  const [copyLink, setCopyLink] = useState('')

  // 是否可以邀请协作
  const [canInvite, setCanInvite] = useState(true)

  // 复制链接
  const copy = () => {
    const value = copyLink
    // const value = `快来开源大学堂一起学习！${inviteUrl}`
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(value.trim())
    } else {
      const dom = document.createElement('input')
      dom.value = value.trim()
      document.body.appendChild(dom)
      dom.select()
      document.execCommand('copy')
      document.body.removeChild(dom)
    }
    message.success('复制成功')
    setInviteDig(false)
  }

  //获取邀请的Url
  const getInviteUrl = async () => {
    // inviteStudy({
    //   repo: repo,
    //   callback: config.osApiURL
    // }).then(res => {
    //   setInviteUrl(res.callbackAddress)
    // })
    const res = await applyShortChain({
      repo: repo as string,
      callback: config.osApiURL + `?modal=${modal}` as string,
      inviteContentType: modal === '1' ? 'wbCreationInvite' : 'wbStudyInvite'
    }).catch(() => { })
    if (!res) return
    setInviteUrl(res?.shortLink)
    setCopyLink(res?.templateContent)
  }

  // 学习：若是被邀请的用户，则不可以再次邀请协作
  // useEffect(() => {
  //   if (modal === '1') return
  //   getInviteStudy({
  //     repo: repo,
  //     key: key
  //   }).then(res => {
  //     onCanInvite?.(res)
  //     setCanInvite(res)
  //   })
  // }, [])

  return (
    <>
      {
        canInvite && (
          <span className='invite-collaboration' onClick={() => {
            setInviteDig(true)
            getInviteUrl()
          }}>
            <SvgIcon iconClass={'inviteIcon'} style={{ width: '20px', height: '20px' }} className="inviteIcon" />
            <span>邀请<br></br>协作</span>
          </span>
        )
      }
      <Modal wrapClassName={'invitaModal'} open={inviteDig} footer={''} width={'500px'} style={{ top: "30%" }}>
        <div className='invitaDiv'>
          <div className='invitaClose' onClick={() => { setInviteDig(false) }}>
            <SvgIcon iconClass="inviteClose" fontSize='24px' />
          </div>
          <div className='inviteTitle'>邀请学习</div>
          <div className='inviteText'>
            <ExclamationCircleFilled rev={undefined} style={{ color: '#FA8C16', marginTop: '4px' }} />
            <div className='text'>
              {/* <div>1.支持邀请平台注册用户和你一起学习；</div>
              <div>2.学习伙伴仅能通过链接进入学习工作台。</div> */}
              <div>1.支持邀请平台注册用户；</div>
              <div>2.协作伙伴通过邀请会直接成为实验室的协作者；</div>
              <div>3.博客和快速体验内容同一时刻仅支持一人创作。</div>
            </div>
          </div>
          <div className='myshare-btn'>
            <input type="text" disabled value={inviteUrl ?? ''} />
            <button onClick={copy}>复制链接</button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Invite;