import React, { useEffect, useState } from 'react'
import Mdast from '@ylzcc/mdast'
import SiderTopTitle from '../SiderTopTitle'
import Tree from './Tree'
import './index.less'
import { isJsonString, base64ToText } from '../../utils'
import { courseTypes } from '../../utils/commonTypes'

type BlogDirectoryProps = {
  courseType: string //课程类型
  wsUrl?: string
  open?: boolean //展开状态
  changeOpen?: (open: boolean) => void // 改变博客目录展开收起状态
  onDirectoryChange?: (value?: any) => void //学习模态下，若博客目录为空，不展示该模块
}

const BlogDirectory: React.FC<BlogDirectoryProps> = (props) => {

  const { wsUrl, open, courseType, changeOpen, onDirectoryChange } = props

  // 加载中
  const [loading, setLoading] = useState(true)

  // ws实例
  const [websocket, setWebsocket] = useState<any>()

  // 需要重连
  const [reconnect, setReconnect] = useState(false)

  // 数据源
  const [mdText, setMdText] = useState<any>()

  // 目录数据
  const [data, setData] = useState<any>([])

  // 连接ws
  const handleWS = (url?: string) => {
    try {
      if (!url) return
      if (websocket?.readyState === 1) {
        websocket?.close()
        setWebsocket('')
        setReconnect(false)
        return
      }
      const ws = new WebSocket(url);
      setWebsocket(ws)
      setReconnect(false)

      ws.onopen = function () {
        setReconnect(false)
      }

      ws.onmessage = function (res: any) {
        const { data } = res
        if (isJsonString(data)) {
          const json = JSON.parse(data)
          const text = json.data
          const type = json?.type
          if (type !== 'read') return
          const mdText = text ? base64ToText(text) : ''
          setMdText(mdText)
          setLoading(false)
        }
      }

      ws.onclose = function (res: any) {
        const code = res.code
        if (code < 3000) {
          setReconnect(true)
        }
      }

      ws.onerror = function () {

      }
    } catch (error) {

    }
  }

  // 获取标题
  useEffect(() => {
    const ast = Mdast.parse(mdText)
    let _headings = Mdast.heading.list(ast)
    _headings = _headings?.filter((v: any) => v?.properties?.tag !== 's')
    setData(_headings)
    if (mdText !== undefined) {
      onDirectoryChange?.(_headings)
    }
  }, [mdText])

  useEffect(() => {
    setLoading(true)
    setReconnect(false)
    handleWS(wsUrl)
  }, [wsUrl])

  useEffect(() => {
    if (reconnect) {
      handleWS(wsUrl)
    }
  }, [reconnect])

  return (
    <div className='blogDirectory'>
      <SiderTopTitle title={courseType === courseTypes.BLOG ? "博客目录" : '目录'} open={open} changeOpen={changeOpen} />
      {
        data?.length ? <Tree treeData={data} /> : !loading ? (
          <div className='blogDirectory-empty'>{`目录根据${courseType === courseTypes.BLOG ? '博客' : ''}标题自动生成，当前暂无目录，请先填写${courseType === courseTypes.BLOG ? '博客' : ''}内容吧~`}</div>
        ) : <></>
      }
    </div>
  )
}

export default BlogDirectory;