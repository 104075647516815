import React, { useEffect } from 'react'
// import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import './index.less'
import dagousvg from '../img/dagou.svg'



const Menu = (props: any) => {
    const { menu, close } = props
    
    return (
        <div className='menu'>
            {
                menu.map((item: any) => {
                    return (
                        <div className={item?.checked ? "menu-item menu-hight" : "menu-item"} onClick={(e) => {e.stopPropagation(); item.callback?.(); close?.();}} key={item.title}>
                            <div className='menu-item-title'>{item.title}</div>
                            {
                                item?.checked ? <img src={dagousvg} alt="" /> : <></>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

// 全局菜单和当前 itab
let instance: any = null
let currentId: any = null

// 显示菜单
const open = (menu: object[], el: any) => {
    if (instance) {
        if (currentId === el.id) return
        close()
    }
    create(menu, el)
}

// 产生菜单
const create = (menu: object[], el: any) => {
    currentId = el.id
    const {left, bottom} = el.getBoundingClientRect()
    instance = document.createElement('div')
    instance.classList.add('myself-menu')
    instance.style.setProperty("left", left + 'px')
    instance.style.setProperty("top", bottom + 'px') 
    instance.style.setProperty("z-index", '999')
    document.body.appendChild(instance)
    // ReactDOM.createRoot(instance).render(<Menu menu={menu}/>)
    ReactDOM.render(<Menu menu={menu} close={close} />, instance)
    listen()
}

// 关闭菜单
const close = () => {
    instance && document.body.removeChild(instance)
    instance = null
    currentId = null
    window.onclick = null
}

// 监听关闭事件
const listen = () => {
    window.onclick = (e: any) => {
        let cu = e.target
        while (cu) {
            if(cu.id === currentId) break
            if(cu.tagName === "BODY"){
                close()
                break
            }
            cu = cu.parentNode
        }
    }
}

export default {
    open,
    close,
    getCurrent: () => ({
        instance: instance ? true : false,
        currentId
    })
}
