import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import "./index.less";
import { Button, Dropdown, Form, Input, MenuProps, Modal, Tooltip, message } from "antd";
import CodeMirror from "codemirror";
import "codemirror/mode/yaml/yaml";
import "codemirror/addon/lint/yaml-lint";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/lint.css";
import EnvForm from "./EnvForm";
import { getContainerTemplate, saveEnvironment, validateImage } from "../../api/modules/cli";
import YAML from 'yaml'
import { nanoid } from 'nanoid'

export type SetEnvProps = {
    open?: boolean;         // 是否显示
    userId?: string;        // 用户id
    creator?: string;       // 创建者
    onCancel?: () => void;  // 关闭显示
    change?: Function;      // 确定时候改变外层数据
    labCode?: string;       // 实验室code
    initValue?: any;        // 表单初始数据 (新增)
    edited?: boolean;       // 是否可以编辑
};

// interface YamlType {
//     id: string,              // 唯一标识
//     name: string,            // 容器名称
//     image: string,           // 镜像
//     resources: {
//         limits: {
//             cpu: number,     // cpu
//             memory: string   // 内存
//         }
//     },
//     ports: [                 // 端口
//         {
//             containerPort: number
//         }
//     ],
//     command: string,         // 命令
//     lifecycle: {             // 命令
//         preStop: {
//             exec: {
//                 command: string
//             }
//         },
//         postStart: {
//             exec: {
//                 command: string
//             }
//         }
//     },
//     env: [                   // 环境变量
//         {
//             name: string,
//             value: string
//         }
//     ],
//     volumeMounts: [          // 持久路径
//         {
//             mountPath: string
//         }
//     ],
//     portsOpen: boolean,      // 端口打开还是关闭
//     commandOpen: boolean,    // 命令打开还是关闭
//     envOpen: boolean,        // 环境变量打开还是关闭
//     volumeMountsOpen: boolean,// 持久路径打开还是关闭
//     imageCode: 0,1,2,3,    // 当前镜像是否通过校验   0 还没校验  1  校验中  2 校验失败   3 校验成功
// }
// type DeepPartial<T> = {
//     [P in keyof T]?: DeepPartial<T[P]> | undefined | null;
// }
// // yarm文件接口格式
// type EnvYaml = Array<DeepPartial<YamlType>>


// 自定义模板
const customTem = {
    id: 'customtem',
    name: 'container-name',
    image: 'ubuntu:20.04',
    resources: {
        limits: {}
    },
    ports: [],
    command: ["sh","-c","tail -f /dev/null"],
    lifecycle: {
        preStop: {
            exec: {
                command: '' // 请根据需要添加执行的命令
            }
        },
        postStart: {
            exec: {
                command: '' // 请根据需要添加执行的命令
            }
        }
    },
    env: [],
    volumeMounts: [],
    portsOpen: false,
    commandOpen: false,
    envOpen: false,
    volumeMountsOpen: false,
    imageCode: 3,
};


const SetEnv: React.FC<SetEnvProps> = (props) => {

    const { open, onCancel, userId, change, labCode, creator, initValue, edited = true } = props

    const [form] = Form.useForm()                                             // 环境名称表单示例
    const Env = useRef<any>(null)                                             // 子表单的实例
    const codemirrorRef = useRef(null);                                       // 编辑器 dom
    const editor = useRef<any>(null);                                         // 编辑器实例

    const [mode, setMode] = useState<any>(true)                               // true: 表单编辑 false:代码预览
    const [tabs, setTabs] = useState<any>([])                                 // 表单编辑 所有标签页
    const [current, setCurrent] = useState<any>({})                           // 当前标签页
    const [isEdit, setIsEdit] = useState<any>(edited)                          // 是否编辑


    // 初始化编辑器和销毁
    useEffect(() => {
        if (mode) return
        if (!codemirrorRef?.current) return
        const edit = CodeMirror(codemirrorRef?.current, {
            value: tabs?.length ? generateTemp(tabs) : undefined,
            mode: "yaml",
            lineNumbers: true,
            smartIndent: true,
            coverGutterNextToScrollbar: false,
            scrollbarStyle: 'null',
            readOnly: true,
            // css有主动设置禁止点击事件
        })
        editor.current = edit
        // setTimeout(() => {
        //     edit.refresh()
        // edit.setValue('1234')
        // editor?.getValue()
        // }, 150)
        return () => {
            editor?.current?.getWrapperElement().remove()
        }
    }, [mode])

    // 保存
    const save = async () => {
        if (!isEdit) {
            setIsEdit(true)
            return
        }
        const data = await Promise.all([form?.validateFields(), Env?.current?.validate()]).catch(() => {})
        if (!data) return
        let resTab = tabs
        if (mode) {
            if (!data?.[1]) {
                message.destroy()
                message.warning('当前容器还未校验成功！')
                return
            }
            resTab = saveTab()   
        }
        const allTabsName = resTab?.map((item: any) => item?.name?.trim())
        const allTabsIndex = Array.from(new Set(allTabsName))
        if (allTabsName?.length !== allTabsIndex?.length) {
            message.destroy()
            message.warning('容器名称不允许重复，当前存在容器名称重复')
            return
        }
        const params = {
            id: initValue?.id,
            owner: userId,
            lab: labCode,
            name: data[0]?.envName ?? '',
            template: generateTemp(resTab),
            create: creator
        }
        const res = await saveEnvironment(params).catch(() => {})
        if (!res) return
        change?.(res?.location)
        onCancel?.()
    }

    // 删除当前标签
    const delTab = () => {
        Modal.confirm({
            title: "确认删除？",
            content: '确认要删除当前容器吗？已填写的信息将丢失!',
            okText: "我再想想",
            cancelText: "确认删除",
            centered: true,
            width: 400,
            onOk() { },
            onCancel() {
                if (tabs?.length <= 1) {
                    setTabs([{ ...customTem }])
                    setCurrent({ ...customTem })
                } else {
                    const newtabs = tabs?.filter((item: any) => item?.id !== current?.id)
                    setTabs(newtabs)
                    if (newtabs[0]) {
                        setCurrent({ ...newtabs[0] })
                    }
                }
            },
            maskClosable: false
        })
    }

    // 选择当前标签
    const selectTab = async (id: string) => {
        if (current?.id === id) return
        const res = await canChange()
        if (res) {
            const newTabs = saveTab()
            const curr = newTabs?.find((item: any) => item?.id === id) as any
            // setTabs(newTabs)
            setCurrent(curr)
        } else {
            message.destroy()
            message.warning('当前容器还未校验成功！')
        }
    }

    // 改变当前标签的名称
    const changeTabName = (name: string) => {
        const newTabs = tabs?.map((item: any) => {
            if (current?.id === item?.id) {
                return { ...item, name }
            } else {
                return { ...item }
            }
        })
        setTabs(newTabs)
    }

    // 添加标签
    const addTab = async (temp: any) => {
        if (tabs?.length >= 10) {
            message.destroy()
            message.warning('最多添加10个容器')
            return
        }
        const res = await canChange()
        if (res) {
            const tab = handleTemObject(temp)
            const newTab = saveTab()
            setTabs([...newTab, tab])
            setCurrent(tab)
        } else {
            message.destroy()
            message.warning('当前容器还未校验成功！')
        }
    }

    // 保存当前标签里面的内容
    const saveTab = () => {
        const newTabs = tabs?.map((item: any) => {
            if (current?.id === item?.id) {
                return Env?.current?.getData()
            } else {
                return { ...item }
            }
        })
        setTabs(newTabs)
        return newTabs
    }

    // 验证镜像
    const verifyImage = async (id: string, image: string): Promise<any> => {
        try {
            const res = await validateImage({ image })
            if (res?.success) {
                return {
                    ...res,
                    code: 3
                }
            }
            return {
                ...res,
                code: 1
            }
        } catch (error: any) {
            return {
                ...error,
                code: 2
            }
        }
    }

    // 是否可以切换容器或mode
    const canChange = async () => {
        return await Env?.current?.validate()
    }

    // 根据容器表单配置生成yarm模板
    const generateTemp = (obj: any): string => {
        obj = JSON.parse(JSON.stringify(obj))
        const data = obj?.map((item: any) => {
            // 处理 cpu和 memory
            if (item?.resources?.limits?.memory) {
                item.resources.limits.memory = item?.resources?.limits?.memory + 'Mi'
            } else {
                item.resources = {
                    limits: {
                        memory: undefined,
                        cpu: item?.resources?.limits?.cpu
                    }
                }
            }
            if (item?.resources?.limits?.cpu) {
                item.resources.limits.cpu = parseFloat(item?.resources?.limits?.cpu)
            } else {
                item.resources.limits.cpu = undefined
            }
            if (!item?.resources?.limits?.cpu && !item?.resources?.limits?.memory) {
                item.resources = undefined
            }
            
            // 命令处理
            if (!item?.command) {
                item.command = undefined
            } else {
                if (!Array.isArray(item?.command)) {
                    // item.command = [item?.command]
                    try {
                        item.command = JSON.parse(item?.command?.trim())
                    } catch (error) {
                        item.command = [item?.command?.trim()]
                    }
                }
            }
            if (!item?.lifecycle?.preStop?.exec?.command) {
                item.lifecycle = {
                    preStop: {
                        exec: {
                            command: undefined
                        }
                    },
                    postStart: {
                        exec: {
                            command: item?.lifecycle?.postStart?.exec?.command
                        }
                    }
                }
            } else {
                if (!Array.isArray(item?.lifecycle?.preStop?.exec?.command)) {
                    try {
                        item.lifecycle.preStop.exec.command = JSON.parse(item?.lifecycle?.preStop?.exec?.command?.trim())
                    } catch (error) {
                        item.lifecycle.preStop.exec.command = [item?.lifecycle?.preStop?.exec?.command?.trim()]
                    }
                }
                // item.lifecycle.preStop.exec.command = [item?.lifecycle?.preStop?.exec?.command]
            }
            if (!item?.lifecycle?.postStart?.exec?.command) {
                item.lifecycle.postStart = {
                    exec: {
                        command: undefined
                    }
                }
            } else {
                if (!Array.isArray(item?.lifecycle?.postStart?.exec?.command)) {
                    try {
                        item.lifecycle.postStart.exec.command = JSON.parse(item?.lifecycle?.postStart?.exec?.command?.trim())
                    } catch (error) {
                        item.lifecycle.postStart.exec.command = [item?.lifecycle?.postStart?.exec?.command?.trim()]
                    }
                }
                // item.lifecycle.postStart.exec.command = [item?.lifecycle?.postStart?.exec?.command]
            }

            if (!item?.lifecycle?.postStart?.exec?.command && !item?.lifecycle?.preStop?.exec?.command) {
                item.lifecycle = undefined
            } else if (!item?.lifecycle?.postStart?.exec?.command) {
                item.lifecycle.postStart = undefined
            } else if (!item?.lifecycle?.preStop?.exec?.command) {
                item.lifecycle.preStop = undefined
            }
            
            // 端口处理
            item.ports = item?.ports?.map((item: any) => {
                return {
                    image: (item?.choice && item?.image) ? item?.image : undefined,
                    choice: item?.choice ? true : false,
                    name: (item?.choice && item?.name) ? item.name : undefined,
                    containerPort: parseInt(item?.containerPort)
                }
            }) ?? []
            if (!item?.ports?.length) {
                item.ports = undefined
            } else {
                item.ports = item?.ports.filter((item: any) => {
                    return item?.containerPort
                })
            }

            // 环境变量处理
            if (!item?.env?.length) {
                item.env = undefined
            } else {
                item.env = item?.env?.filter((item: any) => {
                    return item?.name && item?.value
                })
            }

            // 持久路径处理
            if (!item?.volumeMounts?.length) {
                item.volumeMounts = undefined
            } else {
                item.volumeMounts = item?.volumeMounts?.filter((item: any) => {
                    return item?.mountPath
                })
            }

            delete item?.id
            delete item?.portsOpen
            delete item?.commandOpen
            delete item?.envOpen
            delete item?.volumeMountsOpen
            delete item?.imageCode
            return { ...item }
        })
        const res = YAML?.stringify({ containers: data })
        return res ?? ''
    }

    // 对模板对象进行处理
    const handleTemObject = useCallback((obj: any) => {
        // 将命令相关一律转化为字符串
        const command = typeof obj?.command === 'string' ? obj?.command :
            Array.isArray(obj?.command) ? obj?.command?.join(' ') : ''
        const after = obj?.lifecycle?.preStop?.exec?.command
        const stop = obj?.lifecycle?.postStart?.exec?.command
        obj.command = command
        if (after) {
            obj.lifecycle.preStop.exec.command = typeof after === 'string' ? after :
                Array.isArray(after) ? after?.join(' ') : ''
        }
        if (stop) {
            obj.lifecycle.postStart.exec.command = typeof stop === 'string' ? stop :
                Array.isArray(stop) ? stop?.join(' ') : ''
        }
        // 将内存字段转为只包含数值类型的字符串
        const memory = obj?.resources?.limits?.memory
        if (memory) {
            obj.resources.limits.memory = parseFloat(memory)
        } else {
            obj.resources = {
                limits: {
                    memory: ''
                }
            }
        }
        // 添加前端展示相关的字段
        return {
            ...obj,
            portsOpen: false,
            commandOpen: false,
            envOpen: false,
            volumeMountsOpen: false,
            id: nanoid(),
            imageCode: 3,
        }
    }, [])

    // 网络请求--------------------
    // 模板请求
    const fetchTemplate = async () => {
        const data = await getContainerTemplate()
        const res: any = []
        data?.forEach((item: any) => {
            const obj = YAML.parse(item?.visual)
            obj?.containers?.forEach((it: any) => {
                res?.push(it)
            })
        })
        res.push({ ...customTem })
        SetTemplate(res)
    }

    const [template, SetTemplate] = useState<any>([])               // 所有模板

    // 添加的菜单
    const temMenu: MenuProps['items'] = template?.map((item: any) => {
        return {
            label: item?.name === 'container-name' ? '自定义' : item?.name,
            key: item?.name,
            onClick: () => {
                addTab(item)
            }
        }
    })

    // 相关数据初始化
    useEffect(() => {
        if (!open) return
        fetchTemplate()
        setMode(true)
        if (initValue && Object?.keys(initValue)?.length) {
            let tem = YAML?.parse(initValue?.template ?? '')
            tem = tem?.containers
            form?.setFieldValue('envName', initValue?.name)
            const newTabs: any = []
            tem?.forEach((item: any) => {
                newTabs?.push(handleTemObject(item))
            })
            setTabs(newTabs)
            setCurrent(newTabs[0] ?? {})
        } else {
            setTabs([{ ...customTem }])
            setCurrent({ ...customTem })
            SetTemplate([{ ...customTem }])
            form?.setFieldValue('envName', '')
        }
    }, [open])


    return (
        <Modal
            open={open}
            wrapClassName={"setenv-modal"}
            onCancel={() => onCancel?.()}
            footer={false}
            closable={false}
            width={"100vw"}
            maskClosable={false}
            forceRender={true}
            destroyOnClose={true}
            style={{ maxWidth: '100vw', top: '60px' }}
        >
            <div className='setenv-modal-close' onClick={onCancel}>
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="inherit">
                    <mask id="mask0_1649_2669" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
                        <rect x="2" y="2" width="20" height="20" />
                    </mask>
                    <g mask="url(#mask0_1649_2669)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M19.0725 5.71607C19.2894 5.49911 19.2894 5.14735 19.0725 4.9304C18.8555 4.71344 18.5038 4.71344 18.2868 4.9304L12 11.2172L5.7132 4.93038C5.49624 4.71343 5.14448 4.71343 4.92753 4.93038C4.71057 5.14734 4.71057 5.4991 4.92753 5.71606L11.2143 12.0029L4.93035 18.2869C4.71339 18.5038 4.71339 18.8556 4.93035 19.0725C5.14731 19.2895 5.49907 19.2895 5.71602 19.0725L12 12.7885L18.284 19.0725C18.5009 19.2895 18.8527 19.2895 19.0697 19.0725C19.2866 18.8556 19.2866 18.5038 19.0697 18.2868L12.7857 12.0029L19.0725 5.71607Z" />
                    </g>
                </svg>
            </div>

            <div className='setenv-modal-form'>
                <Form
                    name="Set-Env"
                    colon={false}
                    validateTrigger="onBlur"
                    autoComplete="off"
                    form={form}
                    layout={"vertical"}
                    disabled={!isEdit}
                >
                    <Form.Item
                        label="环境名称"
                        name='envName'
                        rules={[
                            { required: true, message: '请输入环境名称', validateTrigger: "onSubmit", whitespace: true },
                            { min: 3, max: 20, message: '请输入3-20个字符', validateTrigger: "onSubmit" },
                            // { min: 3, max: 20, message: '请输入3-20字符的中英文、数字、符号', validateTrigger: "onSubmit" },
                            // {
                            //     validator: (_, value) => {
                            //         const re = /^[\u4E00-\u9FA5a-zA-Z0-9_\.-]+$/
                            //         if (re.test(value?.trim?.())) {
                            //             return Promise.resolve()
                            //         }
                            //         return Promise.reject('请输入3-20字符的中英文、数字、符号')
                            //     }
                            // }
                        ]}
                        validateFirst
                    >
                        <Input
                            placeholder="请输入环境名称"
                            showCount
                            allowClear
                            maxLength={20}
                            style={{ width: '460px' }}
                        />
                    </Form.Item>
                </Form>
            </div>

            <div className='setenv-modal-nav'>
                <span className='setenv-modal-nav-left'>环境配置</span>
                <div className='setenv-modal-nav-right'>
                    <div className={mode ? 'light setenv-modal-nav-right-btn' : 'setenv-modal-nav-right-btn'} onClick={() => {
                        if (mode) return
                        const curr = tabs?.find((item: any) => item?.id === current?.id)
                        setCurrent(curr)
                        setMode(true)
                    }}>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className='svg1'>
                            <path d="M7.79133 2.56152H3C2.44772 2.56152 2 3.00924 2 3.56152V13.1442C2 13.6965 2.44771 14.1442 3 14.1442H12.5827C13.135 14.1442 13.5827 13.6965 13.5827 13.1442V8.35286" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
                            <line x1="5.5625" y1="10.0837" x2="13.084" y2="2.56219" strokeWidth="1.3" strokeLinecap="round" />
                        </svg>
                        <span>表单编辑</span>
                    </div>
                    <div className={mode ? 'setenv-modal-nav-right-btn' : 'light setenv-modal-nav-right-btn'} onClick={async () => {
                        if (!mode) return
                        const res = await canChange()
                        if (res) {
                            saveTab()
                            setMode(false)
                        } else {
                            message.destroy()
                            message.warning('当前容器还未校验成功！')
                        }
                    }}>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className='svg2'>
                            <g clipPath="url(#clip0_2573_20438)">
                                <path d="M5.01373 3.90823C4.78583 3.68033 4.44397 3.68033 4.21607 3.90823L0.113799 7.89655C-0.114105 8.12445 -0.114105 8.46631 0.113799 8.69421L4.10211 12.6825C4.33002 12.9104 4.67187 12.9104 4.89978 12.6825C5.12768 12.4546 5.12768 12.1128 4.89978 11.8849L1.36727 8.35236L5.01373 4.7059C5.12768 4.47799 5.12768 4.13614 5.01373 3.90823ZM9.116 3.90823C8.88809 3.90823 8.54624 4.02218 8.43229 4.36404L6.38115 12.1128C6.2672 12.4546 6.4951 12.6825 6.83696 12.7965C7.17881 12.9104 7.40672 12.6825 7.52067 12.3407L9.57181 4.59194C9.68576 4.36404 9.45785 4.02218 9.116 3.90823ZM15.8392 7.89655L11.8508 3.90823C11.6229 3.68033 11.2811 3.68033 11.0532 3.90823C10.8253 4.13614 10.8253 4.47799 11.0532 4.7059L14.5857 8.35236L10.9392 11.9988C10.7113 12.2267 10.7113 12.5686 10.9392 12.7965C11.1671 13.0244 11.509 13.0244 11.7369 12.7965L15.7252 8.80816C15.9531 8.58026 15.9531 8.12445 15.8392 7.89655Z" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2573_20438">
                                    <rect width="16" height="16" transform="translate(0 0.352539)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>代码预览</span>
                    </div>
                </div>
            </div>

            <div className='setenv-modal-tabs'>
                {
                    mode ? <>
                        <div className='setenv-modal-tabs-nav'>
                            {
                                tabs?.map((item: any) => {
                                    return <Tooltip key={item?.id} title={item?.name} arrow={false} placement='bottomLeft' overlayClassName={'tooltip-nav'}>
                                        <div onClick={() => { selectTab(item?.id) }} className={current?.id === item?.id ? 'setenv-modal-tabs-nav-item light' : 'setenv-modal-tabs-nav-item'}>
                                            {item?.name}
                                        </div>
                                    </Tooltip>
                                })
                            }
                            <div className='setenv-modal-tabs-nav-add'>
                                {
                                    isEdit ? <>
                                        <Dropdown
                                            menu={{ items: temMenu }}
                                            getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                                            trigger={['click']}
                                            overlayClassName='setenv-modal-tabs-nav-add-dropdown'
                                        >
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="7.99919" cy="8.3527" r="6.11667" strokeWidth="1.1" />
                                                <path d="M7.39893 4.95352C7.39893 4.62215 7.66755 4.35352 7.99893 4.35352V4.35352C8.3303 4.35352 8.59893 4.62214 8.59893 4.95352V11.7535C8.59893 12.0849 8.3303 12.3535 7.99893 12.3535V12.3535C7.66755 12.3535 7.39893 12.0849 7.39893 11.7535V4.95352Z" />
                                                <rect x="11.999" y="7.75195" width="1.2" height="8" rx="0.6" transform="rotate(90 11.999 7.75195)" />
                                            </svg>
                                        </Dropdown>
                                    </> : <>
                                        <svg style={{pointerEvents: 'none', cursor: 'default'}} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.99919" cy="8.3527" r="6.11667" strokeWidth="1.1" />
                                            <path d="M7.39893 4.95352C7.39893 4.62215 7.66755 4.35352 7.99893 4.35352V4.35352C8.3303 4.35352 8.59893 4.62214 8.59893 4.95352V11.7535C8.59893 12.0849 8.3303 12.3535 7.99893 12.3535V12.3535C7.66755 12.3535 7.39893 12.0849 7.39893 11.7535V4.95352Z" />
                                            <rect x="11.999" y="7.75195" width="1.2" height="8" rx="0.6" transform="rotate(90 11.999 7.75195)" />
                                        </svg>
                                    </>
                                }
                            </div>
                        </div>
                        <div className='setenv-modal-tabs-env'>
                            <EnvForm ref={Env} data={current} setContainerName={changeTabName} verifyImage={verifyImage} edited={isEdit} />
                            <img style={{cursor: isEdit ? 'pointer' : 'default'}} src={require('../../assets/images/reddel.png')} className='setenv-modal-tabs-env-del' alt="" onClick={() => {isEdit && delTab()}} />
                        </div>
                    </> : <div className="setenv-modal-tabs-content" ref={codemirrorRef}></div>
                }
            </div>

            <div className='setenv-modal-footer'>
                <Button htmlType="button" style={{ minWidth: '88px' }} onClick={() => { onCancel?.() }}>取消</Button>
                <Button htmlType="button" type="primary" style={{ minWidth: '88px' }} onClick={save}>{isEdit ? '保存' : '编辑'}</Button>
            </div>

        </Modal>
    );
};

export default SetEnv;