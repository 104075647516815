import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import { Button, Checkbox, Form, Input, message, Modal, Select, TimePicker } from 'antd'
import SvgIcon from '@/components/SvgIcon'
import { handleTimeSecond } from '@/utils/time'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import RichEditor from '@/components/RichEditor'
import TipsAbility from './TipsAbility'
import KnowledgeFormItem from '../KnowledgeFormItem'
import { queryActivityKnowledge } from '@/api/modules/community'

type ChoiceQuestionProps = {
    open: boolean
    onClose?: () => void
    value?: any
    time?: boolean           // 是否需要显示时间控件  默认不需要
    initTime?: string        // 如果需要可以提供一个初始值
    veriryTime?: (speed: number, id?: string) => any// 如果需要时间空间还可以提供一个校验时间的控件
    finish?: (data: any) => Promise<any>
    chapterId: string             // 小节id
    courseCode: string            // 课程code
}

// 问答数据
export interface Quesandans {
    id: string,  // 唯一标识
    category: 'radio' | 'checkbox',  // 单选  多选
    title: string,  //题目
    time: string, // 触发时间
    options: Array<{   //选项
        key: string,  //  选项key
        value: string  // 选项值
    }>,  //
    selected: string,  //  正确答案
    buttonName: string,  //  按钮名称
    score: number,  //   分数
    required: boolean,  //   是否必答
    requiredCorrect: boolean,  //   是否必须回答正确
    auxiliary: Array<{  //辅助活动
        type: "tip",  //辅助活动类型
        triggerTime: number,  //  触发时机  默认 0
        description: string  //  描述内容
    }> | null
    speed?: number
    knowledgeIds: string[] | undefined
}


// 选择题  单选和多选
const ChoiceQuestion: React.FC<ChoiceQuestionProps> = (props) => {

    const { open, onClose, value, time = false, initTime = '00:00:00', veriryTime, finish, chapterId, courseCode } = props

    const [form] = Form.useForm()
    const TipsAbilityRef = useRef(null)  // 获取提示表单

    // const { quesandans, category, mark, close, finish, initTime = '00:00:00', veriryTime } = props

    const [questionTitle, setQuestionTitlee] = useState<any>()
    const questionEditor = useRef<any>(null)

    // 表单数据定义--------------------
    // 1 问答表单
    const [select, setSelect] = useState('')
    // 选项列表   默认单选
    const radioListMod = [  // 单选默认模板
        {
            key: 'a',
            value: '默认选项1',
            select: true,   // 是否选中
            del: false,   // 是否可以删除
            hover: false,   // hover是否高亮
        },
        {
            key: 'b',
            value: '默认选项2',
            select: false,
            del: true,
            hover: false,
        },
        {
            key: 'c',
            value: '默认选项3',
            select: false,
            del: true,
            hover: false,
        },
        {
            key: 'd',
            value: '默认选项4',
            select: false,
            del: true,
            hover: false,
        }
    ]

    // 单选和多选列表
    const [optionList, setOptionList] = useState<any[]>([])

    // 是否必答
    const [requiredAnswer, setRequiredAnswer] = useState(false)
    // 回答是否正确
    const [requiredTrue, setRequiredTrue] = useState(false)

    // 2 提示表单
    const [tips, setTips] = useState({
        trigger: 0,
        tip: ''
    })

    // 初始化表单数据
    useEffect(() => {
        if (!open) return
        handleInitFormData(value)
    }, [value, initTime, open])

    const handleInitFormData = async (value: any) => {
        if (value && Object.keys(value)?.length) {
            const lables = await fetchLabels('UPDATE', value.knowledgeIds, value?.id)
            const { title, category, buttonName, score, time, options, selected, required, requiredCorrect, auxiliary } = value
            const datetime = time ? dayjs(time, 'HH:mm:ss') : dayjs(initTime, 'HH:mm:ss')
            form.setFieldsValue({
                question: title,
                option: category,
                button: buttonName,
                score: score,
                time: datetime,
                knowledgeIds: lables?.knowledgeList ?? []
            })
            setQuestionTitlee(title)
            // 单选还是多选数据处理
            const len = options.length
            const list = options?.map((item: any) => {
                const select = selected.split(',').includes(item.key)
                const del = len === 2 ? false : !select
                return { ...item, hover: false, select, del }
            })
            
            setOptionList(list)
            setSelect(category)

            // 是否必答相关
            setRequiredAnswer(required)
            setRequiredTrue(requiredCorrect)

            // 是否有辅助活动
            if (auxiliary && auxiliary?.length) {
                setCanAddActivity(0)
                const tipp = auxiliary[0]
                setTips({
                    trigger: tipp.triggerTime,
                    tip: tipp.description
                })
            }
        } else {
            const lables = await fetchLabels()
            form.setFieldsValue({
                question: '',
                option: 'radio',
                button: '提交',
                score: 10,
                time: dayjs(initTime, 'HH:mm:ss'),
                knowledgeIds: lables?.knowledgeList ?? []
            })
            setSelect('radio')
            setOptionList(radioListMod)
        }
    }

    const fetchLabels = async (operate: 'CREATE' | 'UPDATE' = 'CREATE', knowledgeIds?: string[], resourceId?: string) => {
        const data = await queryActivityKnowledge({
            chapterId,
            courseCode,
            operate,
            knowledgeIds,
            resourceId
        }).catch(() => {})
        return data
    }

    // 单选还是多选
    const change = (value: string) => {
        setSelect(value)
        switch (value) {
            case 'radio':
                const radioList = optionList.map((item, index, list) => {
                    const canSelect = index ? false : true
                    let canDel = !canSelect
                    if (list.length < 3) {
                        canDel = false
                    }
                    return { ...item, select: canSelect, del: canDel }
                })
                setOptionList(radioList)
                break
            case 'checkbox':
                const checkboxList = optionList.map((item, index, list) => {
                    const canSelect = index === 0 || index === 1
                    return { ...item, select: canSelect, del: !canSelect }
                })
                setOptionList(checkboxList)
                break
            default:
                break
        }
    }

    // Select删除的规则
    const deleteSelect = (key: string) => {
        const list = [...optionList]
        const li: any = optionList.find((item) => {
            return key === item.key
        })
        if (li.select) {
            message.destroy()
            message.error('正确答案不可删除')
        } else if (!li.del) {
            message.destroy()
            message.error('至少保留两个选项')
        } else {
            const newList = list.filter(item => {
                return item.key !== key
            })
            if (newList.length === 2) {
                const twoList = newList.map(item => {
                    return { ...item, del: false }
                })
                setOptionList(twoList)
            } else {
                setOptionList(newList)
            }
            message.success('删除成功')
        }
    }

    // Select选择正确答案规则
    const choiceSelect = (key: string) => {
        const list = [...optionList]
        if (select === 'radio') {
            const index: any = list.findIndex((item) => {
                return key === item.key
            })
            if (!list[index].select) {
                list[index].select = true
                list[index].del = false
                for (let i = 0; i < list.length; i++) {
                    if (i !== index) {
                        list[i].select = false
                        if (list.length > 2) {
                            list[i].del = true
                        }
                    }
                }
                setOptionList(list)
            }
        } else {
            if (list.length > 2) {
                let index = 0
                list.forEach(item => {
                    if (item.select && key !== item.key) {
                        index++
                    }
                })
                if (index >= 2) {
                    const n: any = list.findIndex((item) => {
                        return key === item.key
                    })
                    list[n].select = !list[n].select
                    list[n].del = !list[n].del
                    setOptionList(list)
                }
            }
        }
    }

    // Select输入框数据的数据控制
    const selectInput = (key: string, value: string) => {
        const list = [...optionList]
        const li: any = list.find((item) => {
            return key === item.key
        })
        li.value = value
        setOptionList(list)
    }

    // Select删除框的hover数据控制 
    const selectDelete = (key: string, hover: boolean) => {
        const list = [...optionList]
        const li: any = list.find((item) => {
            return key === item.key
        })
        li.hover = hover
        setOptionList(list)
    }

    // Select添加选项
    const selectAddOption = () => {
        let newList = [...optionList]
        if (select === 'radio' && optionList.length === 2) {
            newList = newList.map(item => {
                return { ...item, del: item.select ? false : true }
            })
        }
        setOptionList([...newList, {
            key: nanoid(5),
            value: '默认选项',
            select: false,
            del: true,
            hover: false,
        }])
    }

    // 必答选择的规则
    useEffect(() => {
        if (!requiredAnswer && requiredTrue) {
            setRequiredTrue(false)
        }
    }, [requiredAnswer])

    // 必须正确选择的规则
    useEffect(() => {
        if (requiredTrue) {
            setRequiredAnswer(true)
        }
    }, [requiredTrue])

    /**  当前可添加的活动
     *   0   任何辅助活动都不可以添加
     *   1   可以添加提示活动
     */
    const [canAddActivity, setCanAddActivity] = useState(1)

    // 确定
    const submit = () => {
        const el: any = TipsAbilityRef?.current
        el?.set()
        const title = questionEditor?.current?.getHtml()
        form.setFieldsValue({
            question: title,
        })
        Promise.all([el?.validateFields(), form.validateFields()]).then(res => {
            const { question, button, score, time, knowledgeIds } = res[1]
            const fenzhi = Number(score) || 0
            const datetime = time?.format('HH:mm:ss') || ''
            let selecttrue: string[] = []
            let temp = false
            const list = optionList.map((item) => {
                if (item.value === '') {
                    temp = true
                }
                if (item.select) {
                    selecttrue.push(item.key)
                }
                return {
                    key: item.key,
                    value: item.value
                }
            })
            if (temp) {
                // console.log('校验没通过')
                return
            }
            // console.log("校验通过")
            const data: Quesandans = {
                id: value?.id ?? '',
                category: select as any,
                title: question,
                options: list,
                selected: selecttrue.join(','),
                buttonName: button,
                score: fenzhi,
                required: requiredAnswer,
                requiredCorrect: requiredTrue,
                time: datetime,
                auxiliary: null,
                speed: time ? handleTimeSecond(time) : undefined,
                knowledgeIds: knowledgeIds?.map((item: any) => item.knowledgeId)
            }
            if (res[0]) {
                data.auxiliary = [{
                    type: 'tip',
                    triggerTime: 0,
                    description: res[0].tip
                }]
            }
            finish?.(data)
            onClose?.()
        }).catch(err => {
            console.log("校验不通过")
        })
    }


    return (
        <Modal
            open={open}
            wrapClassName={'ylzcc-modal'}
            onCancel={onClose}
            footer={false}
            centered={true}
            width={'1020px'}
            maskClosable={false}
            destroyOnClose={true}
        >
            <div className='ylzcc-form-head'>
                <span>{value ? '编辑' : '新增'}选择题</span>
            </div>
            <Form
                name="basic"
                colon={false}
                validateTrigger='onBlur'
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    label="设置题目"
                    name={'question'}
                    rules={[
                        { required: true, message: '请输入题目内容', whitespace: true },
                        { min: 3, message: '请至少输入3个字' }
                    ]
                    }
                    validateFirst
                >
                    <RichEditor placeholder='请输入题目内容' ref={questionEditor} defaultValue={questionTitle} />
                </Form.Item>


                <Form.Item
                    label="选项设置"
                    name={'option'}
                    rules={[{ required: true }]}
                >
                    <Select
                        style={{ width: 180 }}
                        onChange={change}
                        options={[
                            { value: 'radio', label: '单选' },
                            { value: 'checkbox', label: '多选' },
                        ]}
                    />
                </Form.Item>

                <Form.Item>
                    <div className='form-item-choise'>
                        <div className='form-item-choise-title'>
                            <span>选项文字</span>
                            <span>正确答案</span>
                        </div>
                        <div className='form-item-choise-select'>
                            {
                                optionList?.map(item => {
                                    return (
                                        <div className='form-item-choise-select-item' key={item.key}>
                                            <div className='form-item-choise-select-item-input'>
                                                <Input showCount maxLength={100} size='small' value={item.value} allowClear onChange={(e) => { selectInput(item.key, e.target.value) }} status={item.value.length ? '' : 'error'} />
                                                {
                                                    item.value.length ? <></> : <span className='tip'>选项内容不可为空，请至少输入1个字</span>
                                                }
                                            </div>
                                            <div className='form-item-choise-select-item-cho' onClick={() => { choiceSelect(item.key) }}>
                                                {
                                                    item.select ? <div className='icon'><SvgIcon iconClass={'select'} fontSize='24px' className='hover' /></div> : <div className='que'></div>
                                                }
                                            </div>
                                            <div className='form-item-choise-select-item-del' onMouseEnter={() => { selectDelete(item.key, true) }} onMouseLeave={() => { selectDelete(item.key, false) }} onClick={() => { deleteSelect(item.key) }}>
                                                {
                                                    item.del ? <SvgIcon iconClass={item.hover ? 'deletee-hover' : 'deletee'} fontSize='20px' /> : <SvgIcon iconClass={'deleteno'} fontSize='20px' />
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className='form-item-choise-select-item'>
                                <div className='form-item-choise-select-item-input' onClick={selectAddOption}>
                                    <div className='form-item-choise-select-item-input-add'>
                                        <span>+添加选项</span>
                                    </div>
                                </div>

                                <div className='form-item-choise-select-item-cho' style={{ visibility: 'hidden' }}>
                                    <div></div>
                                </div>
                                <div className='form-item-choise-select-item-del' style={{ visibility: 'hidden' }}>

                                </div>
                            </div>
                        </div>
                    </div>
                </Form.Item>

                <div style={{ width: '260px' }}>
                    <Form.Item
                        label="按钮名称"
                        name={'button'}
                        rules={[
                            { required: true, min: 2, message: '按钮名称不可为空，请至少输入2个字' },
                        ]
                        }
                    >
                        <Input showCount maxLength={6} />
                    </Form.Item>
                </div>


                {
                    time && <div style={{ width: '260px' }}>
                        <Form.Item
                            label="触发时间"
                            name={'time'}
                            rules={[
                                { required: true, message: '请输入触发时间' },
                                {
                                    validator(_, _value) {
                                        if (veriryTime) {
                                            const res = veriryTime(handleTimeSecond(_value), value?.id)
                                            if (res.success) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject(res.message ?? '')
                                            }
                                        }
                                        return Promise.resolve()
                                    },
                                    validateTrigger: "onSubmit"
                                }
                            ]
                            }
                            validateFirst
                        >
                            <TimePicker disabled={!value} showNow={false} />
                        </Form.Item>
                    </div>
                }

                <KnowledgeFormItem form={form} chapterId={chapterId} courseCode={courseCode}/>

                <div className='form-item-check'>
                    <div>
                        <Checkbox checked={requiredAnswer} onChange={(e) => { setRequiredAnswer(e.target.checked) }} >
                            是否必答
                        </Checkbox>
                        {
                            requiredAnswer && (
                                <div className='form-item-check-tip'>
                                    <SvgIcon iconClass='zhushi' />
                                    <span>勾选必答后，学习者必须完成该选择题才可以进行下一步操作。</span>
                                </div>
                            )
                        }
                    </div>

                    <div>
                        <Checkbox checked={requiredTrue} onChange={(e) => { setRequiredTrue(e.target.checked) }}>
                            是否必须回答正确
                        </Checkbox>
                        {
                            requiredTrue && (
                                <div className='form-item-check-tip'>
                                    <SvgIcon iconClass='zhushi' />
                                    <span>勾选必须回答正确后，学习者必须回答正确该选择题才可以进行下一步操作。</span>
                                </div>
                            )
                        }
                    </div>
                </div>

                {
                    canAddActivity ? <div className='form-item-tip'>
                        <div className='form-item-tip-btn' onClick={() => { setCanAddActivity(0) }}>
                            <SvgIcon iconClass={'add'} fontSize='16px' />
                            <span>添加提示</span>
                        </div>
                    </div> : <div className='form-item-tiptab'>
                        <TipsAbility ref={TipsAbilityRef} close={() => { setCanAddActivity(1) }} tips={tips} />
                    </div>
                }


                <Form.Item>
                    <div className='form-item-button'>
                        <Button className='form-item-button-can' onClick={onClose}>取消</Button>
                        <Button className='form-item-button-en' type='primary' onClick={submit}>确定</Button>
                    </div>
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default ChoiceQuestion